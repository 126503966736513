import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormBlockGroup } from '../form-block';
import { NameFormBlockData } from './name-form-block-data';
import { GenderSettingsService } from '@shared/services';

@Component({
  selector: 'app-name-form-block',
  templateUrl: 'name-form-block.component.html',
  styleUrls: ['name-form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameFormBlockComponent extends FormBlockGroup<NameFormBlockData> {
  get tabIndexEnd() {
    return this.tabIndexStart + 3;
  }

  constructor(public genderSettings: GenderSettingsService) {
    super();
  }

  initializeControl(data?: NameFormBlockData): void {
    this.control = new UntypedFormGroup({
      gender: new UntypedFormControl(data?.gender, this.getValidatorFn('gender')),
      title: new UntypedFormControl(data?.title, this.getValidatorFn('title')),
      firstName: new UntypedFormControl(data?.firstName ?? '', this.getValidatorFn('firstName')),
      lastName: new UntypedFormControl(data?.lastName ?? '', this.getValidatorFn('lastName')),
    });
  }
  _patchValue(update: { previous: NameFormBlockData; current: NameFormBlockData }): void {
    this.control.patchValue({
      gender: update.current?.gender ?? 0,
      title: update.current?.title ?? '',
      firstName: update.current?.firstName ?? '',
      lastName: update.current?.lastName ?? '',
    });
  }

  updateValidators(): void {
    this.control.get('gender').setValidators(this.getValidatorFn('gender'));
    this.control.get('title').setValidators(this.getValidatorFn('title'));
    this.control.get('firstName').setValidators(this.getValidatorFn('firstName'));
    this.control.get('lastName').setValidators(this.getValidatorFn('lastName'));
  }
}
