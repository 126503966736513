import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UpdateP4MWebshopCustomerComponent } from './update-p4m-webshop-customer.component';

import {
  AddressFormBlockModule,
  BirthDateFormBlockModule,
  InterestsFormBlockModule,
  NameFormBlockModule,
  OrganisationFormBlockModule,
  P4mNumberFormBlockModule,
  NewsletterFormBlockModule,
  DeviatingAddressFormBlockComponentModule,
  AcceptAGBFormBlockModule,
  EmailFormBlockModule,
  PhoneNumbersFormBlockModule,
} from '../../components/form-blocks';
import { UiFormControlModule } from '@ui/form-control';
import { UiInputModule } from '@ui/input';
import { CustomerPipesModule } from '@shared/pipes/customer';
import { CustomerTypeSelectorModule } from '../../components/customer-type-selector';
import { UiSpinnerModule } from '@ui/spinner';

@NgModule({
  imports: [
    CommonModule,
    CustomerTypeSelectorModule,
    AddressFormBlockModule,
    BirthDateFormBlockModule,
    InterestsFormBlockModule,
    NameFormBlockModule,
    OrganisationFormBlockModule,
    P4mNumberFormBlockModule,
    NewsletterFormBlockModule,
    DeviatingAddressFormBlockComponentModule,
    AcceptAGBFormBlockModule,
    EmailFormBlockModule,
    PhoneNumbersFormBlockModule,
    UiFormControlModule,
    UiInputModule,
    CustomerPipesModule,
    UiSpinnerModule,
  ],
  exports: [UpdateP4MWebshopCustomerComponent],
  declarations: [UpdateP4MWebshopCustomerComponent],
})
export class UpdateP4MWebshopCustomerModule {}
