import { ValidationErrors } from '@angular/forms';

export function date(control): ValidationErrors | null {
  if (control.value) {
    const match = /^(\d{4})(-(\d{2})){2}T((\d{2})(:|.?)){3}(\d{3})?Z$/g.test(control.value);
    if (!match) {
      return { dateOfBirth: 'Geburtsdatum ist ungültig' };
    }
  }
  return null;
}
