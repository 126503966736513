/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OmsConfiguration as __Configuration } from '../oms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfStockStatusCodeDTO } from '../models/response-args-of-ienumerable-of-stock-status-code-dto';
@Injectable({
  providedIn: 'root',
})
class StockStatusCodeService extends __BaseService {
  static readonly StockStatusCodeGetStockStatusCodesPath = '/order/ssc/{supplierId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Verfügbarkeitsstatus
   * @param params The `StockStatusCodeService.StockStatusCodeGetStockStatusCodesParams` containing the following parameters:
   *
   * - `supplierId`: PK Lieferant
   *
   * - `eagerLoading`: default: 0
   *
   * - `code`: Code (optional)
   */
  StockStatusCodeGetStockStatusCodesResponse(params: StockStatusCodeService.StockStatusCodeGetStockStatusCodesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockStatusCodeDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/ssc/${encodeURIComponent(String(params.supplierId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockStatusCodeDTO>;
      })
    );
  }
  /**
   * Verfügbarkeitsstatus
   * @param params The `StockStatusCodeService.StockStatusCodeGetStockStatusCodesParams` containing the following parameters:
   *
   * - `supplierId`: PK Lieferant
   *
   * - `eagerLoading`: default: 0
   *
   * - `code`: Code (optional)
   */
  StockStatusCodeGetStockStatusCodes(params: StockStatusCodeService.StockStatusCodeGetStockStatusCodesParams): __Observable<ResponseArgsOfIEnumerableOfStockStatusCodeDTO> {
    return this.StockStatusCodeGetStockStatusCodesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfStockStatusCodeDTO)
    );
  }
}

module StockStatusCodeService {

  /**
   * Parameters for StockStatusCodeGetStockStatusCodes
   */
  export interface StockStatusCodeGetStockStatusCodesParams {

    /**
     * PK Lieferant
     */
    supplierId: number;

    /**
     * default: 0
     */
    eagerLoading?: number;

    /**
     * Code (optional)
     */
    code?: null | string;
  }
}

export { StockStatusCodeService }
