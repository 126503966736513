import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UiOverlayPositionX, UiOverlayPositionY, UiOverlayTrigger } from '@ui/common';

@Component({
  selector: 'ui-tooltip',
  templateUrl: 'tooltip.component.html',
  styleUrls: ['tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent implements UiOverlayTrigger, OnInit {
  constructor() {}

  @ViewChild(TemplateRef)
  templateRef: TemplateRef<any>;

  @ContentChild('content')
  content: ElementRef<any>;

  @Input()
  xPosition: UiOverlayPositionX;

  @Input()
  yPosition: UiOverlayPositionY;

  @Input()
  xOffset: number;

  @Input()
  yOffset: number;

  close: () => void;

  @Input()
  closeable: boolean;

  @Input()
  warning: boolean;

  get classList() {
    return ['ui-tooltip-panel', `x-position-${this.xPosition}`, `y-position-${this.yPosition}`];
  }

  ngOnInit(): void {}
}
