/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for Av services
 */
@Injectable({
  providedIn: 'root',
})
export class AvConfiguration {
  rootUrl: string = 'https://isa-test.paragon-data.net';
}

export interface AvConfigurationInterface {
  rootUrl?: string;
}
