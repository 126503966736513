import { Injectable } from '@angular/core';
import { OrderItemListItemDTO, StatusValues } from '@swagger/oms';
import { ChangeOrderItemStatusBaseActionHandler } from './change-order-item-status-base.action-handler';
import { DomainOmsService } from '../oms.service';
import { OrderItemsContext } from './order-items.context';

@Injectable()
export class NotFetchedActionHandler extends ChangeOrderItemStatusBaseActionHandler {
  constructor(domainOmsService: DomainOmsService) {
    super(domainOmsService, 'NOTFETCHED');
  }

  getStatusValues(orderItem: OrderItemListItemDTO, context: OrderItemsContext): StatusValues {
    return {
      processingStatus: 131072,
    };
  }
}
