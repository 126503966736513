<div class="font-bold text-center border-t border-b border-solid border-disabled-customer -mx-4 py-4">
  {{ customer?.communicationDetails?.email }}
</div>
<div class="grid grid-flow-row gap-1 text-sm font-bold border-b border-solid border-disabled-customer -mx-4 py-4 px-14">
  <span *ngIf="customer?.organisation?.name">{{ customer?.organisation?.name }}</span>
  <span>{{ customer?.lastName }} {{ customer?.firstName }}</span>
  <ng-container *ngIf="customer?.address; let address">
    <span>{{ address?.street }} {{ address?.streetNumber }}</span>
    <span *ngIf="address?.info">{{ address?.info }}</span>
    <span>{{ address?.zipCode }} {{ address?.city }}</span>
  </ng-container>
</div>

<div class="grid grid-flow-col gap-4 justify-around mt-12">
  <button class="border-2 border-solid border-brand rounded-full font-bold text-brand px-6 py-3 text-lg" (click)="close(false)">
    neues Onlinekonto anlegen
  </button>
  <button
    *ngIf="!isWebshopWithP4M"
    class="border-2 border-solid border-brand rounded-full font-bold text-white px-6 py-3 text-lg bg-brand"
    (click)="close(true)"
  >
    Daten übernehmen
  </button>
  <button
    *ngIf="isWebshopWithP4M"
    class="border-2 border-solid border-brand rounded-full font-bold text-white px-6 py-3 text-lg bg-brand"
    (click)="selectCustomer()"
  >
    Datensatz auswählen
  </button>
</div>
