import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiSearchboxNextComponent } from './searchbox.component';
import { FormsModule } from '@angular/forms';
import { IconModule } from '@shared/components/icon';
import { UiIconModule } from '@ui/icon';

@NgModule({
  imports: [CommonModule, UiIconModule, IconModule, FormsModule],
  exports: [UiSearchboxNextComponent],
  declarations: [UiSearchboxNextComponent],
})
export class UiSearchboxNextModule {}
