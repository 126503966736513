<div class="grid grid-cols-[1fr_auto] items-center gap-4">
  <div class="grid grid-flow-row gap-4">
    <h1 class="text-left font-bold text-lg">{{ item.headline }}</h1>
    <div class="notification-text">{{ item.text }}</div>
  </div>
  <div>
    <button *ngIf="editButton" class="notification-edit-cta text-brand font-bold text-lg px-4 py-3" (click)="itemSelected.emit(item)">
      {{ editButtonLabel }}
    </button>
  </div>
</div>
