import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtmlTags',
})
export class StripHtmlTagsPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return value?.replace(/(<([^>]+)>)/gi, '');
  }
}
