/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OmsConfiguration, OmsConfigurationInterface } from './oms-configuration';

import { AbholfachService } from './services/abholfach.service';
import { BranchService } from './services/branch.service';
import { LogisticianService } from './services/logistician.service';
import { OrderCheckoutService } from './services/order-checkout.service';
import { OrderService } from './services/order.service';
import { ReceiptService } from './services/receipt.service';
import { StockStatusCodeService } from './services/stock-status-code.service';
import { SupplierService } from './services/supplier.service';
import { VATService } from './services/vat.service';

/**
 * Provider for all Oms services, plus OmsConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    OmsConfiguration,
    AbholfachService,
    BranchService,
    LogisticianService,
    OrderCheckoutService,
    OrderService,
    ReceiptService,
    StockStatusCodeService,
    SupplierService,
    VATService
  ],
})
export class OmsModule {
  static forRoot(customParams: OmsConfigurationInterface): ModuleWithProviders<OmsModule> {
    return {
      ngModule: OmsModule,
      providers: [
        {
          provide: OmsConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
