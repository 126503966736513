import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as moment from 'moment';

moment.locale('de');

import { AppModule } from './app/app.module';
import { DebugService } from './app/debug/debug.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const debugService = new DebugService();

if (environment.debug) {
  const consoleLog = console.log;

  console.log = (...args) => {
    debugService.add({ type: 'log', args });
    consoleLog(...args);
  };

  const consoleWarn = console.warn;

  console.warn = (...args) => {
    debugService.add({ type: 'warn', args });
    consoleWarn(...args);
  };

  const consoleError = console.error;

  console.error = (...args) => {
    debugService.add({ type: 'error', args });
    consoleError(...args);
  };
}

platformBrowserDynamic([{ provide: DebugService, useValue: debugService }])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
