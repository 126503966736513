<div class="wrapper">
  <h2>Bitte überprüfen Sie die eingegebenen Adressdaten</h2>
  <p>Vorschläge:</p>

  <ul class="content">
    <li *ngFor="let item of ref?.data">
      <span>{{ item.street }} {{ item.streetNumber }}, {{ item.zipCode }} {{ item.city }}</span>
      <button (click)="ref.close(item)">Übernehmen</button>
    </li>
  </ul>

  <div class="center">
    <button class="select-btn" (click)="ref.close('continue')">Eingegebene Adresse übernehmen</button>
  </div>
</div>
