import { NgModule } from '@angular/core';
import { DateAdapter } from '.';
import { UiAutofocusDirective, UiCheckboxAutofocusDirective, UiSelectAutofocusDirective } from './autofocus.directive';
import { BlobImageDirective } from './blob-image.directive';
import { UiClickOutsideDirective } from './click-outside.directive';
import { UiElementDistanceDirective } from './element-distance';
import { UiFocusDirective } from './focus';
import { IsInViewportDirective } from './is-in-viewport.directive';
import { UiOverlayTriggerDirective } from './overlay-trigger';
import { GroupByPipe } from './pipes/group-by.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { StripHtmlTagsPipe } from './pipes/strip-html-tags.pipe';
import { SubstrPipe } from './pipes/substr.pipe';
import { SkeletonLoaderComponent } from './skeleton-loader';

const components = [SkeletonLoaderComponent];
const directives = [
  UiClickOutsideDirective,
  IsInViewportDirective,
  BlobImageDirective,
  UiAutofocusDirective,
  UiCheckboxAutofocusDirective,
  UiSelectAutofocusDirective,
  UiFocusDirective,
  UiOverlayTriggerDirective,
  UiElementDistanceDirective,
];
const pipes = [StripHtmlTagsPipe, SubstrPipe, ReplacePipe];
@NgModule({
  imports: [GroupByPipe],
  exports: [...components, ...directives, ...pipes, GroupByPipe],
  declarations: [...components, ...directives, ...pipes],
  providers: [],
})
export class UiCommonModule {
  static forRoot() {
    return {
      ngModule: UiCommonModule,
      providers: [
        {
          provide: DateAdapter,
          useClass: DateAdapter,
        },
      ],
    };
  }
}
