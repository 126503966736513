import { Directive, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { IUiInput, UiInput } from '../../tree';

@Directive({})
export abstract class AbstractUiFilterInputDirective {
  private _input: UiInput;

  @Input()
  set input(value: IUiInput) {
    if (value instanceof UiInput) {
      this._input = value;
    } else {
      this._input = UiInput.create(value);
    }

    this._onUiInputChange.next(this._input);
  }

  get input() {
    return this._input;
  }

  get uiInput() {
    return this._input;
  }

  get value() {
    return this.uiInput?.value;
  }

  protected _onUiInputChange = new Subject<UiInput>();

  onUiInputChange$ = this._onUiInputChange.asObservable();

  constructor() {}

  setValue(value: string) {
    this.uiInput.setValue(value, { emitEvent: true });
  }
}
