import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeprecatedAddressSelectionModalComponent } from './address-selection-modal.component';
import { UiIconModule } from '@ui/icon';

@NgModule({
  imports: [CommonModule, UiIconModule],
  exports: [DeprecatedAddressSelectionModalComponent],
  declarations: [DeprecatedAddressSelectionModalComponent],
})
export class AddressSelectionModalModule {}
