import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationProcess, ApplicationService } from '@core/application';
import { Breadcrumb, BreadcrumbService } from '@core/breadcrumb';
import { DomainCheckoutService } from '@domain/checkout';
import { CheckoutNavigationService } from '@shared/services';
import { BehaviorSubject, NEVER, Observable, combineLatest, isObservable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'shell-process-bar-item',
  templateUrl: 'process-bar-item.component.html',
  styleUrls: ['process-bar-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellProcessBarItemComponent implements OnInit, OnDestroy, OnChanges {
  private _process$ = new BehaviorSubject<ApplicationProcess>(undefined);

  process$ = this._process$.asObservable();

  @Input()
  process: ApplicationProcess;

  @Output()
  closed = new EventEmitter();

  activatedProcessId$ = this._app.activatedProcessId$;

  latestBreadcrumb$: Observable<Breadcrumb> = NEVER;

  routerLink$: Observable<string[] | any[]> = NEVER;

  queryParams$: Observable<Object> = NEVER;

  isActive$: Observable<boolean> = NEVER;

  showCloseButton$: Observable<boolean> = NEVER;

  cartItemCount$: Observable<number> = NEVER;

  constructor(
    private _breadcrumb: BreadcrumbService,
    private _app: ApplicationService,
    private _router: Router,
    private _checkout: DomainCheckoutService,
    private _checkoutNavigationService: CheckoutNavigationService,
    public _elRef: ElementRef<HTMLElement>
  ) {}

  ngOnChanges({ process }: SimpleChanges): void {
    if (process) {
      this._process$.next(process.currentValue);
    }
  }

  ngOnInit() {
    this.initLatestBreadcrumb$();
    this.initRouterLink$();
    this.initQueryParams$();
    this.initIsActive$();
    this.initShowCloseButton$();
    this.initCartItemCount$();
  }

  scrollIntoView() {
    setTimeout(() => this._elRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' }), 0);
  }

  getCheckoutPath(processId: number) {
    return this._checkoutNavigationService.getCheckoutReviewPath(processId).path;
  }

  initLatestBreadcrumb$() {
    this.latestBreadcrumb$ = this.process$.pipe(switchMap((process) => this._breadcrumb.getLastActivatedBreadcrumbByKey$(process?.id)));
  }

  initRouterLink$() {
    this.routerLink$ = this.latestBreadcrumb$.pipe(
      map((breadcrumb) => (breadcrumb?.path instanceof Array ? breadcrumb.path : [breadcrumb?.path]))
    );
  }

  initQueryParams$() {
    this.queryParams$ = this.latestBreadcrumb$.pipe(map((breadcrumb) => breadcrumb?.params));
  }

  initIsActive$() {
    if (isObservable(this.activatedProcessId$) && isObservable(this.process$)) {
      this.isActive$ = combineLatest([this.activatedProcessId$, this.process$]).pipe(
        map(([activatedId, process]) => process?.id === activatedId),
        tap((isActive) => {
          if (isActive) {
            this.scrollIntoView();
          }
        })
      );
    }
  }

  initShowCloseButton$() {
    if (isObservable(this.isActive$) && isObservable(this.process$)) {
      this.showCloseButton$ = this.process$.pipe(map((process) => process?.closeable));
    }
  }

  initCartItemCount$() {
    this.cartItemCount$ = this.process$.pipe(
      switchMap((process) => this._checkout?.getShoppingCart({ processId: process?.id })),
      map((cart) => cart?.items?.length ?? 0)
    );
  }

  ngOnDestroy() {
    this._process$.complete();
  }

  async close() {
    const breadcrumb = await this.getLatestBreadcrumbForSection();
    await this.navigate(breadcrumb);
    this._app.removeProcess(this.process.id);
    this.closed.emit();
  }

  getLatestBreadcrumbForSection(): Promise<Breadcrumb> {
    return this._breadcrumb
      .getLatestBreadcrumbForSection('customer', (c) => c.key !== this.process?.id)
      .pipe(first())
      .toPromise();
  }

  async navigate(breadcrumb?: Breadcrumb) {
    if (breadcrumb) {
      if (breadcrumb.path instanceof Array) {
        await this._router.navigate(breadcrumb.path, { queryParams: breadcrumb.params });
      } else {
        await this._router.navigate([breadcrumb.path], { queryParams: breadcrumb.params });
      }
    } else {
      await this._router.navigate(['/kunde/dashboard']);
    }
  }
}
