import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PackageListComponent } from './package-list.component';
import { PackageListItemComponent } from './package-list-item.component';
import { PackageListItemLoaderComponent } from './package-list-item-loader.component';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PackageInspectionPipesModule } from '@shared/pipes/package-inspection';

@NgModule({
  imports: [CommonModule, PackageInspectionPipesModule, RouterModule, ScrollingModule],
  exports: [PackageListComponent, PackageListItemComponent, PackageListItemLoaderComponent],
  declarations: [PackageListComponent, PackageListItemComponent, PackageListItemLoaderComponent],
})
export class PackageListModule {}
