import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ConfigLoader } from './config-loaders';
import { CORE_CONFIG_LOADER } from './tokens';
import { pick } from './utils';

@Injectable()
export class Config {
  private _config: any;

  private readonly _initilized = new ReplaySubject<void>(1);
  get initialized() {
    return this._initilized.asObservable();
  }

  constructor(@Inject(CORE_CONFIG_LOADER) private readonly _configLoader: ConfigLoader) {}

  // load config and assign it to this._config
  async init() {
    this._config = await this._configLoader.load();
    this._initilized.next();
  }

  get(path: string) {
    return pick(path, this._config);
  }
}
