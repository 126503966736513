<ng-container [formGroup]="control">
  <shared-form-control label="Festnetznummer">
    <input
      placeholder="Festnetznummer"
      class="input-control"
      type="tel"
      formControlName="phone"
      [tabindex]="tabIndexStart"
      [autofocus]="focusAfterInit"
      [readonly]="readonly"
    />
  </shared-form-control>
  <shared-form-control label="Mobilnummer">
    <input
      placeholder="Mobilnummer"
      class="input-control"
      type="tel"
      formControlName="mobile"
      [tabindex]="tabIndexStart + 1"
      [readonly]="readonly"
    />
  </shared-form-control>
</ng-container>
