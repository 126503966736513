import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShellTopBarComponent } from './top-bar.component';
import { AuthModule } from '@core/auth';
import { RouterModule } from '@angular/router';
import { IconModule } from '@shared/components/icon';

@NgModule({
  imports: [CommonModule, IconModule, AuthModule, RouterModule],
  exports: [ShellTopBarComponent],
  declarations: [ShellTopBarComponent],
})
export class ShellTopBarModule {}
