import { Injectable } from '@angular/core';
import { ActionHandler } from '@core/command';
import { OrderItemsContext } from './order-items.context';
import { OMSPrintService } from '@swagger/print';
import { UiModalService } from '@ui/modal';
import { PrintModalComponent, PrintModalData } from '@modal/printer';
import { NativeContainerService } from 'native-container';
import { groupBy } from '@ui/common';

@Injectable()
export class PrintSmallamountinvoiceActionHandler extends ActionHandler<OrderItemsContext> {
  constructor(
    private uiModal: UiModalService,
    private omsPrintService: OMSPrintService,
    private nativeContainerService: NativeContainerService
  ) {
    super('PRINT_SMALLAMOUNTINVOICE');
  }

  async handler(data: OrderItemsContext): Promise<OrderItemsContext> {
    await this.uiModal
      .open({
        content: PrintModalComponent,
        config: { showScrollbarY: false },
        data: {
          printImmediately: !this.nativeContainerService.isNative,
          printerType: 'Label',
          print: async (printer) => {
            try {
              const receipts = data?.receipts?.filter((r) => r?.receiptType & 128);
              for (const group of groupBy(receipts, (receipt) => receipt?.buyer?.buyerNumber)) {
                await this.omsPrintService
                  .OMSPrintKleinbetragsrechnung({
                    data: group?.items?.map((r) => r?.id),
                    printer,
                  })
                  .toPromise();
              }
              return {
                error: false,
              };
            } catch (error) {
              console.error(error);
              return {
                error: true,
                message: error?.message || error,
              };
            }
          },
        } as PrintModalData,
      })
      .afterClosed$.toPromise();

    return data;
  }
}
