/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { RemiConfiguration as __Configuration } from '../remi-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfSDVFilialdatenDTO } from '../models/response-args-of-sdvfilialdaten-dto';
@Injectable({
  providedIn: 'root',
})
class SDVService extends __BaseService {
  static readonly SDVGetFilialstammdatenPath = '/inventory/stock/{stockId}/filialstammdaten';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Filialstammdaten
   * @param params The `SDVService.SDVGetFilialstammdatenParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `locale`: Lokalisierung
   */
  SDVGetFilialstammdatenResponse(params: SDVService.SDVGetFilialstammdatenParams): __Observable<__StrictHttpResponse<ResponseArgsOfSDVFilialdatenDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/filialstammdaten`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfSDVFilialdatenDTO>;
      })
    );
  }
  /**
   * Filialstammdaten
   * @param params The `SDVService.SDVGetFilialstammdatenParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `locale`: Lokalisierung
   */
  SDVGetFilialstammdaten(params: SDVService.SDVGetFilialstammdatenParams): __Observable<ResponseArgsOfSDVFilialdatenDTO> {
    return this.SDVGetFilialstammdatenResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfSDVFilialdatenDTO)
    );
  }
}

module SDVService {

  /**
   * Parameters for SDVGetFilialstammdaten
   */
  export interface SDVGetFilialstammdatenParams {

    /**
     * Lager PK
     */
    stockId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }
}

export { SDVService }
