<div class="options-wrapper">
  <div
    *ngIf="uiStartOption"
    class="option"
    [attr.data-label]="uiStartOption?.label"
    [attr.data-value]="uiStartOption?.value"
    [attr.data-key]="uiStartOption?.key"
    [attr.data-selected]="uiStartOption?.selected"
  >
    <div class="option-wrapper">
      <span> {{ uiStartOption?.label }}: </span>
      <button
        class="cta-picker"
        [class.open]="dpStartTrigger?.opened"
        [uiOverlayTrigger]="dpStart"
        #dpStartTrigger="uiOverlayTrigger"
        type="button"
      >
        <span>
          {{ uiStartOption?.value | date: 'dd.MM.yy' }}
        </span>
        <ui-icon icon="arrow_head" size="1em"></ui-icon>
      </button>
    </div>
    <ui-datepicker
      class="dp-left"
      #dpStart
      yPosition="below"
      xPosition="after"
      [ngModel]="uiStartOption?.value"
      saveLabel="Übernehmen"
      (save)="uiStartOption?.setValue($event)"
    >
    </ui-datepicker>
  </div>
  <div
    *ngIf="uiStopOption"
    class="option"
    [attr.data-label]="uiStopOption?.label"
    [attr.data-value]="uiStopOption?.value"
    [attr.data-key]="uiStopOption?.key"
    [attr.data-selected]="uiStopOption?.selected"
  >
    <div class="option-wrapper">
      <span> {{ uiStopOption?.label }}: </span>
      <button
        class="cta-picker"
        [class.open]="dpStopTrigger?.opened"
        [uiOverlayTrigger]="dpStop"
        #dpStopTrigger="uiOverlayTrigger"
        type="button"
      >
        <span>
          {{ uiStopOptionValue | date: 'dd.MM.yy' }}
        </span>
        <ui-icon icon="arrow_head" size="1em"></ui-icon>
      </button>
    </div>
    <ui-datepicker
      class="dp-right"
      yPosition="below"
      xPosition="after"
      #dpStop
      [ngModel]="uiStopOptionValue"
      (save)="setStopValue($event)"
      saveLabel="Übernehmen"
    >
    </ui-datepicker>
  </div>
</div>
