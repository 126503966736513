import { ItemDTO } from '@swagger/cat';
import { ShoppingCartItemDTO } from '@swagger/checkout';

export function getCatalogProductNumber(item: ItemDTO);
export function getCatalogProductNumber(item: ShoppingCartItemDTO);
export function getCatalogProductNumber(item: ItemDTO | ShoppingCartItemDTO) {
  if (!item) {
    return '';
  }

  if (item.product?.catalogProductNumber) {
    return item.product?.catalogProductNumber;
  }

  return String(item.id);
}
