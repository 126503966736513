import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormBlockGroup } from '../form-block';
import { PhoneNumbersFormBlockData } from './phone-numbers-form-block-data';

@Component({
  selector: 'app-phone-numbers-form-block',
  templateUrl: 'phone-numbers-form-block.component.html',
  styleUrls: ['phone-numbers-form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumbersFormBlockComponent extends FormBlockGroup<PhoneNumbersFormBlockData> {
  get tabIndexEnd() {
    return this.tabIndexStart + 1;
  }

  initializeControl(data?: PhoneNumbersFormBlockData): void {
    this.control = new UntypedFormGroup({
      phone: new UntypedFormControl(data?.phone ?? '', this.getValidatorFn('phone')),
      mobile: new UntypedFormControl(data?.mobile ?? '', this.getValidatorFn('mobile')),
    });
  }

  _patchValue(update: { previous: PhoneNumbersFormBlockData; current: PhoneNumbersFormBlockData }): void {
    this.control.patchValue({
      phone: update.current.phone ?? '',
      mobile: update.current.mobile ?? '',
    });
  }

  updateValidators(): void {
    this.control.setValidators(this.getValidatorFn('phone'));
    this.control.get('phone').updateValueAndValidity();
    this.control.setValidators(this.getValidatorFn('mobile'));
    this.control.get('mobile').updateValueAndValidity();
  }
}
