import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { P4mNumberFormBlockComponent } from './p4m-number-form-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '@shared/components/icon';
import { FormControlComponent } from '@shared/components/form-control';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormControlComponent, IconComponent],
  exports: [P4mNumberFormBlockComponent],
  declarations: [P4mNumberFormBlockComponent],
})
export class P4mNumberFormBlockModule {}
