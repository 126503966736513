import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BreadcrumbService } from './breadcrumb.service';
import { BreadcrumbEffects } from './store/breadcrumb.effect';
import { breadcrumbReducer } from './store/breadcrumb.reducer';
import { featureName } from './store/breadcrumb.state';

@NgModule()
export class CoreBreadcrumbModule {
  static forRoot(): ModuleWithProviders<CoreBreadcrumbModule> {
    return {
      ngModule: CoreBreadcrumbForRootModule,
    };
  }
}

@NgModule({
  imports: [StoreModule.forFeature(featureName, breadcrumbReducer), EffectsModule.forFeature([BreadcrumbEffects])],
  providers: [BreadcrumbService],
})
export class CoreBreadcrumbForRootModule {}
