import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { NgIf } from '@angular/common';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { UiIconModule } from '@ui/icon';

@Component({
  selector: 'shared-loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'shared-loader' },
  standalone: true,
  imports: [NgIf, UiIconModule],
})
export class LoaderComponent {
  @Input() loading: BooleanInput;

  @Input() background: BooleanInput;

  @Input() spinnerSize: NumberInput = 24;

  @Input() hideContent: BooleanInput;

  get showLoader() {
    return coerceBooleanProperty(this.loading);
  }

  get showBackground() {
    return coerceBooleanProperty(this.background);
  }

  get spinnerSizeInRem() {
    return coerceNumberProperty(this.spinnerSize) / 16 + 'rem';
  }

  get contentHidden() {
    return coerceBooleanProperty(this.hideContent);
  }

  constructor() {}
}
