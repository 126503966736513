<shared-checkbox
  class="mb-4"
  [formControl]="deviatingAddress"
  [tabindex]="tabIndexStart"
  [autofocus]="focusAfterInit"
  [readonly]="readonly"
>
  <ng-content></ng-content>
</shared-checkbox>
<div class="address-block" *ngIf="control.value.deviatingAddress">
  <div class="wrapper">
    <app-organisation-form-block
      *ngIf="organisation"
      [tabIndexStart]="tabIndexStart + 1"
      #orgaBlock
      (onInit)="addOrganisationGroup($event)"
      (onDestroy)="removeOrganisationGroup()"
      [data]="data?.organisation"
      #nameFormBlock
      [tabIndexStart]="tabIndexStart + 1"
      [requiredMarks]="organisationRequiredMarks"
      [validatorFns]="organisationValidatorFns"
      [readonly]="readonly"
    >
    </app-organisation-form-block>
    <app-name-form-block
      (onInit)="addNameGroup($event)"
      (onDestroy)="removeNameGroup()"
      [data]="data?.name"
      #nameFormBlock
      [requiredMarks]="nameRequiredMarks"
      [validatorFns]="nameValidatorFns"
      [readonly]="readonly"
    >
    </app-name-form-block>
    <app-address-form-block
      #addressFormBlock
      (onInit)="addAddressGroup($event)"
      (onDestroy)="removeAddressGroup()"
      [data]="data?.address"
      [requiredMarks]="addressRequiredMarks"
      [validatorFns]="addressValidatorFns"
      [readonly]="readonly"
    >
    </app-address-form-block>
    <app-email-form-block
      *ngIf="email"
      #emailFormBlock
      (onInit)="addEmailGroup($event)"
      (onDestroy)="removeEmailGroup()"
      [data]="data?.email"
      [requiredMark]="emailRequiredMark"
      [validatorFns]="emailValidationFns"
      [readonly]="readonly"
    >
    </app-email-form-block>
    <app-phone-numbers-form-block
      *ngIf="phoneNumbers"
      (onInit)="addPhoneNumbersGroup($event)"
      (onDestroy)="removePhoneNumbersGroup()"
      [readonly]="readonly"
    >
      [tabIndexStart]="emailFormBlock?.tabIndexEnd+1" [requiredMarks]="phoneNumbersRequiredMarks" [validatorFns]="phoneNumbersValidatorFns">
    </app-phone-numbers-form-block>
  </div>
</div>
