import { Injectable, isDevMode } from '@angular/core';
import { EnvironmentService } from '@core/environment';
import { PromptModalData, UiModalService, UiPromptModalComponent } from '@ui/modal';
import { Observable } from 'rxjs';
import { ScanAdapter } from './scan-adapter';

@Injectable()
export class DevScanAdapter implements ScanAdapter {
  readonly name = 'Dev';

  constructor(private _modal: UiModalService, private _environmentService: EnvironmentService) {}

  async init(): Promise<boolean> {
    return Promise.resolve(false);
    // return new Promise((resolve, reject) => {
    //   resolve(isDevMode());
    // });
  }

  scan(): Observable<string> {
    return new Observable((observer) => {
      const modalRef = this._modal.open({
        content: UiPromptModalComponent,
        title: 'Scannen',
        data: {
          message: 'Diese Eingabemaske dient nur zu Entwicklungs und Testzwecken.',
          placeholder: 'Scan Code',
          confirmText: 'weiter',
          cancelText: 'abbrechen',
        } as PromptModalData,
      });

      const sub = modalRef.afterClosed$.subscribe((result) => {
        observer.next(result.data);
        observer.complete();
      });

      return () => {
        modalRef.close();
        sub.unsubscribe();
      };
    });
  }
}
