import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiDropdownItemDirective } from './dropdown-item.directive';
import { UiDropdownComponent } from './dropdown.component';

@NgModule({
  declarations: [UiDropdownComponent, UiDropdownItemDirective],
  imports: [CommonModule],
  exports: [UiDropdownComponent, UiDropdownItemDirective],
})
export class UiDropdownModule {}
