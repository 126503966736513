import { ModuleWithProviders, NgModule } from '@angular/core';
import { Config } from '@core/config';
import { ConsoleLogProvider } from './console-log.provider';
import { Logger } from './logger.service';
import { LOG_PROVIDER, LOG_LEVEL } from './tokens';

export function _logLevelProviderFactory(config: Config) {
  return config.get('@core/logger.logLevel');
}

@NgModule({})
export class CoreLoggerModule {
  static forRoot(): ModuleWithProviders<CoreLoggerModule> {
    return {
      ngModule: CoreLoggerModule,
      providers: [
        Logger,
        {
          provide: LOG_PROVIDER,
          useClass: ConsoleLogProvider,
          multi: true,
        },
        {
          provide: LOG_LEVEL,
          useFactory: _logLevelProviderFactory,
          deps: [Config],
        },
      ],
    };
  }
}
