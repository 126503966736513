<ng-template>
  <div class="quantity-list" [class]="classList">
    <button *ngIf="showTrash" class="quantity-list-item del" type="button" (click)="setQuantity(0)">
      <ui-icon size="20px" icon="trash"></ui-icon>
    </button>
    <button
      class="quantity-list-item"
      [class.selected]="qty === quantity"
      type="button"
      *ngFor="let qty of rangeArray; let first = first"
      (click)="setQuantity(qty)"
    >
      {{ qty }}

      <button class="close-btn" (click)="close()">
        <ui-icon *ngIf="first" class="r-90deg" size="14px" icon="arrow_head"></ui-icon>
      </button>
    </button>
    <button *ngIf="range > 9 || range === undefined" class="quantity-list-item" type="button" (click)="enableCustomInput.emit()">
      10+
    </button>
  </div>
</ng-template>
