<div class="shared-splitscreen__side" *ngIf="desktopLarge()" [class.shared-splitscreen__hidden]="!sideActivated()">
  <router-outlet
    *ngIf="desktopLarge()"
    #sideOutlet="outlet"
    (activate)="onActivate()"
    (deactivate)="onDeactivate()"
    name="side"
  ></router-outlet>
</div>
<div class="shared-splitscreen__gap" *ngIf="desktopLarge()" [class.shared-splitscreen__hidden]="!sideActivated()"></div>
<div class="shared-splitscreen__primary">
  <router-outlet #primaryOutlet="outlet"></router-outlet>
</div>
