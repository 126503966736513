import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@ui/icon';
import { UiCheckboxComponent } from './checkbox.component';
import { UiCheckboxGroupComponent } from './checkbox-group.component';
import { IconModule } from '@shared/components/icon';

@NgModule({
  declarations: [UiCheckboxComponent, UiCheckboxGroupComponent],
  imports: [CommonModule, UiIconModule, IconModule],
  exports: [UiCheckboxComponent, UiCheckboxGroupComponent],
})
export class UiCheckboxModule {}
