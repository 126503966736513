<ng-container [formGroup]="control">
  <shared-form-control class="col-span-2" label="Firmenname">
    <input
      placeholder="Firmenname"
      class="input-control"
      type="text"
      formControlName="name"
      [tabindex]="tabIndexStart"
      [autofocus]="focusAfterInit"
      [readonly]="readonly"
    />
  </shared-form-control>
  <ng-container *ngIf="appearence === 'default'">
    <shared-form-control label="Abteilung">
      <input
        placeholder="Abteilung"
        class="input-control"
        type="text"
        formControlName="department"
        [tabindex]="tabIndexStart + 1"
        [readonly]="readonly"
      />
    </shared-form-control>
    <shared-form-control label="USt-ID">
      <input
        placeholder="USt-ID"
        class="input-control"
        type="text"
        formControlName="vatId"
        [tabindex]="tabIndexStart + 2"
        [readonly]="readonly"
      />
    </shared-form-control>
  </ng-container>
</ng-container>
