<button class="modal-close-btn" (click)="close()" *ngIf="ref.config.canClose">
  <ui-icon icon="close" size="21px"></ui-icon>
</button>

<h1 *ngIf="!!title" class="modal-title">{{ title }}</h1>

<div class="modal-content scroll-bar" [class.scrollX]="ref.config.showScrollbarX" [class.scrollY]="ref.config.showScrollbarY">
  <ng-container [ngSwitch]="contentType">
    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="templateRef; context: context"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="componentType"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'string'">
      <div [innerHTML]="content"></div>
    </ng-container>
  </ng-container>
</div>
