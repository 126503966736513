<div class="wrapper">
  <h2>Warenkorb kann dem Kunden nicht zugewiesen werden</h2>
  <p>
    <!-- Der Kundentyp ist mit der gewählten Kaufoption nicht kombinierbar. -->
    {{ ref.data.message }}
  </p>

  <div class="cta-wrapper">
    <a class="search-btn" [routerLink]="customerSearchNav.path" [queryParams]="queryParams" (click)="ref.close()">Zur Kundensuche</a>
    <button class="create-btn" (click)="upgradeCustomer()">Kundendaten erfassen</button>
  </div>
</div>
