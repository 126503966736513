import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSelectedFilterOptionsComponent } from './components';
import { SelectedFiltersPipe, CheckAllChildOptionsSelectedPipe, FilterResetWordingPipe } from './pipe';
import { UiIconModule } from '@ui/icon';

@NgModule({
  imports: [CommonModule, UiIconModule],
  exports: [UiSelectedFilterOptionsComponent, CheckAllChildOptionsSelectedPipe, FilterResetWordingPipe],
  declarations: [UiSelectedFilterOptionsComponent, SelectedFiltersPipe, FilterResetWordingPipe, CheckAllChildOptionsSelectedPipe],
  providers: [],
})
export class UiSelectedFilterOptionsModule {}
