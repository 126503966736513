import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShellProcessBarComponent } from './process-bar.component';
import { UiIconModule } from '@ui/icon';
import { ShellProcessBarItemComponent } from './process-bar-item/process-bar-item.component';
import { RouterModule } from '@angular/router';
import { CdkMenuModule } from '@angular/cdk/menu';
import { AuthModule } from '@core/auth';
import { IconModule } from '@shared/components/icon';

@NgModule({
  imports: [CommonModule, UiIconModule, IconModule, RouterModule, CdkMenuModule, AuthModule],
  exports: [ShellProcessBarComponent],
  declarations: [ShellProcessBarComponent, ShellProcessBarItemComponent],
})
export class ProcessBarModule {}
