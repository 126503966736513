/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OmsConfiguration as __Configuration } from '../oms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfQuerySettingsDTO } from '../models/response-args-of-query-settings-dto';
import { ResponseArgsOfIEnumerableOfAutocompleteDTO } from '../models/response-args-of-ienumerable-of-autocomplete-dto';
import { AutocompleteTokenDTO } from '../models/autocomplete-token-dto';
import { ListResponseArgsOfDBHOrderItemListItemDTO } from '../models/list-response-args-of-dbhorder-item-list-item-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ListResponseArgsOfOrderItemListItemDTO } from '../models/list-response-args-of-order-item-list-item-dto';
import { ResponseArgsOfInteger } from '../models/response-args-of-integer';
@Injectable({
  providedIn: 'root',
})
class AbholfachService extends __BaseService {
  static readonly AbholfachWareneingangQuerySettingsPath = '/wareneingang/s/settings';
  static readonly AbholfachWareneingangAutocompletePath = '/wareneingang/s/complete';
  static readonly AbholfachWareneingangPath = '/wareneingang/s';
  static readonly AbholfachWareneingangslisteQuerySettingsPath = '/wareneingangsliste/s/settings';
  static readonly AbholfachWareneingangslistePath = '/wareneingangsliste';
  static readonly AbholfachWarenausgabeQuerySettingsPath = '/warenausgabe/s/settings';
  static readonly AbholfachWarenausgabeAutocompletePath = '/warenausgabe/s/complete';
  static readonly AbholfachWarenausgabePath = '/warenausgabe/s';
  static readonly AbholfachAbholfachbereinigungslistePath = '/abholfach/abholfachbereinigungsliste';
  static readonly AbholfachAbholfachremissionsvorschauPath = '/abholfach/abholfachremissionsvorschau';
  static readonly AbholfachCreateAbholfachremissionPath = '/abholfach/abholfachremission';
  static readonly AbholfachReservierungenPath = '/abholfach/reservierungen';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Wareneingang-Filter
   */
  AbholfachWareneingangQuerySettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/wareneingang/s/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      })
    );
  }
  /**
   * Wareneingang-Filter
   */
  AbholfachWareneingangQuerySettings(): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.AbholfachWareneingangQuerySettingsResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfQuerySettingsDTO)
    );
  }

  /**
   * Autocomplete für Wareneingang
   * @param payload Suchkriterien
   */
  AbholfachWareneingangAutocompleteResponse(payload?: AutocompleteTokenDTO): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/wareneingang/s/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>;
      })
    );
  }
  /**
   * Autocomplete für Wareneingang
   * @param payload Suchkriterien
   */
  AbholfachWareneingangAutocomplete(payload?: AutocompleteTokenDTO): __Observable<ResponseArgsOfIEnumerableOfAutocompleteDTO> {
    return this.AbholfachWareneingangAutocompleteResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfAutocompleteDTO)
    );
  }

  /**
   * Suche nach Bestellposten für Wareneingang
   * @param payload Suchkriterien
   */
  AbholfachWareneingangResponse(payload?: QueryTokenDTO): __Observable<__StrictHttpResponse<ListResponseArgsOfDBHOrderItemListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/wareneingang/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfDBHOrderItemListItemDTO>;
      })
    );
  }
  /**
   * Suche nach Bestellposten für Wareneingang
   * @param payload Suchkriterien
   */
  AbholfachWareneingang(payload?: QueryTokenDTO): __Observable<ListResponseArgsOfDBHOrderItemListItemDTO> {
    return this.AbholfachWareneingangResponse(payload).pipe(
      __map(_r => _r.body as ListResponseArgsOfDBHOrderItemListItemDTO)
    );
  }

  /**
   * Wareneingangsliste-Filter
   */
  AbholfachWareneingangslisteQuerySettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/wareneingangsliste/s/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      })
    );
  }
  /**
   * Wareneingangsliste-Filter
   */
  AbholfachWareneingangslisteQuerySettings(): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.AbholfachWareneingangslisteQuerySettingsResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfQuerySettingsDTO)
    );
  }

  /**
   * Wareneingangsliste
   * @param payload Suchkriterien
   */
  AbholfachWareneingangslisteResponse(payload: QueryTokenDTO): __Observable<__StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/wareneingangsliste`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>;
      })
    );
  }
  /**
   * Wareneingangsliste
   * @param payload Suchkriterien
   */
  AbholfachWareneingangsliste(payload: QueryTokenDTO): __Observable<ListResponseArgsOfOrderItemListItemDTO> {
    return this.AbholfachWareneingangslisteResponse(payload).pipe(
      __map(_r => _r.body as ListResponseArgsOfOrderItemListItemDTO)
    );
  }

  /**
   * Warenausgabe-Filter
   */
  AbholfachWarenausgabeQuerySettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/warenausgabe/s/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      })
    );
  }
  /**
   * Warenausgabe-Filter
   */
  AbholfachWarenausgabeQuerySettings(): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.AbholfachWarenausgabeQuerySettingsResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfQuerySettingsDTO)
    );
  }

  /**
   * Autocomplete für Warenausgabe
   * @param payload Suchkriterien
   */
  AbholfachWarenausgabeAutocompleteResponse(payload?: AutocompleteTokenDTO): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/warenausgabe/s/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>;
      })
    );
  }
  /**
   * Autocomplete für Warenausgabe
   * @param payload Suchkriterien
   */
  AbholfachWarenausgabeAutocomplete(payload?: AutocompleteTokenDTO): __Observable<ResponseArgsOfIEnumerableOfAutocompleteDTO> {
    return this.AbholfachWarenausgabeAutocompleteResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfAutocompleteDTO)
    );
  }

  /**
   * Suche nach Bestellposten für die Warenausgabe
   * @param payload Suchkriterien
   */
  AbholfachWarenausgabeResponse(payload?: QueryTokenDTO): __Observable<__StrictHttpResponse<ListResponseArgsOfDBHOrderItemListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/warenausgabe/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfDBHOrderItemListItemDTO>;
      })
    );
  }
  /**
   * Suche nach Bestellposten für die Warenausgabe
   * @param payload Suchkriterien
   */
  AbholfachWarenausgabe(payload?: QueryTokenDTO): __Observable<ListResponseArgsOfDBHOrderItemListItemDTO> {
    return this.AbholfachWarenausgabeResponse(payload).pipe(
      __map(_r => _r.body as ListResponseArgsOfDBHOrderItemListItemDTO)
    );
  }

  /**
   * Abholfachbereinigungsliste
   */
  AbholfachAbholfachbereinigungslisteResponse(): __Observable<__StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/abholfach/abholfachbereinigungsliste`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>;
      })
    );
  }
  /**
   * Abholfachbereinigungsliste
   */
  AbholfachAbholfachbereinigungsliste(): __Observable<ListResponseArgsOfOrderItemListItemDTO> {
    return this.AbholfachAbholfachbereinigungslisteResponse().pipe(
      __map(_r => _r.body as ListResponseArgsOfOrderItemListItemDTO)
    );
  }

  /**
   * Abholfachremissionsvorschau
   */
  AbholfachAbholfachremissionsvorschauResponse(): __Observable<__StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/abholfach/abholfachremissionsvorschau`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>;
      })
    );
  }
  /**
   * Abholfachremissionsvorschau
   */
  AbholfachAbholfachremissionsvorschau(): __Observable<ListResponseArgsOfOrderItemListItemDTO> {
    return this.AbholfachAbholfachremissionsvorschauResponse().pipe(
      __map(_r => _r.body as ListResponseArgsOfOrderItemListItemDTO)
    );
  }

  /**
   * Abholfachremittenden erzeugen
   */
  AbholfachCreateAbholfachremissionResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/abholfach/abholfachremission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInteger>;
      })
    );
  }
  /**
   * Abholfachremittenden erzeugen
   */
  AbholfachCreateAbholfachremission(): __Observable<ResponseArgsOfInteger> {
    return this.AbholfachCreateAbholfachremissionResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfInteger)
    );
  }

  /**
   * Reservierungen
   * @param payload undefined
   */
  AbholfachReservierungenResponse(payload: QueryTokenDTO): __Observable<__StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/abholfach/reservierungen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>;
      })
    );
  }
  /**
   * Reservierungen
   * @param payload undefined
   */
  AbholfachReservierungen(payload: QueryTokenDTO): __Observable<ListResponseArgsOfOrderItemListItemDTO> {
    return this.AbholfachReservierungenResponse(payload).pipe(
      __map(_r => _r.body as ListResponseArgsOfOrderItemListItemDTO)
    );
  }
}

module AbholfachService {
}

export { AbholfachService }
