/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { EisConfiguration as __Configuration } from '../eis-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfDisplayInfoDTO } from '../models/list-response-args-of-display-info-dto';
import { DisplayInfoRequest } from '../models/display-info-request';
import { ResponseArgsOfIEnumerableOfInputDTO } from '../models/response-args-of-ienumerable-of-input-dto';
import { ResponseArgsOfQuerySettingsDTO } from '../models/response-args-of-query-settings-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { DashboardInfoRequest } from '../models/dashboard-info-request';
import { ResponseArgsOfEntityDTOContainerOfBranchDTO } from '../models/response-args-of-entity-dtocontainer-of-branch-dto';
import { ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString } from '../models/response-args-of-ienumerable-of-entity-key-value-dtoof-string-and-string';
import { ResponseArgsOfDisplayInfoDTO } from '../models/response-args-of-display-info-dto';
import { ResponseArgsOfConfirmationDTO } from '../models/response-args-of-confirmation-dto';
import { ResponseArgs } from '../models/response-args';
import { FileDTO } from '../models/file-dto';
import { ResponseArgsOfIEnumerableOfFileDTO } from '../models/response-args-of-ienumerable-of-file-dto';
import { ResponseArgsOfIEnumerableOfArticleDTO } from '../models/response-args-of-ienumerable-of-article-dto';
import { ResponseArgsOfIEnumerableOfCommentDTO } from '../models/response-args-of-ienumerable-of-comment-dto';
import { ResponseArgsOfCommentDTO } from '../models/response-args-of-comment-dto';
import { CommentDTO } from '../models/comment-dto';
@Injectable({
  providedIn: 'root',
})
class EISPublicService extends __BaseService {
  static readonly EISPublicGetDisplayInfosPath = '/eis/intranet/info';
  static readonly EISPublicQueryDisplayInfoFilterPath = '/eis/intranet/filter';
  static readonly EISPublicQueryDisplayInfoFilter2Path = '/eis/intranet/info/s/filter';
  static readonly EISPublicQueryDisplayInfoSettingsPath = '/eis/intranet/info/s/settings';
  static readonly EISPublicQueryDisplayInfoPath = '/eis/intranet/info/s';
  static readonly EISPublicGetDashboardInfosPath = '/eis/intranet/dashboard';
  static readonly EISPublicGetCurrentBranchPath = '/eis/intranet/currentbranch';
  static readonly EISPublicGetCategoriesPath = '/eis/intranet/categories';
  static readonly EISPublicGetDisplayInfoByIdPath = '/eis/intranet/info/{infoId}';
  static readonly EISPublicSetInfoToEditPath = '/eis/intranet/info/{infoId}/edit';
  static readonly EISPublicResetConfirmationPath = '/eis/intranet/info/{infoId}/reset';
  static readonly EISPublicCompleteConfirmationPath = '/eis/intranet/info/{infoId}/complete';
  static readonly EISPublicGetFilesPath = '/eis/intranet/info/{infoId}/file';
  static readonly EISPublicGetFileContentPath = '/eis/intranet/file/{fileId}';
  static readonly EISPublicGetTeaserImagePath = '/eis/intranet/info/{infoId}/image/teaser';
  static readonly EISPublicGetImagesPath = '/eis/intranet/info/{infoId}/image';
  static readonly EISPublicGetArticlesPath = '/eis/intranet/info/{infoId}/article';
  static readonly EISPublicGetConfirmationFilesPath = '/eis/intranet/info/{infoId}/confirmation/{confirmationId}/file';
  static readonly EISPublicGetConfirmationFilesByBranchIdPath = '/eis/intranet/branch/{branchId}/info/{infoId}/confirmation/file';
  static readonly EISPublicGetCommentsByBranchIdPath = '/eis/intranet/branch/{branchId}/info/{infoId}/comment';
  static readonly EISPublicAddCommentPath = '/eis/intranet/info/{infoId}/comment';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Infos
   * @param params The `EISPublicService.EISPublicGetDisplayInfosParams` containing the following parameters:
   *
   * - `data`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  EISPublicGetDisplayInfosResponse(params: EISPublicService.EISPublicGetDisplayInfosParams): __Observable<__StrictHttpResponse<ListResponseArgsOfDisplayInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/eis/intranet/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfDisplayInfoDTO>;
      })
    );
  }
  /**
   * Infos
   * @param params The `EISPublicService.EISPublicGetDisplayInfosParams` containing the following parameters:
   *
   * - `data`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  EISPublicGetDisplayInfos(params: EISPublicService.EISPublicGetDisplayInfosParams): __Observable<ListResponseArgsOfDisplayInfoDTO> {
    return this.EISPublicGetDisplayInfosResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfDisplayInfoDTO)
    );
  }

  /**
   * Filterwerte
   */
  EISPublicQueryDisplayInfoFilterResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>;
      })
    );
  }
  /**
   * Filterwerte
   */
  EISPublicQueryDisplayInfoFilter(): __Observable<ResponseArgsOfIEnumerableOfInputDTO> {
    return this.EISPublicQueryDisplayInfoFilterResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfInputDTO)
    );
  }

  /**
   * Filterwerte
   */
  EISPublicQueryDisplayInfoFilter2Response(): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/info/s/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>;
      })
    );
  }
  /**
   * Filterwerte
   */
  EISPublicQueryDisplayInfoFilter2(): __Observable<ResponseArgsOfIEnumerableOfInputDTO> {
    return this.EISPublicQueryDisplayInfoFilter2Response().pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfInputDTO)
    );
  }

  /**
   * Filterwerte
   */
  EISPublicQueryDisplayInfoSettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/info/s/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      })
    );
  }
  /**
   * Filterwerte
   */
  EISPublicQueryDisplayInfoSettings(): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.EISPublicQueryDisplayInfoSettingsResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfQuerySettingsDTO)
    );
  }

  /**
   * Infos / Aufgaben suchen
   * @param params The `EISPublicService.EISPublicQueryDisplayInfoParams` containing the following parameters:
   *
   * - `queryToken`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  EISPublicQueryDisplayInfoResponse(params: EISPublicService.EISPublicQueryDisplayInfoParams): __Observable<__StrictHttpResponse<ListResponseArgsOfDisplayInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/eis/intranet/info/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfDisplayInfoDTO>;
      })
    );
  }
  /**
   * Infos / Aufgaben suchen
   * @param params The `EISPublicService.EISPublicQueryDisplayInfoParams` containing the following parameters:
   *
   * - `queryToken`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  EISPublicQueryDisplayInfo(params: EISPublicService.EISPublicQueryDisplayInfoParams): __Observable<ListResponseArgsOfDisplayInfoDTO> {
    return this.EISPublicQueryDisplayInfoResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfDisplayInfoDTO)
    );
  }

  /**
   * Infos
   * @param params The `EISPublicService.EISPublicGetDashboardInfosParams` containing the following parameters:
   *
   * - `data`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  EISPublicGetDashboardInfosResponse(params: EISPublicService.EISPublicGetDashboardInfosParams): __Observable<__StrictHttpResponse<ListResponseArgsOfDisplayInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/eis/intranet/dashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfDisplayInfoDTO>;
      })
    );
  }
  /**
   * Infos
   * @param params The `EISPublicService.EISPublicGetDashboardInfosParams` containing the following parameters:
   *
   * - `data`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  EISPublicGetDashboardInfos(params: EISPublicService.EISPublicGetDashboardInfosParams): __Observable<ListResponseArgsOfDisplayInfoDTO> {
    return this.EISPublicGetDashboardInfosResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfDisplayInfoDTO)
    );
  }

  /**
   * Dem Benutzer zugeordnete Filiale
   * @param params The `EISPublicService.EISPublicGetCurrentBranchParams` containing the following parameters:
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  EISPublicGetCurrentBranchResponse(params: EISPublicService.EISPublicGetCurrentBranchParams): __Observable<__StrictHttpResponse<ResponseArgsOfEntityDTOContainerOfBranchDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/currentbranch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfEntityDTOContainerOfBranchDTO>;
      })
    );
  }
  /**
   * Dem Benutzer zugeordnete Filiale
   * @param params The `EISPublicService.EISPublicGetCurrentBranchParams` containing the following parameters:
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `eagerLoading`: eagerLoading (optional)
   */
  EISPublicGetCurrentBranch(params: EISPublicService.EISPublicGetCurrentBranchParams): __Observable<ResponseArgsOfEntityDTOContainerOfBranchDTO> {
    return this.EISPublicGetCurrentBranchResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfEntityDTOContainerOfBranchDTO)
    );
  }

  /**
   * Kategorien / Bereiche
   * @param locale Lokalisierung (optional)
   */
  EISPublicGetCategoriesResponse(locale?: null | string): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString>;
      })
    );
  }
  /**
   * Kategorien / Bereiche
   * @param locale Lokalisierung (optional)
   */
  EISPublicGetCategories(locale?: null | string): __Observable<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString> {
    return this.EISPublicGetCategoriesResponse(locale).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString)
    );
  }

  /**
   * Info
   * @param params The `EISPublicService.EISPublicGetDisplayInfoByIdParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicGetDisplayInfoByIdResponse(params: EISPublicService.EISPublicGetDisplayInfoByIdParams): __Observable<__StrictHttpResponse<ResponseArgsOfDisplayInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfDisplayInfoDTO>;
      })
    );
  }
  /**
   * Info
   * @param params The `EISPublicService.EISPublicGetDisplayInfoByIdParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicGetDisplayInfoById(params: EISPublicService.EISPublicGetDisplayInfoByIdParams): __Observable<ResponseArgsOfDisplayInfoDTO> {
    return this.EISPublicGetDisplayInfoByIdResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfDisplayInfoDTO)
    );
  }

  /**
   * In Bearbeitung
   * @param params The `EISPublicService.EISPublicSetInfoToEditParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicSetInfoToEditResponse(params: EISPublicService.EISPublicSetInfoToEditParams): __Observable<__StrictHttpResponse<ResponseArgsOfConfirmationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}/edit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfConfirmationDTO>;
      })
    );
  }
  /**
   * In Bearbeitung
   * @param params The `EISPublicService.EISPublicSetInfoToEditParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicSetInfoToEdit(params: EISPublicService.EISPublicSetInfoToEditParams): __Observable<ResponseArgsOfConfirmationDTO> {
    return this.EISPublicSetInfoToEditResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfConfirmationDTO)
    );
  }

  /**
   * Bearbeitungsstatus zurücksetzen
   * @param params The `EISPublicService.EISPublicResetConfirmationParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicResetConfirmationResponse(params: EISPublicService.EISPublicResetConfirmationParams): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}/reset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      })
    );
  }
  /**
   * Bearbeitungsstatus zurücksetzen
   * @param params The `EISPublicService.EISPublicResetConfirmationParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicResetConfirmation(params: EISPublicService.EISPublicResetConfirmationParams): __Observable<ResponseArgs> {
    return this.EISPublicResetConfirmationResponse(params).pipe(
      __map(_r => _r.body as ResponseArgs)
    );
  }

  /**
   * Bearbeitungsstatus zurücksetzen
   * @param params The `EISPublicService.EISPublicCompleteConfirmationParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `file`: Datei (optional)
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicCompleteConfirmationResponse(params: EISPublicService.EISPublicCompleteConfirmationParams): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.file;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      })
    );
  }
  /**
   * Bearbeitungsstatus zurücksetzen
   * @param params The `EISPublicService.EISPublicCompleteConfirmationParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `file`: Datei (optional)
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicCompleteConfirmation(params: EISPublicService.EISPublicCompleteConfirmationParams): __Observable<ResponseArgs> {
    return this.EISPublicCompleteConfirmationResponse(params).pipe(
      __map(_r => _r.body as ResponseArgs)
    );
  }

  /**
   * Dateien
   * @param params The `EISPublicService.EISPublicGetFilesParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicGetFilesResponse(params: EISPublicService.EISPublicGetFilesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfFileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}/file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfFileDTO>;
      })
    );
  }
  /**
   * Dateien
   * @param params The `EISPublicService.EISPublicGetFilesParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicGetFiles(params: EISPublicService.EISPublicGetFilesParams): __Observable<ResponseArgsOfIEnumerableOfFileDTO> {
    return this.EISPublicGetFilesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfFileDTO)
    );
  }

  /**
   * Datei-Inhalt
   * @param params The `EISPublicService.EISPublicGetFileContentParams` containing the following parameters:
   *
   * - `fileId`: Datei PK
   *
   * - `download`: Datei wird als Download gesendet (optional)
   *
   * @return Datei
   */
  EISPublicGetFileContentResponse(params: EISPublicService.EISPublicGetFileContentParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.download != null) __params = __params.set('download', params.download.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/file/${encodeURIComponent(params.fileId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Datei-Inhalt
   * @param params The `EISPublicService.EISPublicGetFileContentParams` containing the following parameters:
   *
   * - `fileId`: Datei PK
   *
   * - `download`: Datei wird als Download gesendet (optional)
   *
   * @return Datei
   */
  EISPublicGetFileContent(params: EISPublicService.EISPublicGetFileContentParams): __Observable<Blob> {
    return this.EISPublicGetFileContentResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Teaser Image
   * @param params The `EISPublicService.EISPublicGetTeaserImageParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `download`: Datei wird als Download gesendet (optional)
   *
   * @return Datei
   */
  EISPublicGetTeaserImageResponse(params: EISPublicService.EISPublicGetTeaserImageParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.download != null) __params = __params.set('download', params.download.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}/image/teaser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Teaser Image
   * @param params The `EISPublicService.EISPublicGetTeaserImageParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `download`: Datei wird als Download gesendet (optional)
   *
   * @return Datei
   */
  EISPublicGetTeaserImage(params: EISPublicService.EISPublicGetTeaserImageParams): __Observable<Blob> {
    return this.EISPublicGetTeaserImageResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Dateien
   * @param params The `EISPublicService.EISPublicGetImagesParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicGetImagesResponse(params: EISPublicService.EISPublicGetImagesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfFileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}/image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfFileDTO>;
      })
    );
  }
  /**
   * Dateien
   * @param params The `EISPublicService.EISPublicGetImagesParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicGetImages(params: EISPublicService.EISPublicGetImagesParams): __Observable<ResponseArgsOfIEnumerableOfFileDTO> {
    return this.EISPublicGetImagesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfFileDTO)
    );
  }

  /**
   * Artikel
   * @param params The `EISPublicService.EISPublicGetArticlesParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicGetArticlesResponse(params: EISPublicService.EISPublicGetArticlesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfArticleDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}/article`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfArticleDTO>;
      })
    );
  }
  /**
   * Artikel
   * @param params The `EISPublicService.EISPublicGetArticlesParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicGetArticles(params: EISPublicService.EISPublicGetArticlesParams): __Observable<ResponseArgsOfIEnumerableOfArticleDTO> {
    return this.EISPublicGetArticlesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfArticleDTO)
    );
  }

  /**
   * Dateien
   * @param params The `EISPublicService.EISPublicGetConfirmationFilesParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `confirmationId`: Aufgabenabschluss PK
   *
   * - `locale`:
   */
  EISPublicGetConfirmationFilesResponse(params: EISPublicService.EISPublicGetConfirmationFilesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfFileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}/confirmation/${encodeURIComponent(params.confirmationId)}/file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfFileDTO>;
      })
    );
  }
  /**
   * Dateien
   * @param params The `EISPublicService.EISPublicGetConfirmationFilesParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `confirmationId`: Aufgabenabschluss PK
   *
   * - `locale`:
   */
  EISPublicGetConfirmationFiles(params: EISPublicService.EISPublicGetConfirmationFilesParams): __Observable<ResponseArgsOfIEnumerableOfFileDTO> {
    return this.EISPublicGetConfirmationFilesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfFileDTO)
    );
  }

  /**
   * Dateien
   * @param params The `EISPublicService.EISPublicGetConfirmationFilesByBranchIdParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `branchId`: Filiale PK
   *
   * - `locale`:
   */
  EISPublicGetConfirmationFilesByBranchIdResponse(params: EISPublicService.EISPublicGetConfirmationFilesByBranchIdParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfFileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/branch/${encodeURIComponent(params.branchId)}/info/${encodeURIComponent(params.infoId)}/confirmation/file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfFileDTO>;
      })
    );
  }
  /**
   * Dateien
   * @param params The `EISPublicService.EISPublicGetConfirmationFilesByBranchIdParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `branchId`: Filiale PK
   *
   * - `locale`:
   */
  EISPublicGetConfirmationFilesByBranchId(params: EISPublicService.EISPublicGetConfirmationFilesByBranchIdParams): __Observable<ResponseArgsOfIEnumerableOfFileDTO> {
    return this.EISPublicGetConfirmationFilesByBranchIdResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfFileDTO)
    );
  }

  /**
   * Anmerkungen / Notizen
   * @param params The `EISPublicService.EISPublicGetCommentsByBranchIdParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `branchId`: Filiale PK
   *
   * - `locale`:
   */
  EISPublicGetCommentsByBranchIdResponse(params: EISPublicService.EISPublicGetCommentsByBranchIdParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfCommentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eis/intranet/branch/${encodeURIComponent(params.branchId)}/info/${encodeURIComponent(params.infoId)}/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfCommentDTO>;
      })
    );
  }
  /**
   * Anmerkungen / Notizen
   * @param params The `EISPublicService.EISPublicGetCommentsByBranchIdParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `branchId`: Filiale PK
   *
   * - `locale`:
   */
  EISPublicGetCommentsByBranchId(params: EISPublicService.EISPublicGetCommentsByBranchIdParams): __Observable<ResponseArgsOfIEnumerableOfCommentDTO> {
    return this.EISPublicGetCommentsByBranchIdResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfCommentDTO)
    );
  }

  /**
   * Anmerkung / Notiz hinzufügen
   * @param params The `EISPublicService.EISPublicAddCommentParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicAddCommentResponse(params: EISPublicService.EISPublicAddCommentParams): __Observable<__StrictHttpResponse<ResponseArgsOfCommentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/eis/intranet/info/${encodeURIComponent(params.infoId)}/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCommentDTO>;
      })
    );
  }
  /**
   * Anmerkung / Notiz hinzufügen
   * @param params The `EISPublicService.EISPublicAddCommentParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `infoId`: Info PK
   *
   * - `locale`:
   */
  EISPublicAddComment(params: EISPublicService.EISPublicAddCommentParams): __Observable<ResponseArgsOfCommentDTO> {
    return this.EISPublicAddCommentResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfCommentDTO)
    );
  }
}

module EISPublicService {

  /**
   * Parameters for EISPublicGetDisplayInfos
   */
  export interface EISPublicGetDisplayInfosParams {

    /**
     * Abfragedaten
     */
    data: DisplayInfoRequest;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicQueryDisplayInfo
   */
  export interface EISPublicQueryDisplayInfoParams {

    /**
     * Abfragedaten
     */
    queryToken: QueryTokenDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicGetDashboardInfos
   */
  export interface EISPublicGetDashboardInfosParams {

    /**
     * Abfragedaten
     */
    data: DashboardInfoRequest;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicGetCurrentBranch
   */
  export interface EISPublicGetCurrentBranchParams {

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;

    /**
     * eagerLoading (optional)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for EISPublicGetDisplayInfoById
   */
  export interface EISPublicGetDisplayInfoByIdParams {

    /**
     * Info PK
     */
    infoId: number;
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicSetInfoToEdit
   */
  export interface EISPublicSetInfoToEditParams {

    /**
     * Info PK
     */
    infoId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicResetConfirmation
   */
  export interface EISPublicResetConfirmationParams {

    /**
     * Info PK
     */
    infoId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicCompleteConfirmation
   */
  export interface EISPublicCompleteConfirmationParams {

    /**
     * Info PK
     */
    infoId: number;

    /**
     * Datei (optional)
     */
    file: FileDTO;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicGetFiles
   */
  export interface EISPublicGetFilesParams {

    /**
     * Info PK
     */
    infoId: number;
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicGetFileContent
   */
  export interface EISPublicGetFileContentParams {

    /**
     * Datei PK
     */
    fileId: number;

    /**
     * Datei wird als Download gesendet (optional)
     */
    download?: boolean;
  }

  /**
   * Parameters for EISPublicGetTeaserImage
   */
  export interface EISPublicGetTeaserImageParams {

    /**
     * Info PK
     */
    infoId: number;

    /**
     * Datei wird als Download gesendet (optional)
     */
    download?: boolean;
  }

  /**
   * Parameters for EISPublicGetImages
   */
  export interface EISPublicGetImagesParams {

    /**
     * Info PK
     */
    infoId: number;
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicGetArticles
   */
  export interface EISPublicGetArticlesParams {

    /**
     * Info PK
     */
    infoId: number;
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicGetConfirmationFiles
   */
  export interface EISPublicGetConfirmationFilesParams {

    /**
     * Info PK
     */
    infoId: number;

    /**
     * Aufgabenabschluss PK
     */
    confirmationId: number;
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicGetConfirmationFilesByBranchId
   */
  export interface EISPublicGetConfirmationFilesByBranchIdParams {

    /**
     * Info PK
     */
    infoId: number;

    /**
     * Filiale PK
     */
    branchId: number;
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicGetCommentsByBranchId
   */
  export interface EISPublicGetCommentsByBranchIdParams {

    /**
     * Info PK
     */
    infoId: number;

    /**
     * Filiale PK
     */
    branchId: number;
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicAddComment
   */
  export interface EISPublicAddCommentParams {

    /**
     * Daten
     */
    payload: CommentDTO;

    /**
     * Info PK
     */
    infoId: number;
    locale?: null | string;
  }
}

export { EISPublicService }
