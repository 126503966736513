import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { IUiInputGroup, UiInput, UiInputGroup } from '../../tree';
import { UiFilterComponent } from '../../ui-filter.component';

@Component({
  selector: 'ui-filter-filter-group-filter',
  templateUrl: 'filter-filter-group-filter.component.html',
  styleUrls: ['filter-filter-group-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiFilterFilterGroupFilterComponent {
  private _inputGroup: UiInputGroup;

  @Input()
  set inputGroup(value: IUiInputGroup) {
    if (value instanceof UiInputGroup) {
      this._inputGroup = value;
    } else {
      this._inputGroup = UiInputGroup.create(value);
    }
  }

  get uiInputGroup() {
    return this._inputGroup;
  }

  private _activeInput: UiInput;

  get activeInput() {
    return this.uiInputGroup?.input?.find((f) => f?.key === this._activeInput?.key) || this.uiInputGroup?.input?.find((f) => f);
  }

  get isFirstInputSelected() {
    return this.activeInput === this.uiInputGroup?.input?.find((f) => f);
  }

  get inputTemplate(): TemplateRef<any> {
    return this._hostComponent.customInputs?.find((f) => f.key === this.activeInput?.key)?.templateRef;
  }

  constructor(private _hostComponent: UiFilterComponent) {}

  setActiveInput(input: UiInput) {
    this._activeInput = input;
  }
}
