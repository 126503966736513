import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebshopCustomnerAlreadyExistsModalComponent } from './webshop-customer-already-exists-modal.component';

@NgModule({
  imports: [CommonModule],
  exports: [WebshopCustomnerAlreadyExistsModalComponent],
  declarations: [WebshopCustomnerAlreadyExistsModalComponent],
})
export class WebshopCustomnerAlreadyExistsModalModule {}
