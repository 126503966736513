/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CrmConfiguration as __Configuration } from '../crm-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfPayerDTO } from '../models/response-args-of-payer-dto';
import { PayerDTO } from '../models/payer-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
import { ResponseArgsOfIEnumerableOfHistoryDTO } from '../models/response-args-of-ienumerable-of-history-dto';
@Injectable({
  providedIn: 'root',
})
class PayerService extends __BaseService {
  static readonly PayerCreatePayerPath = '/payer';
  static readonly PayerUpdatePayerPath = '/payer/{payerId}';
  static readonly PayerGetPayerPath = '/payer/{payerId}';
  static readonly PayerDeactivatePayerPath = '/payer/{payerId}/deactivate';
  static readonly PayerDeletePayerPath = '/payer/{payerId}/delete';
  static readonly PayerGetPayerHistoryPath = '/payer/{payerId}/history';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Neue(r) Regulierer/Rechnungsadresse
   * @param payer Reguliererdaten/Rechnungsadresse
   */
  PayerCreatePayerResponse(payer: PayerDTO): __Observable<__StrictHttpResponse<ResponseArgsOfPayerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payer;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/payer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPayerDTO>;
      })
    );
  }
  /**
   * Neue(r) Regulierer/Rechnungsadresse
   * @param payer Reguliererdaten/Rechnungsadresse
   */
  PayerCreatePayer(payer: PayerDTO): __Observable<ResponseArgsOfPayerDTO> {
    return this.PayerCreatePayerResponse(payer).pipe(
      __map(_r => _r.body as ResponseArgsOfPayerDTO)
    );
  }

  /**
   * Änderung eines/einer bestehenden Regulierers/Rechnungsadresse
   * @param params The `PayerService.PayerUpdatePayerParams` containing the following parameters:
   *
   * - `payerId`: PK Regulierer/Rechnungsadresse
   *
   * - `payer`: Reguliererdaten/Rechnungsadresse
   */
  PayerUpdatePayerResponse(params: PayerService.PayerUpdatePayerParams): __Observable<__StrictHttpResponse<ResponseArgsOfPayerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payer;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/payer/${encodeURIComponent(String(params.payerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPayerDTO>;
      })
    );
  }
  /**
   * Änderung eines/einer bestehenden Regulierers/Rechnungsadresse
   * @param params The `PayerService.PayerUpdatePayerParams` containing the following parameters:
   *
   * - `payerId`: PK Regulierer/Rechnungsadresse
   *
   * - `payer`: Reguliererdaten/Rechnungsadresse
   */
  PayerUpdatePayer(params: PayerService.PayerUpdatePayerParams): __Observable<ResponseArgsOfPayerDTO> {
    return this.PayerUpdatePayerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfPayerDTO)
    );
  }

  /**
   * @param payerId undefined
   */
  PayerGetPayerResponse(payerId: number): __Observable<__StrictHttpResponse<ResponseArgsOfPayerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/payer/${encodeURIComponent(String(payerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPayerDTO>;
      })
    );
  }
  /**
   * @param payerId undefined
   */
  PayerGetPayer(payerId: number): __Observable<ResponseArgsOfPayerDTO> {
    return this.PayerGetPayerResponse(payerId).pipe(
      __map(_r => _r.body as ResponseArgsOfPayerDTO)
    );
  }

  /**
   * Deaktivieren eines/einer bestehenden Regulierers/Rechnungsadresse
   * @param params The `PayerService.PayerDeactivatePayerParams` containing the following parameters:
   *
   * - `payerId`: PK Regulierer/Rechnungsadresse
   *
   * - `deactivationComment`: Deaktivierungsgrund
   */
  PayerDeactivatePayerResponse(params: PayerService.PayerDeactivatePayerParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.deactivationComment != null) __params = __params.set('deactivationComment', params.deactivationComment.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/payer/${encodeURIComponent(String(params.payerId))}/deactivate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Deaktivieren eines/einer bestehenden Regulierers/Rechnungsadresse
   * @param params The `PayerService.PayerDeactivatePayerParams` containing the following parameters:
   *
   * - `payerId`: PK Regulierer/Rechnungsadresse
   *
   * - `deactivationComment`: Deaktivierungsgrund
   */
  PayerDeactivatePayer(params: PayerService.PayerDeactivatePayerParams): __Observable<ResponseArgsOfBoolean> {
    return this.PayerDeactivatePayerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }

  /**
   * Löschen eines/einer bestehenden Regulierers/Rechnungsadresse
   *
   * Setzt den Datensatz auf gelöscht und anonymisiert benutzerbezogene Daten. Außerdem wird die ReguliererNummer PayerNumber verändert.
   * @param params The `PayerService.PayerDeletePayerParams` containing the following parameters:
   *
   * - `payerId`: PK Regulierer/Rechnungsadresse
   *
   * - `deletionComment`: Grund des Löschens
   */
  PayerDeletePayerResponse(params: PayerService.PayerDeletePayerParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.deletionComment != null) __params = __params.set('deletionComment', params.deletionComment.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/payer/${encodeURIComponent(String(params.payerId))}/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Löschen eines/einer bestehenden Regulierers/Rechnungsadresse
   *
   * Setzt den Datensatz auf gelöscht und anonymisiert benutzerbezogene Daten. Außerdem wird die ReguliererNummer PayerNumber verändert.
   * @param params The `PayerService.PayerDeletePayerParams` containing the following parameters:
   *
   * - `payerId`: PK Regulierer/Rechnungsadresse
   *
   * - `deletionComment`: Grund des Löschens
   */
  PayerDeletePayer(params: PayerService.PayerDeletePayerParams): __Observable<ResponseArgsOfBoolean> {
    return this.PayerDeletePayerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }

  /**
   * @param params The `PayerService.PayerGetPayerHistoryParams` containing the following parameters:
   *
   * - `payerId`:
   *
   * - `locale`:
   */
  PayerGetPayerHistoryResponse(params: PayerService.PayerGetPayerHistoryParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/payer/${encodeURIComponent(String(params.payerId))}/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>;
      })
    );
  }
  /**
   * @param params The `PayerService.PayerGetPayerHistoryParams` containing the following parameters:
   *
   * - `payerId`:
   *
   * - `locale`:
   */
  PayerGetPayerHistory(params: PayerService.PayerGetPayerHistoryParams): __Observable<ResponseArgsOfIEnumerableOfHistoryDTO> {
    return this.PayerGetPayerHistoryResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfHistoryDTO)
    );
  }
}

module PayerService {

  /**
   * Parameters for PayerUpdatePayer
   */
  export interface PayerUpdatePayerParams {

    /**
     * PK Regulierer/Rechnungsadresse
     */
    payerId: number;

    /**
     * Reguliererdaten/Rechnungsadresse
     */
    payer: PayerDTO;
  }

  /**
   * Parameters for PayerDeactivatePayer
   */
  export interface PayerDeactivatePayerParams {

    /**
     * PK Regulierer/Rechnungsadresse
     */
    payerId: number;

    /**
     * Deaktivierungsgrund
     */
    deactivationComment?: null | string;
  }

  /**
   * Parameters for PayerDeletePayer
   */
  export interface PayerDeletePayerParams {

    /**
     * PK Regulierer/Rechnungsadresse
     */
    payerId: number;

    /**
     * Grund des Löschens
     */
    deletionComment?: null | string;
  }

  /**
   * Parameters for PayerGetPayerHistory
   */
  export interface PayerGetPayerHistoryParams {
    payerId: number;
    locale?: null | string;
  }
}

export { PayerService }
