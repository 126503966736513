<h2 class="subtitle" *ngIf="modalRef.data.subtitle; let subtitle">{{ subtitle }}</h2>
<p class="content" *ngIf="modalRef.data.content; let content">
  {{ content }}
</p>

<div class="actions" *ngIf="modalRef.data.actions; let actions">
  <button *ngFor="let action of actions" [class.selected]="action.selected" (click)="handleCommand(action.command)">
    {{ action.label }}
  </button>
</div>

<p class="description" *ngIf="modalRef.data.description; let description">
  {{ description }}
</p>
