/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CheckoutConfiguration as __Configuration } from '../checkout-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfKulturPassResult } from '../models/response-args-of-ienumerable-of-kultur-pass-result';
import { ProductDTO } from '../models/product-dto';
@Injectable({
  providedIn: 'root',
})
class KulturPassService extends __BaseService {
  static readonly KulturPassCanAddForKulturPassPath = '/store/shoppingcart/kulturpass/canadd';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Überprüfung, ob die Artikel zu einer KulturPass-Bestellung hinzugefügt werden können
   * @param params The `KulturPassService.KulturPassCanAddForKulturPassParams` containing the following parameters:
   *
   * - `payload`: Daten (EAN und ProductGroup müssen übergeben werden)
   *
   * - `locale`: Lokalisierung
   */
  KulturPassCanAddForKulturPassResponse(params: KulturPassService.KulturPassCanAddForKulturPassParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfKulturPassResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/shoppingcart/kulturpass/canadd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfKulturPassResult>;
      })
    );
  }
  /**
   * Überprüfung, ob die Artikel zu einer KulturPass-Bestellung hinzugefügt werden können
   * @param params The `KulturPassService.KulturPassCanAddForKulturPassParams` containing the following parameters:
   *
   * - `payload`: Daten (EAN und ProductGroup müssen übergeben werden)
   *
   * - `locale`: Lokalisierung
   */
  KulturPassCanAddForKulturPass(params: KulturPassService.KulturPassCanAddForKulturPassParams): __Observable<ResponseArgsOfIEnumerableOfKulturPassResult> {
    return this.KulturPassCanAddForKulturPassResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfKulturPassResult)
    );
  }
}

module KulturPassService {

  /**
   * Parameters for KulturPassCanAddForKulturPass
   */
  export interface KulturPassCanAddForKulturPassParams {

    /**
     * Daten (EAN und ProductGroup müssen übergeben werden)
     */
    payload: Array<ProductDTO>;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }
}

export { KulturPassService }
