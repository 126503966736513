import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ApplicationService } from '@core/application';
import { CustomerDTO } from '@swagger/crm';
import { UiModalRef } from '@ui/modal';
import { encodeFormData, mapCustomerDtoToCustomerCreateFormData } from '../../create-customer';
import { CustomerCreateNavigation } from '@shared/services';

@Component({
  selector: 'page-cant-select-guest-modal',
  templateUrl: 'cant-select-guest-modal.component.html',
  styleUrls: ['cant-select-guest-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CantSelectGuestModalComponent {
  customerCreateNavigation = inject(CustomerCreateNavigation);

  constructor(public ref: UiModalRef<any, CustomerDTO>, public readonly applicationService: ApplicationService) {}

  customerCreatePath() {
    return this.customerCreateNavigation.createCustomerRoute({
      processId: this.applicationService.activatedProcessId,
      customerType: 'guest',
    });
  }

  createCustomerDataQuery(customer: CustomerDTO): { [key: string]: string } {
    const formData = encodeFormData(mapCustomerDtoToCustomerCreateFormData(customer));

    return { formData };
  }
}
