import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UiInput } from '../tree';

export interface UiFilterAutocomplete {
  /**
   * Anzeige / Bezeichner
   */
  display?: string;

  /**
   * Id
   */
  id?: string;

  /**
   * Abfragewert
   */
  query?: string;

  /**
   * Art (z.B. Titel, Autor, Verlag, ...)
   */
  type?: string;
}

@Injectable()
export abstract class UiFilterAutocompleteProvider {
  abstract readonly for: string;

  abstract complete(input: UiInput): Observable<UiFilterAutocomplete[]>;
}
