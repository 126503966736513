/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RemiConfiguration, RemiConfigurationInterface } from './remi-configuration';

import { SDVService } from './services/sdv.service';
import { PackageService } from './services/package.service';
import { RemiService } from './services/remi.service';
import { ReturnService } from './services/return.service';
import { StockService } from './services/stock.service';
import { SupplierService } from './services/supplier.service';

/**
 * Provider for all Remi services, plus RemiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    RemiConfiguration,
    SDVService,
    PackageService,
    RemiService,
    ReturnService,
    StockService,
    SupplierService
  ],
})
export class RemiModule {
  static forRoot(customParams: RemiConfigurationInterface): ModuleWithProviders<RemiModule> {
    return {
      ngModule: RemiModule,
      providers: [
        {
          provide: RemiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
