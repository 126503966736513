import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NetworkStatusService {
  online$ = new Observable<boolean>((subscriber) => {
    const handler = () => subscriber.next(navigator.onLine);

    window.addEventListener('online', handler);
    window.addEventListener('offline', handler);

    handler();

    return () => {
      window.removeEventListener('online', handler);
      window.removeEventListener('offline', handler);
    };
  });

  status$ = this.online$.pipe(map((online) => (online ? 'online' : 'offline')));
}

export const injectNetworkStatus$ = () => inject(NetworkStatusService).status$;

export const injectOnline$ = () => inject(NetworkStatusService).online$;
