import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiSelectComponent } from './ui-select.component';
import { UiIconModule } from '@ui/icon';
import { UiSelectOptionComponent } from './ui-select-option.component';

@NgModule({
  imports: [CommonModule, UiIconModule],
  exports: [UiSelectComponent, UiSelectOptionComponent],
  declarations: [UiSelectComponent, UiSelectOptionComponent],
})
export class UiSelectModule {}
