import { ModuleWithProviders, NgModule } from '@angular/core';
import { DomainRemissionService } from './remission.service';

@NgModule({})
export class DomainRemissionModule {
  static forRoot(): ModuleWithProviders<DomainRemissionModule> {
    return {
      ngModule: RootDomainRemissionModule,
    };
  }
}

@NgModule({
  imports: [],
  providers: [DomainRemissionService],
})
export class RootDomainRemissionModule {}
