import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { NEVER, Observable, throwError } from 'rxjs';
import { UiMessageModalComponent, UiModalService } from '@ui/modal';
import { catchError, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '@core/auth';
import { IsaLogProvider } from '../providers';
import { LogLevel } from '@core/logger';
import { injectOnline$ } from '../services/network-status.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  readonly offline$ = injectOnline$().pipe(filter((online) => !online));

  constructor(
    private _modal: UiModalService,
    private _auth: AuthService,
    private _isaLogProvider: IsaLogProvider,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      takeUntil(this.offline$),
      catchError((error: HttpErrorResponse, caught: any) => this.handleError(error)),
    );
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      return this._modal
        .open({
          content: UiMessageModalComponent,
          title: 'Sie sind nicht mehr angemeldet',
          data: { message: 'Sie werden neu angemeldet' },
        })
        .afterClosed$.pipe(
          tap(() => {
            this._auth.login();
          }),
          mergeMap(() => NEVER),
        );
    }

    if (!error.url.endsWith('/isa/logging')) {
      this._isaLogProvider.log(LogLevel.ERROR, 'Http Error', error);
    }

    return throwError(error);
  }
}
