import { Injectable } from '@angular/core';
import { Config } from '@core/config';
import { Gender } from './defs';
import { GenderValue } from './types';

@Injectable({ providedIn: 'root' })
export class GenderSettingsService {
  private get _genderConfig(): Record<string, string> {
    return this._config.get('gender');
  }

  get genders(): Gender[] {
    return this._transformGendersArray();
  }

  constructor(private _config: Config) {}

  getGenderByValue(value: GenderValue): string {
    return this._genderConfig[value];
  }

  private _transformGendersArray(): Gender[] {
    const genders: Gender[] = [];

    for (const key of Object.keys(this._genderConfig)) {
      const value = Number(key) as GenderValue;
      genders.push({ value, label: this._genderConfig[key] });
    }

    // #4603 Genders sorted reverse { "4": "Frau", "2": "Mann", "1": "Enby", "0": "Keine Angabe" }
    return genders.sort((a, b) => (a.value > b.value ? -1 : 1));
  }
}
