import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CantSelectGuestModalComponent } from './cant-select-guest-modal.component';
import { UiIconModule } from '@ui/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, UiIconModule, RouterModule],
  exports: [CantSelectGuestModalComponent],
  declarations: [CantSelectGuestModalComponent],
})
export class CantSelectGuestModalModule {}
