import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UiModalComponent } from './modal.component';
import { UiModalService } from './modal.service';
import { UiDebugModalComponent } from './debug-modal/debug-modal.component';
import { UiMessageModalComponent } from './message-modal.component';
import { UiIconModule } from '@ui/icon';
import { UiDialogModalComponent } from './dialog/dialog-modal.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OpenDialogInterceptor } from './dialog/open-dialog.interceptor';
import { UiPromptModalComponent } from './prompt-modal';

@NgModule({
  imports: [CommonModule, OverlayModule, UiIconModule],
  declarations: [UiModalComponent, UiDebugModalComponent, UiMessageModalComponent, UiDialogModalComponent, UiPromptModalComponent],
  exports: [UiModalComponent, UiDebugModalComponent, UiMessageModalComponent, UiDialogModalComponent, UiPromptModalComponent],
})
export class UiModalModule {
  static forRoot(): ModuleWithProviders<UiModalModule> {
    return {
      ngModule: UiModalModule,
      providers: [
        UiModalService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: OpenDialogInterceptor,
          multi: true,
        },
      ],
    };
  }
}
