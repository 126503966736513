import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScanditOverlayComponent } from './scandit-overlay.component';
import { ScanditScanAdapter } from './scandit.scan-adapter';
import { SCAN_ADAPTER } from '../tokens';

@NgModule({
  imports: [CommonModule],
  exports: [ScanditOverlayComponent],
  declarations: [ScanditOverlayComponent],
})
export class ScanditScanAdapterModule {
  static forRoot() {
    return {
      ngModule: ScanditScanAdapterModule,
      providers: [{ provide: SCAN_ADAPTER, useClass: ScanditScanAdapter, multi: true }],
    };
  }
}
