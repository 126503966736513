<form *ngIf="formData$ | async; let data" (keydown.enter)="$event.preventDefault()">
  <h1 class="title flex flex-row items-center justify-center">Kundenkartendaten erfasen</h1>
  <p class="description">Bitte erfassen Sie die Kundenkarte</p>

  <app-customer-type-selector [processId]="processId$ | async" [p4mUser]="true" customerType="webshop" [readonly]="true">
  </app-customer-type-selector>

  <app-p4m-number-form-block
    #p4mBlock
    [tabIndexStart]="1"
    (onInit)="addFormBlock('p4m', $event)"
    [data]="data.p4m"
    (dataChanges)="patchFormData('p4m', $event)"
    [focusAfterInit]="!data?._meta?.p4mRequired"
    [asyncValidatorFns]="asyncLoyaltyCardValidatorFn"
  >
  </app-p4m-number-form-block>

  <app-accept-agb-form-block
    [tabIndexStart]="inBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('agb', $event)"
    [data]="data.agb"
    (dataChanges)="patchFormData('agb', $event)"
    [requiredMark]="true"
    [validatorFns]="agbValidatorFns"
  >
  </app-accept-agb-form-block>

  <app-newsletter-form-block
    class="mb-4"
    #newsletterBlock
    [tabIndexStart]="p4mBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('newsletter', $event)"
    [data]="data.newsletter"
    (dataChanges)="patchFormData('newsletter', $event)"
    [focusAfterInit]="data?._meta?.p4mRequired"
  >
  </app-newsletter-form-block>

  <app-name-form-block (onInit)="addFormBlock('name', $event)" [data]="data.name" readonly> </app-name-form-block>

  <app-email-form-block (onInit)="addFormBlock('email', $event)" [data]="data.email" readonly> </app-email-form-block>

  <app-organisation-form-block
    (onInit)="addFormBlock('organisation', $event)"
    appearence="compact"
    [data]="data.organisation"
    readonly
  ></app-organisation-form-block>

  <app-address-form-block [data]="data.address" readonly> </app-address-form-block>

  <div class="mt-8">
    <h4 class="-mb-6">Rechnungsadresse</h4>
    <ui-form-control class="-mb-5" [showHint]="false">
      <input type="text" [value]="(billingAddress | address:true) ?? 'Keine Adresse vorhanden'" [readonly]="true" />
    </ui-form-control>
    <app-deviating-address-form-block
      #dbaBlock
      [defaults]="{ address: { country: 'DEU' } }"
      [tabIndexStart]="newsletterBlock.tabIndexEnd + 1"
      (onInit)="addFormBlock('billingAddress', $event)"
      [data]="data.billingAddress"
      (dataChanges)="patchFormData('billingAddress', $event)"
      [nameRequiredMarks]="nameRequiredMarks"
      [nameValidatorFns]="nameValidationFns"
      [addressRequiredMarks]="addressRequiredMarks"
      [addressValidatorFns]="addressValidatorFns"
    >
      Abweichende Rechnungsadresse anlegen
    </app-deviating-address-form-block>
  </div>

  <div class="mt-8">
    <h4 class="-mb-6">Lieferadresse</h4>
    <ui-form-control class="-mb-5" [showHint]="false">
      <input type="text" [value]="(shippingAddress | address:true) ?? 'Keine Adresse vorhanden'" [readonly]="true" />
    </ui-form-control>
    <app-deviating-address-form-block
      #ddaBlock
      [defaults]="{ address: { country: 'DEU' } }"
      [tabIndexStart]="dbaBlock.tabIndexEnd + 1"
      (onInit)="addFormBlock('deviatingDeliveryAddress', $event)"
      [data]="data.deviatingDeliveryAddress"
      (dataChanges)="patchFormData('deviatingDeliveryAddress', $event)"
      [nameRequiredMarks]="nameRequiredMarks"
      [nameValidatorFns]="nameValidationFns"
      [addressRequiredMarks]="addressRequiredMarks"
      [addressValidatorFns]="addressValidatorFns"
    >
      Abweichende Lieferadresse anlegen
    </app-deviating-address-form-block>
  </div>

  <app-birth-date-form-block
    #bdBlock
    [tabIndexStart]="ddaBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('birthDate', $event)"
    [data]="data.birthDate"
    (dataChanges)="patchFormData('birthDate', $event)"
    [requiredMark]="true"
    [validatorFns]="birthDateValidatorFns"
  >
  </app-birth-date-form-block>

  <app-interests-form-block
    #inBlock
    [tabIndexStart]="bdBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('interests', $event)"
    [data]="data.interests"
    (dataChanges)="patchFormData('interests', $event)"
  ></app-interests-form-block>

  <div class="spacer"></div>

  <div class="sticky w-full flex items-center justify-center">
    <button class="cta-submit" type="button" [disabled]="form.invalid || form.pending" (click)="save()">
      <ui-spinner [show]="busy$ | async">Speichern</ui-spinner>
    </button>
  </div>
</form>
