<div class="fixed inset-x-0 top-0 z-fixed">
  <shell-top-bar class="max-w-[1920px] desktop-xx-large:max-w-[2448px] mx-auto"></shell-top-bar>
</div>

<shell-side-menu
  class="fixed top-[8.25rem] bottom-0 z-[148] -translate-x-full transition-transform duration-200 ease-in-out desktop-small:translate-x-0 desktop-small:transition-none"
  [class.translate-x-0]="sideMenuOpen$ | async"
></shell-side-menu>

<div class="fixed z-[149] desktop-small:z-[0] top-[4.625rem] inset-x-0">
  <div class="max-w-[1920px] desktop-xx-large:max-w-[2448px] mx-auto flex flex-row">
    <shell-process-bar class="grow"></shell-process-bar>
  </div>
</div>

<main
  class="ml-0 desktop-small:ml-[10.5rem] desktop-x-large:ml-[16.5rem] px-4 pt-[0.1875rem] desktop-small:px-6 remove-padding"
  (click)="closeSideMenu()"
>
  <shell-toaster-outlet></shell-toaster-outlet>
  <div class="mx-auto w-full">
    <ng-content></ng-content>
  </div>
</main>
