import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormBlockGroup } from '../form-block';
import { OrganisationFormBlockData } from './organisation-form-block-data';

@Component({
  selector: 'app-organisation-form-block',
  templateUrl: 'organisation-form-block.component.html',
  styleUrls: ['organisation-form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationFormBlockComponent extends FormBlockGroup<OrganisationFormBlockData> {
  @Input()
  appearence: 'default' | 'compact' = 'default';

  get tabIndexEnd() {
    return this.tabIndexStart + 2;
  }

  constructor() {
    super();
  }

  initializeControl(data?: OrganisationFormBlockData): void {
    this.control = new UntypedFormGroup({
      name: new UntypedFormControl(data?.name ?? '', this.getValidatorFn('name')),
      department: new UntypedFormControl(data?.department ?? '', this.getValidatorFn('department')),
      vatId: new UntypedFormControl(data?.vatId ?? '', this.getValidatorFn('vatId')),
    });
  }
  _patchValue(update: { previous: OrganisationFormBlockData; current: OrganisationFormBlockData }): void {
    this.control.patchValue({
      name: update.current?.name ?? '',
      department: update.current?.department ?? '',
      vatId: update.current?.vatId ?? '',
    });
  }

  updateValidators(): void {
    this.control.setValidators(this.getValidatorFn('name'));
    this.control.get('name').updateValueAndValidity();
    this.control.setValidators(this.getValidatorFn('department'));
    this.control.get('department').updateValueAndValidity();
    this.control.setValidators(this.getValidatorFn('vatId'));
    this.control.get('vatId').updateValueAndValidity();
  }
}
