import { Injectable, inject } from '@angular/core';
import { NavigationRoute } from './defs/navigation-route';
import { Router } from '@angular/router';
import { OrderItemProcessingStatusValue } from '@swagger/oms';
import { isBoolean } from 'lodash';

@Injectable({ providedIn: 'root' })
export class PickUpShelfOutNavigationService {
  private readonly _router = inject(Router);

  defaultRoute({ processId }: { processId?: number }): NavigationRoute {
    const path = ['/kunde', processId, 'pickup-shelf', { outlets: { primary: ['main'], side: ['search'] } }].filter((v) => !!v);

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  listRoute({ processId }: { processId?: number }): NavigationRoute {
    const path = ['/kunde', processId ? processId : undefined, 'pickup-shelf', { outlets: { primary: ['list'], side: null } }].filter(
      (v) => !!v
    );

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  filterRoute({ processId }: { processId?: number }): NavigationRoute {
    const path = [
      '/kunde',
      processId ? processId : undefined,
      'pickup-shelf',
      { outlets: { primary: ['list', 'filter'], side: ['list'] } },
    ].filter((v) => !!v);

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  detailRoute({
    processId,
    item,
  }: {
    processId?: number;
    item: {
      orderId: number;
      orderNumber: string;
      compartmentCode?: string;
      compartmentInfo?: string;
      processingStatus: OrderItemProcessingStatusValue;
    };
  }): NavigationRoute {
    let path: any[];

    if (item.compartmentCode) {
      path = [
        '/kunde',
        processId ? processId : undefined,
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              'compartment',
              item.compartmentCode ? encodeURIComponent(item.compartmentCode) : undefined,
              item.compartmentInfo ? encodeURIComponent(item.compartmentInfo) : undefined,
              'status',
              item.processingStatus,
            ].filter((v) => !!v),
            side: ['list'],
          },
        },
      ].filter((v) => !!v);
    } else {
      path = [
        '/kunde',
        processId ? processId : undefined,
        'pickup-shelf',
        {
          outlets: {
            primary: ['order', item.orderId, encodeURIComponent(item.orderNumber), 'item', 'status', item.processingStatus].filter(
              (v) => !!v
            ),
            side: ['list'],
          },
        },
      ].filter((v) => !!v);
    }

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  editRoute({
    processId,
    item,
  }: {
    processId?: number;
    item: {
      orderId: number;
      orderNumber: string;
      compartmentCode: string;
      compartmentInfo: string;
      processingStatus: OrderItemProcessingStatusValue;
    };
  }): NavigationRoute {
    let path: any[];

    if (item.compartmentCode) {
      path = [
        '/kunde',
        processId ? processId : undefined,
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              'compartment',
              item.compartmentCode ? encodeURIComponent(item.compartmentCode) : undefined,
              item.compartmentInfo ? encodeURIComponent(item.compartmentInfo) : undefined,
              'status',
              item.processingStatus,
              'edit',
            ].filter((v) => !!v),
            side: ['list'],
          },
        },
      ].filter((v) => !!v);
    } else {
      path = [
        '/kunde',
        processId ? processId : undefined,
        'pickup-shelf',
        {
          outlets: {
            primary: ['order', item.orderId, encodeURIComponent(item.orderNumber), 'item', 'status', item.processingStatus, 'edit'].filter(
              (v) => !!v
            ),
            side: ['list'],
          },
        },
      ].filter((v) => !!v);
    }

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  historyRoute({
    processId,
    item,
  }: {
    processId?: number;
    item: {
      orderId: number;
      orderNumber: string;
      compartmentCode: string;
      compartmentInfo: string;
      processingStatus: OrderItemProcessingStatusValue;
    };
  }): NavigationRoute {
    let path: any[];

    if (item.compartmentCode) {
      path = [
        '/kunde',
        processId ? processId : undefined,
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              'compartment',
              item.compartmentCode ? encodeURIComponent(item.compartmentCode) : undefined,
              item.compartmentInfo ? encodeURIComponent(item.compartmentInfo) : undefined,
              'status',
              item.processingStatus,
              'history',
            ].filter((v) => !!v),
            side: ['list'],
          },
        },
      ].filter((v) => !!v);
    } else {
      path = [
        '/kunde',
        processId ? processId : undefined,
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              encodeURIComponent(item.orderNumber),
              'item',
              'status',
              item.processingStatus,
              'history',
            ].filter((v) => !!v),
            side: ['list'],
          },
        },
      ].filter((v) => !!v);
    }

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }
}
