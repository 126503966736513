import { Subject } from 'rxjs';
import { UiFilter } from './ui-filter';

export interface IUiOrderBy {
  by?: string;
  desc?: boolean;
  label?: string;
  selected?: boolean;
}

export class UiOrderBy implements IUiOrderBy {
  //#region implements IUiFilterOrderByDTO
  private _by?: string;
  get by() {
    return this._by;
  }

  private _desc?: boolean;
  get desc() {
    return this._desc;
  }

  private _label?: string;
  get label() {
    return this._label;
  }

  private _selected?: boolean;
  get selected() {
    return this._selected;
  }

  //#endregion

  constructor(public readonly parent?: UiFilter) {}

  readonly changes = new Subject<{ keys: (keyof IUiOrderBy)[]; orderBy: UiOrderBy }>();

  setSelected(value: boolean, { emitEvent }: { emitEvent: boolean } = { emitEvent: true }) {
    if (this.selected !== value) {
      this._selected = value;

      if (emitEvent) {
        this.changes.next({ keys: ['selected'], orderBy: this });
      }
    }
  }

  toObject(): IUiOrderBy {
    return {
      by: this.by,
      desc: this.desc,
      label: this.label,
      selected: this.selected,
    };
  }

  static create(orderBy: IUiOrderBy, parent?: UiFilter) {
    const target = new UiOrderBy(parent);

    target._by = orderBy?.by;
    target._desc = orderBy?.desc;
    target._label = orderBy?.label;
    target._selected = orderBy?.selected;

    return target;
  }
}
