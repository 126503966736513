import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFormControlComponent } from './ui-form-control.component';
import { UiIconModule } from '@ui/icon';
import { UiFormControlFirstErrorPipe } from './ui-form-first-error.pipe';

@NgModule({
  imports: [CommonModule, UiIconModule],
  exports: [UiFormControlComponent],
  declarations: [UiFormControlComponent, UiFormControlFirstErrorPipe],
})
export class UiFormControlModule {}
