import { ModuleWithProviders, NgModule } from '@angular/core';
import { DomainCatalogService } from './catalog.service';
import { ThumbnailUrlPipe } from './thumbnail-url.pipe';
import { DomainCatalogThumbnailService } from './thumbnail.service';

@NgModule({
  declarations: [ThumbnailUrlPipe],
  imports: [],
  exports: [ThumbnailUrlPipe],
})
export class DomainCatalogModule {
  static forRoot(): ModuleWithProviders<DomainCatalogModule> {
    return {
      ngModule: DomainCatalogModule,
      providers: [DomainCatalogService, DomainCatalogThumbnailService],
    };
  }
}
