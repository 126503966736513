/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { EisConfiguration as __Configuration } from '../eis-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfString } from '../models/response-args-of-string';
import { DocumentPayloadOfIEnumerableOfString } from '../models/document-payload-of-ienumerable-of-string';
import { DocumentPayloadOfIEnumerableOfDisplayInfoDTO } from '../models/document-payload-of-ienumerable-of-display-info-dto';
@Injectable({
  providedIn: 'root',
})
class EISPublicDocumentService extends __BaseService {
  static readonly EISPublicDocumentGetArticlesPdfAsBase64Path = '/eis/intranet/info/article/pdf/base64';
  static readonly EISPublicDocumentGetInfosPdfAsBase64Path = '/eis/intranet/info/pdf/base64';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Artikelliste als PDF (base64)
   * @param params The `EISPublicDocumentService.EISPublicDocumentGetArticlesPdfAsBase64Params` containing the following parameters:
   *
   * - `payload`: DocumentPayload mit EANsK
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicDocumentGetArticlesPdfAsBase64Response(params: EISPublicDocumentService.EISPublicDocumentGetArticlesPdfAsBase64Params): __Observable<__StrictHttpResponse<ResponseArgsOfString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/eis/intranet/info/article/pdf/base64`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfString>;
      })
    );
  }
  /**
   * Artikelliste als PDF (base64)
   * @param params The `EISPublicDocumentService.EISPublicDocumentGetArticlesPdfAsBase64Params` containing the following parameters:
   *
   * - `payload`: DocumentPayload mit EANsK
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicDocumentGetArticlesPdfAsBase64(params: EISPublicDocumentService.EISPublicDocumentGetArticlesPdfAsBase64Params): __Observable<ResponseArgsOfString> {
    return this.EISPublicDocumentGetArticlesPdfAsBase64Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfString)
    );
  }

  /**
   * Aufgaben und Infos als PDF (base64)
   * @param params The `EISPublicDocumentService.EISPublicDocumentGetInfosPdfAsBase64Params` containing the following parameters:
   *
   * - `payload`: DocumentPayload mit Infos
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicDocumentGetInfosPdfAsBase64Response(params: EISPublicDocumentService.EISPublicDocumentGetInfosPdfAsBase64Params): __Observable<__StrictHttpResponse<ResponseArgsOfString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/eis/intranet/info/pdf/base64`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfString>;
      })
    );
  }
  /**
   * Aufgaben und Infos als PDF (base64)
   * @param params The `EISPublicDocumentService.EISPublicDocumentGetInfosPdfAsBase64Params` containing the following parameters:
   *
   * - `payload`: DocumentPayload mit Infos
   *
   * - `locale`: Lokalisierung (optional)
   */
  EISPublicDocumentGetInfosPdfAsBase64(params: EISPublicDocumentService.EISPublicDocumentGetInfosPdfAsBase64Params): __Observable<ResponseArgsOfString> {
    return this.EISPublicDocumentGetInfosPdfAsBase64Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfString)
    );
  }
}

module EISPublicDocumentService {

  /**
   * Parameters for EISPublicDocumentGetArticlesPdfAsBase64
   */
  export interface EISPublicDocumentGetArticlesPdfAsBase64Params {

    /**
     * DocumentPayload mit EANsK
     */
    payload: DocumentPayloadOfIEnumerableOfString;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISPublicDocumentGetInfosPdfAsBase64
   */
  export interface EISPublicDocumentGetInfosPdfAsBase64Params {

    /**
     * DocumentPayload mit Infos
     */
    payload: DocumentPayloadOfIEnumerableOfDisplayInfoDTO;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }
}

export { EISPublicDocumentService }
