import { Pipe, PipeTransform } from '@angular/core';
import { ProductImageService } from './product-image.service';

@Pipe({
  name: 'productImage',
  standalone: true,
  pure: true,
})
export class ProductImagePipe implements PipeTransform {
  constructor(private imageService: ProductImageService) {}
  transform(imageId: string, width?: number, height?: number, showDummy?: boolean): any {
    return this.imageService.getImageUrl({ imageId, width, height, showDummy });
  }
}
