import { Pipe, PipeTransform } from '@angular/core';
import { DateAdapter } from '@ui/common';

@Pipe({
  name: 'getCellName',
})
export class GetCellNamePipe implements PipeTransform {
  constructor(private dateAdapter: DateAdapter) {}

  transform(value: any, ...args: any[]): any {
    return this.dateAdapter.getDateName(value);
  }
}
