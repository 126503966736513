import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BaseNavigationService } from './base-navigation.service';

@Injectable({ providedIn: 'root' })
export class NavigationService extends BaseNavigationService {
  constructor(private _router: Router) {
    super();
  }

  protected _createNavigationFn(path?: any[], extras?: NavigationExtras): () => Promise<boolean> {
    return () => this._router.navigate(path, extras);
  }
}
