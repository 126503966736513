import { TargetDTO } from './target.dto';

export interface EnvelopeDTO<T> {
  uId?: string;

  timestamp?: string;

  sequence?: number;

  target?: TargetDTO;

  action?: string;

  data?: T;
}
