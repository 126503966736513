import { NgModule } from '@angular/core';
import { Config } from '@core/config';
import { AvConfiguration } from '@swagger/availability';
import { CatConfiguration } from '@swagger/cat';
import { CheckoutConfiguration } from '@swagger/checkout';
import { CrmConfiguration } from '@swagger/crm';
import { EisConfiguration } from '@swagger/eis';
import { IsaConfiguration } from '@swagger/isa';
import { OmsConfiguration } from '@swagger/oms';
import { PrintConfiguration } from '@swagger/print';
import { RemiConfiguration } from '@swagger/remi';
import { WwsConfiguration } from '@swagger/wws';

export function createConfigurationFactory(name: string) {
  return function (config: Config): { rootUrl: string } {
    return config.get(`@swagger/${name}`);
  };
}

@NgModule({
  providers: [
    { provide: AvConfiguration, useFactory: createConfigurationFactory('av'), deps: [Config] },
    { provide: CatConfiguration, useFactory: createConfigurationFactory('cat'), deps: [Config] },
    { provide: CheckoutConfiguration, useFactory: createConfigurationFactory('checkout'), deps: [Config] },
    { provide: CrmConfiguration, useFactory: createConfigurationFactory('crm'), deps: [Config] },
    { provide: EisConfiguration, useFactory: createConfigurationFactory('eis'), deps: [Config] },
    { provide: IsaConfiguration, useFactory: createConfigurationFactory('isa'), deps: [Config] },
    { provide: OmsConfiguration, useFactory: createConfigurationFactory('oms'), deps: [Config] },
    { provide: PrintConfiguration, useFactory: createConfigurationFactory('print'), deps: [Config] },
    { provide: RemiConfiguration, useFactory: createConfigurationFactory('remi'), deps: [Config] },
    { provide: WwsConfiguration, useFactory: createConfigurationFactory('wws'), deps: [Config] },
  ],
})
export class AppSwaggerModule {}
