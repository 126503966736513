import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddressFormBlockComponent } from './address-form-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlComponent } from '@shared/components/form-control';
import { SelectModule } from '@shared/components/select';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormControlComponent, SelectModule],
  exports: [AddressFormBlockComponent],
  declarations: [AddressFormBlockComponent],
})
export class AddressFormBlockModule {}
