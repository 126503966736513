import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IUiInputGroup, UiInputGroup } from '../../tree';

@Component({
  selector: 'ui-filter-filter-group-main',
  templateUrl: 'filter-filter-group-main.component.html',
  styleUrls: ['filter-filter-group-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiFilterFilterGroupMainComponent {
  private _inputGroup: UiInputGroup;

  @Input()
  set inputGroup(value: IUiInputGroup) {
    if (value instanceof UiInputGroup) {
      this._inputGroup = value;
    } else {
      this._inputGroup = UiInputGroup.create(value);
    }
  }

  get uiInputGroup() {
    return this._inputGroup;
  }

  constructor() {}
}
