/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { EisConfiguration, EisConfigurationInterface } from './eis-configuration';

import { EISBackendService } from './services/eisbackend.service';
import { EISPublicService } from './services/eispublic.service';
import { EISPublicDocumentService } from './services/eispublic-document.service';

/**
 * Provider for all Eis services, plus EisConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    EisConfiguration,
    EISBackendService,
    EISPublicService,
    EISPublicDocumentService
  ],
})
export class EisModule {
  static forRoot(customParams: EisConfigurationInterface): ModuleWithProviders<EisModule> {
    return {
      ngModule: EisModule,
      providers: [
        {
          provide: EisConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
