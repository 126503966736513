import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUiOption, UiOption } from '../../../tree';

@Component({
  selector: 'ui-input-option-bool',
  templateUrl: 'filter-input-option-bool.component.html',
  styleUrls: ['filter-input-option-bool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiFilterInputOptionBoolComponent implements OnDestroy {
  private _option: UiOption;

  @Input()
  set option(value: IUiOption) {
    if (value instanceof UiOption) {
      this._option = value;
    } else {
      this._option = UiOption.create(value);
    }
    this.subscribeChanges();
  }

  @Output() optionChange = new EventEmitter<UiOption>();

  get uiOption() {
    return this._option;
  }

  optionChangeSubscription = new Subscription();

  get hasPartiallyCheckedChildren() {
    const options = this.uiOption?.values ?? [];
    return !options.every((option) => option.selected) && options.some((option) => option.selected);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.unsubscribeChanges();
  }

  subscribeChanges() {
    this.unsubscribeChanges();
    if (this.uiOption) {
      this.optionChangeSubscription.add(
        this.uiOption.changes.subscribe((change) => {
          this.cdr.markForCheck();
          this.optionChange.next(change.target);
        })
      );
    }
  }

  unsubscribeChanges() {
    this.optionChangeSubscription.unsubscribe();
    this.optionChangeSubscription = new Subscription();
  }
}
