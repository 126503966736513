import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  forwardRef,
  ChangeDetectorRef,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-quantity-dropdown',
  templateUrl: 'quantity-dropdown.component.html',
  styleUrls: ['quantity-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: QuantityDropdownComponent, multi: true }],
})
export class QuantityDropdownComponent implements ControlValueAccessor {
  @ViewChild('quantity', { read: ElementRef, static: false })
  quantityInput: ElementRef;

  @Input()
  quantity: number;

  @Output()
  quantityChange = new EventEmitter<number>();

  @Input()
  suffix: string = 'x';

  @Input()
  minQuantity: number = 1;

  showDropdown = false;
  customInput = false;

  @Input()
  disabled: boolean;

  @Input() range?: number = 999;

  @Input() showTrash = true;

  @Input()
  showSpinner: boolean;

  quantityInputValue: number;

  onChange = (_: number) => {};
  onTouched = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(obj: any): void {
    this.quantity = obj;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setQuantity(quantity: number) {
    if (this.disabled) {
      return;
    }

    this.quantity = quantity;
    this.onChange(quantity);
    this.quantityChange.emit(quantity);
    this.showDropdown = false;
    this.customInput = false;
  }

  close() {
    this.setQuantity(0);
  }

  enableCustomeInput() {
    this.customInput = true;
    this.showDropdown = false;
    setTimeout(() => {
      this.quantityInput?.nativeElement?.focus();
    }, 50);
  }

  @HostListener('click', ['$event'])
  select(event: MouseEvent) {
    event?.stopPropagation();

    if (this.disabled) {
      return;
    }

    this.onTouched();
    this.cdr.markForCheck();
  }
}
