<form *ngIf="formData$ | async; let data" (keydown.enter)="$event.preventDefault()">
  <h1 class="title">Kundendaten erfassen</h1>
  <p class="description">
    Wenn Sie möchten legen wir Ihnen <br />
    gerne ein Onlinekonto an. Dort können <br />
    Sie Ihre Bestellungen einsehen.
  </p>

  <app-customer-type-selector
    [processId]="processId$ | async"
    [p4mUser]="false"
    customerType="webshop"
    (valueChanges)="customerTypeChanged($event)"
  >
  </app-customer-type-selector>

  <app-name-form-block
    #name
    [tabIndexStart]="1"
    (onInit)="addFormBlock('name', $event)"
    [requiredMarks]="nameRequiredMarks"
    [validatorFns]="nameValidationFns"
    [data]="data.name"
    (dataChanges)="patchFormData('name', $event)"
  >
  </app-name-form-block>

  <p class="info">
    Wir werden Ihnen Werbung zu ähnlichen Produkten oder Dienstleistungen aus unserem Sortiment per E-Mail zusenden. Sie können der
    Verwendung Ihrer Daten jederzeit z.B. mittels der in den E-Mails enthaltenen Abmeldelinks widersprechen, ohne dass hierfür andere als
    die Übermittlungskosten nach den Basistarifen entstehen.
  </p>

  <app-email-form-block
    #email
    [tabIndexStart]="name.tabIndexEnd + 1"
    (onInit)="addFormBlock('email', $event)"
    [requiredMark]="true"
    [asyncValidatorFns]="asyncEmailValidatorFns"
    [validatorFns]="emailValidatorFns"
    [data]="data.email"
    (dataChanges)="patchFormData('email', $event)"
  ></app-email-form-block>

  <app-organisation-form-block
    #org
    [tabIndexStart]="email.tabIndexEnd + 1"
    appearence="compact"
    (onInit)="addFormBlock('organisation', $event)"
    [data]="data.organisation"
    (dataChanges)="patchFormData('organisation', $event)"
  >
  </app-organisation-form-block>

  <app-address-form-block
    #address
    [tabIndexStart]="org.tabIndexEnd + 1"
    [requiredMarks]="addressRequiredMarks"
    [validatorFns]="addressValidators"
    (onInit)="addFormBlock('address', $event)"
    [defaults]="{ country: 'DEU' }"
    [data]="data.address"
    (dataChanges)="patchFormData('address', $event)"
  >
  </app-address-form-block>

  <p class="info">Das Anlegen geht für Sie noch schneller, wenn wir Ihnen das initiale Passwort per SMS auf Ihr Mobilgerät schicken.</p>

  <app-phone-numbers-form-block
    #phoneNumbers
    [tabIndexStart]="address.tabIndexEnd + 1"
    (onInit)="addFormBlock('phoneNumbers', $event)"
    [data]="data.phoneNumbers"
    (dataChanges)="patchFormData('phoneNumbers', $event)"
  ></app-phone-numbers-form-block>

  <app-birth-date-form-block
    #birthDate
    [tabIndexStart]="phoneNumbers.tabIndexEnd + 1"
    (onInit)="addFormBlock('birthDate', $event)"
    [data]="data.birthDate"
    (dataChanges)="patchFormData('birthDate', $event)"
  ></app-birth-date-form-block>

  <app-deviating-address-form-block
    [tabIndexStart]="birthDate.tabIndexEnd + 1"
    (onInit)="addFormBlock('deviatingDeliveryAddress', $event)"
    appearence="b2b"
    [nameRequiredMarks]="nameRequiredMarks"
    [nameValidatorFns]="nameValidationFns"
    [addressRequiredMarks]="addressRequiredMarks"
    [addressValidatorFns]="addressValidators"
    [defaults]="{ address: { country: 'DEU' } }"
    [data]="data.deviatingDeliveryAddress"
    (dataChanges)="patchFormData('deviatingDeliveryAddress', $event)"
  >
    Die Lieferadresse weicht von der Rechnungsadresse ab
  </app-deviating-address-form-block>

  <div class="spacer"></div>
  <div class="sticky w-full flex items-center justify-center">
    <button class="cta-submit" type="button" [disabled]="form.invalid || form.pending" (click)="save()">
      <ui-spinner [show]="busy$ | async">Speichern</ui-spinner>
    </button>
  </div>
</form>
