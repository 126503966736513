<div
  class="ui-switch-wrapper"
  [class.ui-switch-on]="value === true"
  [class.ui-switch-off]="value === false"
  [class.ui-switch-unset]="value === null || value === undefined"
>
  <button class="ui-switch-btn ui-switch-btn-on" type="button" (click)="setValue(true)">{{ labelOn }}</button>
  <button class="ui-switch-btn ui-switch-btn-unset" type="button" (click)="setValue(undefined)"></button>
  <button class="ui-switch-btn ui-switch-btn-off" type="button" (click)="setValue(false)">{{ labelOff }}</button>
</div>
