function* numberId() {
  let lastId = Date.now();
  while (true) {
    yield lastId++;
  }
}

const numberIdGenerator = numberId();

export function getNumberId(): number {
  return numberIdGenerator.next().value as number;
}
