/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for Eis services
 */
@Injectable({
  providedIn: 'root',
})
export class EisConfiguration {
  rootUrl: string = 'https://eis-test.kubernetes.paragon-systems.de';
}

export interface EisConfigurationInterface {
  rootUrl?: string;
}
