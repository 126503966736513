import { Component, OnInit } from '@angular/core';
import { UiModalRef } from '../defs';
import { ConfirmModalData } from './confirm-modal.data';

@Component({
  selector: 'ui-confirm-modal',
  template: `
    <p class="message">
      {{ modalRef.data.message }}
    </p>
    <div class="actions">
      <button class="cancel" (click)="modalRef.close(false)">{{ modalRef.data.rejectLabel || 'nein' }}</button>
      <button class="confirm" (click)="modalRef.close(true)">{{ modalRef.data.confirmLabel || 'ja' }}</button>
    </div>
  `,
  styleUrls: ['./confirm-modal.component.scss'],
})
export class UiConfirmModalComponent implements OnInit {
  constructor(public modalRef: UiModalRef<boolean, ConfirmModalData>) {}

  ngOnInit() {}
}
