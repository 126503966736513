<div class="shared-select-toggle" [class.has-value]="hasValue" [class.is-open]="isOpen" (click)="toggle()">
  <span class="shared-select-display-value truncate">{{ displayValue }}</span>
  <div class="grid grid-flow-col gap-2">
    <shared-icon class="shared-select-arrow" [icon]="!isOpen ? 'arrow-drop-down' : 'arrow-drop-up'"></shared-icon>
    <shared-icon *ngIf="clearableEnabled && value" class="shared-select-clear" icon="close" (click)="setValue(clearValue)"></shared-icon>
  </div>
</div>
<div class="shared-select-options-outlet">
  <div class="shared-select-options" [class.is-open]="isOpen" [class.force-close]="forceClose">
    <ng-content></ng-content>
  </div>
</div>
