import { Inject, Injectable } from '@angular/core';
import { Config } from '@core/config';
import { CDN_PRODUCT_IMAGE } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class ProductImageService {
  constructor(private readonly _config: Config) {}

  getImageUrl({
    imageId,
    width = 150,
    height = 150,
    showDummy = true,
  }: {
    imageId: string;
    width?: number;
    height?: number;
    showDummy?: boolean;
  }): string {
    return `${this._config.get('@cdn/product-image.url')}/${imageId}_${width}x${height}.jpg?showDummy=${showDummy}`;
  }
}
