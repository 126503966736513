import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

export interface ConsoleLog {
  timestamp?: Date;
  type: 'log' | 'warn' | 'error';
  args: any[];
}

@Injectable()
export class DebugService {
  private _consoleSubject = new BehaviorSubject<ConsoleLog[]>([]);

  logs$ = this._consoleSubject.asObservable();

  constructor() {
    fromEvent(window, 'message').subscribe((event: MessageEvent) => {
      this.add({ type: 'log', args: [event.data] });
    });
  }

  add(log: ConsoleLog) {
    this._consoleSubject.next([...this._consoleSubject.value, { ...log, timestamp: new Date() }]);
  }
}
