import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductImageModule } from '@cdn/product-image';
import { UiCheckboxModule } from '@ui/checkbox';
import { UiCommonModule } from '@ui/common';
import { UiDropdownModule } from '@ui/dropdown';
import { UiIconModule } from '@ui/icon';
import { UiQuantityDropdownModule } from '@ui/quantity-dropdown';
import { UiSelectBulletModule } from '@ui/select-bullet';
import { UiSpinnerModule } from 'apps/ui/spinner/src/lib/ui-spinner.module';
import { ReorderModalComponent } from './reorder.component';
import { SupplierNamePipe } from './supplier-name.pipe';

@NgModule({
  imports: [
    CommonModule,
    UiIconModule,
    ProductImageModule,
    UiSelectBulletModule,
    FormsModule,
    ReactiveFormsModule,
    UiSpinnerModule,
    UiQuantityDropdownModule,
    UiCheckboxModule,
    UiDropdownModule,
    UiCommonModule,
  ],
  exports: [ReorderModalComponent, SupplierNamePipe],
  declarations: [ReorderModalComponent, SupplierNamePipe],
  providers: [],
})
export class ReorderModalModule {}
