import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateGuestCustomerComponent } from './create-guest-customer.component';
import { OrganisationFormBlockModule } from '../../components/form-blocks/organisation';
import { NameFormBlockModule } from '../../components/form-blocks/name';
import { AddressFormBlockModule } from '../../components/form-blocks/address';
import { CustomerTypeSelectorModule } from '../../components/customer-type-selector';
import { BirthDateFormBlockModule, DeviatingAddressFormBlockComponentModule, EmailFormBlockModule } from '../../components/form-blocks';
import { PhoneNumbersFormBlockModule } from '../../components/form-blocks/phone-numbers/phone-numbers-form-block.module';
import { UiSpinnerModule } from '@ui/spinner';

@NgModule({
  imports: [
    CommonModule,
    OrganisationFormBlockModule,
    NameFormBlockModule,
    AddressFormBlockModule,
    DeviatingAddressFormBlockComponentModule,
    CustomerTypeSelectorModule,
    EmailFormBlockModule,
    PhoneNumbersFormBlockModule,
    BirthDateFormBlockModule,
    UiSpinnerModule,
  ],
  exports: [CreateGuestCustomerComponent],
  declarations: [CreateGuestCustomerComponent],
})
export class CreateGuestCustomerModule {}
