import { Filter } from '../filter-group';
import { cloneSelectFilterOption } from './clone-select-filter-option';

export function cloneFilter(filter: Filter) {
  const clone = { ...filter };

  switch (filter.type) {
    case 'select':
      clone.options = Array.isArray(filter.options) ? filter.options.map(cloneSelectFilterOption) : filter.options;
      break;
  }

  return clone;
}
