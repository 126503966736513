import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFilterInputOptionDateRangeComponent } from './filter-input-option-date-range.component';
import { UiDatepickerModule } from '@ui/datepicker';
import { FormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';
import { UiCommonModule } from '@ui/common';

@NgModule({
  imports: [CommonModule, UiCommonModule, UiDatepickerModule, FormsModule, UiIconModule],
  exports: [UiFilterInputOptionDateRangeComponent],
  declarations: [UiFilterInputOptionDateRangeComponent],
})
export class UiFilterInputOptionDateRangeModule {}
