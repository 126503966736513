import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Datepicker } from '../datepicker';
import { map } from 'rxjs/operators';
import { DateAdapter } from '@ui/common';

@Component({
  selector: 'ui-datepicker-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiDatepickerHeaderComponent implements OnInit {
  monthName$ = this.datepicker.displayed$.pipe(map((date) => this.dateAdapter.getMonthName(date)));

  yearName$ = this.datepicker.displayed$.pipe(map((date) => this.dateAdapter.getYearName(date)));

  constructor(private datepicker: Datepicker, private dateAdapter: DateAdapter) {}

  ngOnInit() {}

  next() {
    this.datepicker.nextMonth();
  }

  previous() {
    this.datepicker.previousMonth();
  }
}
