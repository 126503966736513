import { Pipe, PipeTransform } from '@angular/core';
import { OrderProcessingStatus } from '@swagger/oms';

@Pipe({
  name: 'processingStatus',
})
export class ProcessingStatusPipe implements PipeTransform {
  processingStatusText = {
    0: '',
    1: 'offen',
    2: 'in Bearbeitung',
    3: 'abgeschlossen',
  };

  transform(value: OrderProcessingStatus): string {
    return this.processingStatusText[value] || '';
  }
}
