import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UiModalService } from '../modal.service';
import { UiDialogModalComponent } from './dialog-modal.component';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DialogModel } from './dialog.model';
import { ToasterService } from '@shared/shell';

@Injectable()
export class OpenDialogInterceptor implements HttpInterceptor {
  constructor(private _modal: UiModalService, private _toast: ToasterService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          if (response?.body?.dialog?.area === 'dialog') {
            this.openDialog({
              ...response.body.dialog,
              context: response.body,
            });
          }
          if (response?.body?.dialog?.area === 'Toaster') {
            this.createToast({
              ...response.body.dialog,
              context: response.body,
            });
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.dialog?.area === 'dialog') {
          this.openDialog(error.error.dialog);
        }
        if (error?.error?.dialog?.area === 'Toaster') {
          this.createToast(error.error.dialog);
        }
        return throwError(error);
      })
    );
  }

  createToast(dialog: DialogModel<any>) {
    this._toast.open({
      title: dialog.title,
      message: dialog.content,
    });
  }

  openDialog(model: DialogModel<any>) {
    this._modal.open({
      content: UiDialogModalComponent,
      data: model,
      title: model.title,
      config: {
        canClose: (model.settings & 1) === 1,
        hasBackdrop: (model.settings & 2) === 2,
      },
    });
  }
}
