import { Injectable } from '@angular/core';
import { NavigationService } from './navigation.service';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CheckoutNavigationService extends NavigationService {
  constructor(_router: Router) {
    super(_router);
  }

  getCheckoutReviewPath(processId?: number): { path: any[]; extras?: NavigationExtras; navigate: () => Promise<boolean> } {
    const path = ['/kunde', processId, 'cart', { outlets: { primary: ['review'], side: ['details'] } }].filter((x) => !!x);
    return { path, navigate: this._createNavigationFn(path) };
  }

  getCheckoutSummaryPath({
    processId,
    orderIds,
    extras,
  }: {
    processId?: number;
    orderIds?: string;
    extras?: NavigationExtras;
  }): { path: any[]; extras?: NavigationExtras; navigate: () => Promise<boolean> } {
    const path = ['/kunde', processId, 'cart', { outlets: { primary: ['summary', orderIds].filter((x) => !!x), side: null } }].filter(
      (x) => !!x
    );
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }
}
