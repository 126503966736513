import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[uiFocus]', exportAs: 'uiFocus' })
export class UiFocusDirective {
  private _focused = false;
  get focused() {
    return this._focused;
  }

  constructor(private elementRef: ElementRef) {}

  @HostListener('focus')
  onFocus() {
    this._focused = true;
  }

  @HostListener('blur')
  onBlur() {
    this._focused = false;
  }

  focus() {
    this.elementRef.nativeElement.focus();
  }
}
