import { Injectable } from '@angular/core';
import { memorize } from '@utils/common';
import { map, shareReplay } from 'rxjs/operators';
import { DomainCatalogService } from './catalog.service';

@Injectable()
export class DomainCatalogThumbnailService {
  constructor(private domainCatalogService: DomainCatalogService) {}

  @memorize()
  getThumnaulUrl({ ean, height, width }: { width?: number; height?: number; ean?: string }) {
    return this.domainCatalogService.getSettings().pipe(
      map((settings) => {
        let thumbnailUrl = settings.imageUrl.replace(/{ean}/, ean);
        return thumbnailUrl;
      }),
      shareReplay()
    );
  }
}
