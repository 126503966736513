import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input } from '@angular/core';

@Directive()
export class BaseFormControlDirective {
  // @Input()
  // @HostBinding('attr.name')
  // name: string = (Date.now() + Math.random()).toString(32);

  // @Input()
  // @HostBinding('attr.id')
  // id: string = (Date.now() + Math.random()).toString(32);

  private _readonly = false;

  @Input()
  @HostBinding('readonly')
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }

  private _disabled: boolean = false;
  @Input()
  get disabled(): BooleanInput {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
}
