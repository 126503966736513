import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiSearchboxComponent } from './ui-searchbox.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';
import { UiSearchboxWarningComponent } from './ui-searchbox-warning.component';
import { UiSearchboxInputDirective } from './ui-searchbox-input.directive';
import { UiSearchboxAutocompleteComponent } from './ui-searchbox-autocomplete.component';
import { UiSearchboxAutocompleteOptionDirective } from './ui-searchbox-autocomplete-option.directive';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UiIconModule],
  exports: [
    UiSearchboxComponent,
    UiSearchboxWarningComponent,
    UiSearchboxInputDirective,
    UiSearchboxAutocompleteComponent,
    UiSearchboxAutocompleteOptionDirective,
  ],
  declarations: [
    UiSearchboxComponent,
    UiSearchboxWarningComponent,
    UiSearchboxInputDirective,
    UiSearchboxAutocompleteComponent,
    UiSearchboxAutocompleteOptionDirective,
  ],
})
export class UiSearchboxModule {}
