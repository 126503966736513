import { Pipe, PipeTransform } from '@angular/core';
import { SelectFilterOption } from '../../filter-group';
import { UiSelectedFilterOptionsComponent } from '../components';

@Pipe({
  name: 'checkAllChildOptionsSelected',
})
export class CheckAllChildOptionsSelectedPipe implements PipeTransform {
  transform(filter: SelectFilterOption[]): SelectFilterOption[] {
    if (Array.isArray(filter)) {
      return filter.map((f) => {
        if (!f.options) {
          return f;
        }
        const allChildsSelected = f.options.every((opt) => !!opt.selected);

        if (allChildsSelected) {
          const { options, ...filterWithoutOptions } = f;
          return filterWithoutOptions;
        }

        return f;
      });
    }

    return filter;
  }
}
