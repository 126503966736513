<div
  class="flex flex-row justify-start items-center h-full max-w-[1920px] desktop-xx-large:max-w-[2448px] relative"
  (mouseenter)="hovered = true"
  (mouseleave)="hovered = false"
>
  <button
    class="scroll-button prev-button"
    *ngIf="showScrollArrows"
    [class.invisible]="!this.hovered || showArrowLeft"
    (click)="scrollLeft()"
  >
    <ui-icon icon="arrow_head" size="22px" rotate="180deg"></ui-icon>
  </button>
  <div
    #processContainer
    class="grid grid-flow-col max-w-[calc(100vw-9.5rem)] overflow-x-scroll"
    (wheel)="onMouseWheel($event)"
    (scroll)="checkScrollArrowVisibility()"
  >
    <shell-process-bar-item
      *ngFor="let process of processes$ | async; trackBy: trackByFn"
      [process]="process"
      (closed)="checkScrollArrowVisibility()"
    ></shell-process-bar-item>
  </div>
  <button
    class="scroll-button next-button"
    *ngIf="showScrollArrows"
    [class.invisible]="!this.hovered || showArrowRight"
    (click)="scrollRight()"
  >
    <ui-icon icon="arrow_head" size="22px"></ui-icon>
  </button>
  <button
    type="button"
    class="grid px-3 shell-process-bar__create-process-btn-desktop start-process-btn grid-flow-col items-center justify-center gap-[0.625rem] grow-0 shrink-0"
    (click)="createProcess('product')"
    type="button"
  >
    <ng-container *ngTemplateOutlet="createProcessButtonContent"></ng-container>
  </button>
  <div class="grow"></div>
  <button
    type="button"
    [disabled]="!(processes$ | async)?.length"
    class="grow-0 shrink-0 px-3 mr-[.125rem] shell-process-bar__close-processes"
    (click)="closeAllProcesses()"
  >
    <div
      class="rounded border border-solid flex flex-row pl-3 pr-[0.625rem] py-[0.375rem]"
      [class.text-brand]="(processes$ | async)?.length"
      [class.border-brand]="(processes$ | async)?.length"
      [class.text-[#AEB7C1]]="!(processes$ | async)?.length"
      [class.border-[#AEB7C1]]="!(processes$ | async)?.length"
    >
      <span class="mr-1">{{ (processes$ | async)?.length }}</span>
      <shared-icon icon="close"></shared-icon>
    </div>
  </button>
</div>

<ng-template #createProcessButtonContent>
  <div class="bg-brand text-white w-[2.375rem] h-[2.375rem] rounded-full grid items-center justify-center mx-auto mb-1">
    <shared-icon icon="add"></shared-icon>
  </div>
  <span class="text-brand create-process-btn-text" *ngIf="showStartProcessText$ | async">Vorgang starten</span>
</ng-template>
