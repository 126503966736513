import { NgModule } from '@angular/core';
import { UiFilterCustomInputDirective } from './custom-input.directive';
import { UiFilterInputTextModule } from './input-text';

@NgModule({
  imports: [UiFilterInputTextModule],
  declarations: [UiFilterCustomInputDirective],
  exports: [UiFilterInputTextModule, UiFilterCustomInputDirective],
})
export class UiFilterInputModule {}
