import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiSwitchComponent } from './ui-switch.component';

@NgModule({
  imports: [CommonModule],
  exports: [UiSwitchComponent],
  declarations: [UiSwitchComponent],
})
export class UiSwitchModule {}
