<div class="input-wrapper" [class.empty]="!ngControl?.value" [class.focused]="!isReadonly && (uiControl?.focused | async)">
  <!-- suffix and prefix order changed due to flex-row-reverse -->
  <span class="suffix">{{ suffix }}</span>
  <ng-content select="input, ui-select, ui-datepicker, button, ui-searchbox, textarea"></ng-content>
  <span class="prefix">{{ prefix }}</span>
  <label *ngIf="label" [for]="uiControl?.id">{{ label }}{{ requiredMark }}</label>
</div>
<span class="hint" *ngIf="showHint && ngControl?.touched && ngControl?.errors">
  {{ ngControl?.errors | uiFormControlFirstError: label }}
</span>
<span class="hint readonly-hint" *ngIf="showHint && isReadonly">Nicht Änderbar</span>
<button
  *ngIf="
    !isReadonly && clearable && ngControl?.value && !ngControl?.disabled && !(uiControl?.type === 'radio' || uiControl?.type === 'checkbox')
  "
  class="ui-form-control-clear clear"
  type="button"
  (click)="clear()"
>
  <ui-icon icon="close"></ui-icon>
</button>

<span *ngIf="!!statusLabel && !clearable" class="status">{{ statusLabel }}</span>
