import { ReturnItemDTO, ReturnSuggestionDTO } from '@swagger/remi';
import { RemissionListItem } from '../defs';

export function mapFromReturnSuggestionDTO(dto: ReturnSuggestionDTO): RemissionListItem {
  return {
    dtoType: 'suggestion',
    dto: dto,
    inStock: 0,
    remainingQuantity: dto.remainingQuantityInStock,
    remissionQuantity: dto.returnItem?.data?.predefinedReturnQuantity,
    remissionReason: dto.returnReason,
    title: dto.product.name,
    department: dto.department,
    ean: dto.product.ean,
    productGroup: dto.product.productGroup,
    format: dto.product.format,
    formatDetail: dto.product.formatDetail,
    price: dto.retailPrice,
    placementType: dto.placementType,
    assortment: dto.assortment,
  };
}

export function mapFromReturnItemDTO(dto: ReturnItemDTO): RemissionListItem {
  return {
    dtoType: 'return',
    dto: dto,
    inStock: 0,
    remissionQuantity: dto.predefinedReturnQuantity,
    remainingQuantity: dto.remainingQuantityInStock,
    remissionReason: dto.returnReason,
    title: dto.product.name,
    department: dto.department,
    ean: dto.product.ean,
    productGroup: dto.product.productGroup,
    format: dto.product.format,
    formatDetail: dto.product.formatDetail,
    price: dto.retailPrice,
    placementType: dto.placementType,
    assortment: dto.assortment,
  };
}
