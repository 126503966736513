import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormBlock } from '../form-block';

@Component({
  selector: 'app-newsletter-form-block',
  templateUrl: 'newsletter-form-block.component.html',
  styleUrls: ['newsletter-form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterFormBlockComponent extends FormBlock<boolean, UntypedFormControl> {
  get tabIndexEnd() {
    return this.tabIndexStart;
  }

  constructor() {
    super();
  }

  initializeControl(data?: boolean): void {
    this.control = new UntypedFormControl(data ?? false);
  }

  _patchValue(update: { previous: boolean; current: boolean }): void {
    this.control.patchValue(update.current);
  }
}
