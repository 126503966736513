<div class="notification-list scroll-bar">
  <ng-container *ngFor="let notification of notifications">
    <modal-notifications-list-item
      (click)="itemSelected(notification)"
      [editButtonLabel]="'Packstück-Prüfung'"
      [item]="notification"
      (itemSelected)="itemSelected($event)"
    ></modal-notifications-list-item>
    <hr />
  </ng-container>
</div>
