<div class="wrapper">
  <div class="actions">
    <button class="close-btn" (click)="ref.close()">
      <ui-icon icon="close" size="21px"></ui-icon>
    </button>
  </div>

  <h2>Diese Bestellung kann nicht noch einmal verwendet werden.</h2>
  <p>Verwenden Sie bitte ein bestehendes Konto oder legen Sie den Kunden neu an.</p>

  <div class="cta-wrapper">
    <a class="search-btn" [routerLink]="['/kunde', applicationService.activatedProcessId, 'customer', 'search']" (click)="ref.close()"
      >Zur Kundensuche</a
    >
    <a
      class="create-btn"
      *ngIf="customerCreatePath(); let nav"
      [routerLink]="nav.path"
      (click)="ref.close()"
      [queryParams]="createCustomerDataQuery(ref?.data)"
      [queryParamsHandling]="'merge'"
      >Kundendaten erfassen</a
    >
  </div>
</div>
