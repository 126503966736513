/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { IsaConfiguration as __Configuration } from '../isa-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfFeedDTO } from '../models/list-response-args-of-feed-dto';
@Injectable({
  providedIn: 'root',
})
class InfoService extends __BaseService {
  static readonly InfoInfoPath = '/isa/info';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Info / Dashboard
   * @param params The `InfoService.InfoInfoParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  InfoInfoResponse(params: InfoService.InfoInfoParams): __Observable<__StrictHttpResponse<ListResponseArgsOfFeedDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/isa/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfFeedDTO>;
      })
    );
  }
  /**
   * Info / Dashboard
   * @param params The `InfoService.InfoInfoParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  InfoInfo(params: InfoService.InfoInfoParams): __Observable<ListResponseArgsOfFeedDTO> {
    return this.InfoInfoResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfFeedDTO)
    );
  }
}

module InfoService {

  /**
   * Parameters for InfoInfo
   */
  export interface InfoInfoParams {
    take?: null | number;
    skip?: null | number;
  }
}

export { InfoService }
