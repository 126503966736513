/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CrmConfiguration as __Configuration } from '../crm-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfCountryDTO } from '../models/response-args-of-ienumerable-of-country-dto';
@Injectable({
  providedIn: 'root',
})
class CountryService extends __BaseService {
  static readonly CountryGetCountriesPath = '/customer/countries';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Länderliste
   * @param params The `CountryService.CountryGetCountriesParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`: Lokalisierung
   */
  CountryGetCountriesResponse(params: CountryService.CountryGetCountriesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfCountryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfCountryDTO>;
      })
    );
  }
  /**
   * Länderliste
   * @param params The `CountryService.CountryGetCountriesParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`: Lokalisierung
   */
  CountryGetCountries(params: CountryService.CountryGetCountriesParams): __Observable<ResponseArgsOfIEnumerableOfCountryDTO> {
    return this.CountryGetCountriesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfCountryDTO)
    );
  }
}

module CountryService {

  /**
   * Parameters for CountryGetCountries
   */
  export interface CountryGetCountriesParams {
    take?: null | number;
    skip?: null | number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }
}

export { CountryService }
