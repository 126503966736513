<div
  class="tab-wrapper flex flex-row items-center justify-between border-b-[0.188rem] border-solid h-14"
  [class.border-surface]="!(isActive$ | async)"
  [class.border-brand]="isActive$ | async"
>
  <a
    class="tab-link font-bold flex flex-row justify-center items-center whitespace-nowrap px-4 truncate max-w-[15rem] h-14"
    [routerLink]="routerLink$ | async"
    [queryParams]="queryParams$ | async"
    (click)="scrollIntoView()"
  >
    <span class="truncate">
      {{ process?.name }}
    </span>
    <ng-container *ngIf="process?.type !== 'cart-checkout'">
      <button
        type="button"
        class="rounded-full px-3 h-[2.375rem] font-bold text-p1 flex flex-row items-center justify-between shopping-cart-count ml-4"
        [class.active]="isActive$ | async"
        [routerLink]="getCheckoutPath((process$ | async)?.id)"
        (click)="$event?.preventDefault(); $event?.stopPropagation()"
      >
        <shared-icon icon="shopping-cart-bold" [size]="22"></shared-icon>
        <span class="shopping-cart-count-label ml-2">{{ cartItemCount$ | async }}</span>
      </button>
    </ng-container>
  </a>
  <button type="button" class="tab-close-btn -ml-4 h-12 w-12 grid justify-center items-center" (click)="close()">
    <shared-icon icon="close" [size]="28"></shared-icon>
  </button>
</div>
