<ng-container [formGroup]="control">
  <shared-form-control label="Anrede">
    <shared-select
      formControlName="gender"
      placeholder="Anrede"
      [readonly]="readonly"
      [tabindex]="tabIndexStart"
      [autofocus]="focusAfterInit"
    >
      <shared-select-option *ngFor="let gender of genderSettings.genders" [value]="gender.value">{{ gender.label }}</shared-select-option>
    </shared-select>
  </shared-form-control>

  <shared-form-control label="Titel">
    <shared-select formControlName="title" placeholder="Titel" [readonly]="readonly" [tabindex]="tabIndexStart + 1">
      <shared-select-option value="Dipl.-Ing.">Dipl.-Ing.</shared-select-option>
      <shared-select-option value="Dr.">Dr.</shared-select-option>
      <shared-select-option value="Dr. med.">Dr. med.</shared-select-option>
      <shared-select-option value="Prof.">Prof.</shared-select-option>
      <shared-select-option value="Prof. Dr.">Prof. Dr.</shared-select-option>
      <shared-select-option value="RA">RA</shared-select-option>
    </shared-select>
  </shared-form-control>

  <shared-form-control label="Nachname">
    <input
      class="input-control"
      placeholder="Nachname"
      type="text"
      formControlName="lastName"
      [tabindex]="tabIndexStart + 2"
      [readonly]="readonly"
    />
  </shared-form-control>

  <shared-form-control label="Vorname">
    <input
      class="input-control"
      placeholder="Vorname"
      type="text"
      formControlName="firstName"
      [tabindex]="tabIndexStart + 3"
      [readonly]="readonly"
    />
  </shared-form-control>
</ng-container>
