import { Pipe, PipeTransform } from '@angular/core';
import { ArrivalStatus } from '@swagger/wws';

@Pipe({
  name: 'arrivalStatusColorClass',
  pure: true,
})
export class ArrivalStatusColorClassPipe implements PipeTransform {
  static readonly ARRIVAL_STATUS_COLOR_CLASSES = {
    0: 'bg-accent-darkblue',
    1: 'bg-accent-green',
    2: 'bg-accent-green',
    4: 'bg-accent-orange',
    8: 'bg-accent-teal',
    16: 'bg-accent-orange',
  };

  transform(value: ArrivalStatus): string {
    return ArrivalStatusColorClassPipe.ARRIVAL_STATUS_COLOR_CLASSES[value] ?? String(value);
  }
}
