<div class="input-options-wrapper">
  <div class="hidden-overflow">
    <div class="input-options-header" [class.header-shadow]="scrollPersantage > 0">
      <button type="button" (click)="setSelected(undefined)">
        Alle entfernen
      </button>
      <button
        type="button"
        (click)="setSelected(true)"
        *ngIf="!uiInputOptions?.max && (uiInputOptions?.parent?.type === 2 || uiInputOptions?.parent?.type === 4)"
      >
        Alle auswählen
      </button>
    </div>
  </div>
  <div class="input-options-wrapper">
    <p class="input-desription">
      {{ uiInputOptions?.parent?.description }}
    </p>
    <ng-container *ngIf="uiInputOptions?.parent?.type === 2 || uiInputOptions?.parent?.type === 4">
      <div class="input-options" #inputOptionsConainter (scroll)="markForCheck()">
        <ng-container *ngIf="uiInputOptions?.parent?.type === 2">
          <ui-input-option-bool
            *ngFor="let option of uiInputOptions?.values"
            [option]="option"
            (optionChange)="optionChange($event)"
          ></ui-input-option-bool>
        </ng-container>
        <ng-container *ngIf="uiInputOptions?.parent?.type === 4">
          <ui-input-option-tri-state *ngFor="let option of uiInputOptions?.values" [option]="option"> </ui-input-option-tri-state>
        </ng-container>
      </div>
      <button class="cta-scroll" [class.up]="scrollPersantage > 20" *ngIf="scrollable" (click)="scroll(20)">
        <ui-icon icon="arrow" size="20px"></ui-icon>
      </button>
    </ng-container>
    <ui-input-option-date-range *ngIf="uiInputOptions?.parent?.type === 128" [options]="uiInputOptions?.values">
    </ui-input-option-date-range>
    <ui-input-option-number-range *ngIf="uiInputOptions?.parent?.type === 4096" [options]="uiInputOptions?.values">
    </ui-input-option-number-range>
  </div>
</div>
