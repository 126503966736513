import { Injectable } from '@angular/core';
import { IconLoader } from './icon-loader';
import { IconConfig } from '../interfaces';

@Injectable()
export class EmptyIconLoader extends IconLoader {
  constructor() {
    super();
  }

  getIcons(): IconConfig {
    return {
      icons: [],
      viewBox: '',
    };
  }
}
