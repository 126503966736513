export function hash(value: any): string {
  const jsonStr = JSON.stringify(value);
  let hash = 0;

  if (jsonStr.length == 0) return hash.toString(16);

  for (let i = 0; i < jsonStr.length; i++) {
    const char = jsonStr.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash.toString(16);
}
