import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormBlockControl } from '../form-block';

@Component({
  selector: 'app-accept-agb-form-block',
  templateUrl: 'accept-agb-form-block.component.html',
  styleUrls: ['accept-agb-form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appAcceptAGBFormBlock',
})
export class AcceptAGBFormBlockComponent extends FormBlockControl<boolean> {
  get tabIndexEnd(): number {
    return this.tabIndexStart;
  }

  constructor() {
    super();
  }

  initializeControl(data?: boolean): void {
    this.control = new UntypedFormControl(data ?? false, this.getValidatorFn(), this.getAsyncValidatorFn());
  }

  _patchValue(update: { previous: boolean; current: boolean }): void {
    this.control.patchValue(update.current);
  }

  updateValidators(): void {
    this.control.setValidators(this.getValidatorFn());
  }

  setValue(value: boolean): void {
    this.control.setValue(value);
  }
}
