import { IUiFilter } from '../tree';

export const querySettingsData: IUiFilter = {
  input: [{ group: 'main', input: [{ key: 'qs', placeholder: 'ISBN/EAN, Titel, Kundenname', type: 1, constraint: '^.{2,100}$' }] }],
  filter: [
    {
      group: 'main',
      input: [
        { key: 'all_branches', label: 'Alle Filialen', type: 2, target: 'filter' },
        { key: 'customer_name', label: 'Kundenname', type: 2, target: 'input' },
        { key: 'product_contributor', label: 'Autor', type: 2, target: 'input' },
        { key: 'product_name', label: 'Titel', type: 2, target: 'input' },
      ],
    },
    {
      group: 'filter',
      input: [
        {
          key: 'orderitemprocessingstatus',
          label: 'Status',
          type: 4,
          target: 'filter',
          options: {
            values: [
              { label: 'abgeholt', value: '256' },
              { label: 'angefragt', value: '524288' },
              { label: 'ans Lager (nicht abgeholt)', value: '262144' },
              { label: 'bestellt', value: '16', selected: true },
              { label: 'derzeit nicht lieferbar', value: '16777216' },
              { label: 'eingetroffen', value: '128' },
              { label: 'Lieferant wird ermittelt', value: '8388608' },
              { label: 'nachbestellt', value: '8192', selected: true },
              { label: 'neu', value: '1' },
              { label: 'nicht lieferbar', value: '4096' },
              { label: 'storniert', value: '1024', selected: true },
              { label: 'storniert (Kunde)', value: '512', selected: true },
              { label: 'storniert (Lieferant)', value: '2048', selected: true },
              { label: 'versendet', value: '64' },
              { label: 'weitergeleitet intern', value: '1048576' },
              { label: 'zugestellt', value: '4194304' },
            ],
          },
        },
        {
          key: 'estimatedshippingdate',
          label: 'vsl. Lieferdatum',
          description: 'Geben Sie das vsl. Lieferdatum ein, um die Suche zu verfeinern.',
          type: 128,
          target: 'filter',
          options: {
            values: [
              { key: 'start', label: 'von' },
              { key: 'stop', label: 'bis' },
            ],
          },
        },
        {
          key: 'supplier',
          label: 'Lieferant',
          type: 2,
          target: 'filter',
          options: {
            values: [
              { label: 'Dummy', value: 'D' },
              { label: 'Filiale', value: 'F' },
              { label: 'H', value: 'H' },
              { label: 'Hugendubel Digital', value: 'DIG' },
              { label: 'KNV', value: 'K' },
              { label: 'KNV-Ausland', value: 'KA' },
              { label: 'KNV-Import', value: 'G' },
              { label: 'KNV-Import (Baker & Taylor)', value: 'T' },
              { label: 'Libri', value: 'L' },
              { label: 'Libri Verlag', value: 'LV1' },
              { label: 'Libri-Import', value: 'I' },
              { label: 'Libri-Import (Bertrams)', value: 'B' },
              { label: 'Petersen', value: 'P' },
              { label: 'Verlag', value: 'LV3' },
              { label: 'Weltbild', value: 'W' },
              { label: 'ZL', value: 'Z' },
            ],
          },
        },
        {
          key: 'channel',
          label: 'Bestellkanal',
          type: 2,
          target: 'filter',
          options: {
            values: [
              { label: 'hugendubel.de', value: '8;16' },
              { label: 'Filiale', value: '2' },
              { label: 'HSC', value: '4' },
            ],
          },
        },
      ],
    },
  ],
  orderBy: [
    {
      by: 'by1',
      desc: true,
      label: 'label1',
    },
    {
      by: 'by2',
      desc: false,
      label: 'label2',
    },
  ],
};
