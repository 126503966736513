<div class="container" *ngIf="value">
  <button class="filters-clear" *ngIf="compareFilter()" (click)="resetFilter()">
    Filter zurücksetzen
  </button>

  <div
    class="filter-option"
    *ngFor="let option of selectedOptions; let optionIndex = index"
    [class.collapsed]="collapsed && optionIndex >= collapseAtIndex"
  >
    <span class="option-name">{{ option.name }}</span>
    <button class="option-clear" (click)="unselectOption(option)">
      <ui-icon icon="close" size="15px"></ui-icon>
    </button>
  </div>

  <button class="display-more" (click)="collapsed = false" *ngIf="collapsed && selectedOptions?.length > collapseAtIndex">
    Mehr
    <ui-icon size="15px" icon="arrow"></ui-icon>
  </button>

  <button class="display-less" (click)="collapsed = true" *ngIf="!collapsed && selectedOptions?.length > collapseAtIndex">
    <ui-icon size="15px" icon="arrow" rotate="-180deg"></ui-icon>
    Weniger
  </button>
</div>
