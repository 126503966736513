export interface IconConfig {
  fallback?: string;
  icons?: Icon[];
  aliases?: IconAlias[];
  viewBox: string;
}

export interface Icon {
  name: string;
  data: string;
  viewBox?: string;
}

export interface IconAlias {
  alias: string;
  name: string;
}
