<h2 class="text-center font-bold text-xl mt-10">Sie haben {{ packageCount }} Irrläufer</h2>
<p class="text-center text-xl mt-4">
  Stellen Sie diese Packstücke für die anderen Filialen bereit. <br />
  Der Spediteur holt Sie zum nächstmöglichsten Zeitpunkt ab.
</p>
<page-package-list [packages]="packages" [showHeader]="false" navigationEnabled="false"></page-package-list>
<div class="text-center my-7">
  <button class="isa-cta-button" (click)="close()">Später</button>
  <button class="isa-cta-button isa-button-primary ml-6" (click)="done()">Erledigt</button>
</div>
