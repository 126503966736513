import { Component, ChangeDetectionStrategy, ViewChild, OnInit } from '@angular/core';
import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { Result } from '@domain/defs';
import { CustomerDTO, CustomerInfoDTO, KeyValueDTOOfStringAndString } from '@swagger/crm';
import { UiErrorModalComponent, UiModalResult } from '@ui/modal';
import { NEVER, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AddressFormBlockComponent, AddressFormBlockData, DeviatingAddressFormBlockComponent } from '../../components/form-blocks';
import { NameFormBlockData } from '../../components/form-blocks/name/name-form-block-data';
import { WebshopCustomnerAlreadyExistsModalComponent, WebshopCustomnerAlreadyExistsModalData } from '../../modals';
import { validateEmail } from '../../validators/email-validator';
import { AbstractCreateCustomer } from '../abstract-create-customer';
import { encodeFormData, mapCustomerDtoToCustomerCreateFormData } from '../customer-create-form-data';

@Component({
  selector: 'app-create-p4m-customer',
  templateUrl: 'create-p4m-customer.component.html',
  styleUrls: ['../create-customer.scss', 'create-p4m-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateP4MCustomerComponent extends AbstractCreateCustomer implements OnInit {
  validateAddress = true;

  validateShippingAddress = true;

  get _customerType() {
    return this.activatedRoute.snapshot.data.customerType;
  }

  get customerType() {
    return `${this._customerType}-p4m`;
  }

  nameRequiredMarks: (keyof NameFormBlockData)[] = ['gender', 'firstName', 'lastName'];

  nameValidationFns: Record<keyof NameFormBlockData, ValidatorFn[]> = {
    firstName: [Validators.required],
    lastName: [Validators.required],
    gender: [Validators.required],
    title: [],
  };

  emailRequiredMark: boolean;

  emailValidatorFn: ValidatorFn[];

  asyncEmailVlaidtorFn: AsyncValidatorFn[];

  asyncLoyaltyCardValidatorFn: AsyncValidatorFn[];

  shippingAddressRequiredMarks: (keyof AddressFormBlockData)[] = ['street', 'streetNumber', 'zipCode', 'city', 'country'];

  shippingAddressValidators: Record<string, ValidatorFn[]> = {
    street: [Validators.required],
    streetNumber: [Validators.required],
    zipCode: [Validators.required],
    city: [Validators.required],
    country: [Validators.required],
  };

  addressRequiredMarks: (keyof AddressFormBlockData)[];

  addressValidatorFns: Record<string, ValidatorFn[]>;

  @ViewChild(AddressFormBlockComponent, { static: false })
  addressFormBlock: AddressFormBlockComponent;

  @ViewChild(DeviatingAddressFormBlockComponent, { static: false })
  deviatingDeliveryAddressFormBlock: DeviatingAddressFormBlockComponent;

  agbValidatorFns = [Validators.requiredTrue];

  birthDateValidatorFns = [];

  existingCustomer$: Observable<CustomerInfoDTO | CustomerDTO | null>;

  ngOnInit(): void {
    super.ngOnInit();
    this.initMarksAndValidators();
    this.existingCustomer$ = this.customerExists$.pipe(
      distinctUntilChanged(),
      switchMap((exists) => {
        if (exists) {
          return this.fetchCustomerInfo();
        }
        return of(null);
      }),
    );

    this.existingCustomer$
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((info) => {
          if (info) {
            return this.customerService.getCustomer(info.id, 2).pipe(
              map((res) => res.result),
              catchError((err) => NEVER),
            );
          }
          return NEVER;
        }),
        withLatestFrom(this.processId$),
      )
      .subscribe(([customer, processId]) => {
        if (customer) {
          this.modal
            .open({
              content: WebshopCustomnerAlreadyExistsModalComponent,
              data: {
                customer,
                processId,
              } as WebshopCustomnerAlreadyExistsModalData,
              title: 'Es existiert bereits ein Onlinekonto mit dieser E-Mail-Adresse',
            })
            .afterClosed$.subscribe(async (result: UiModalResult<boolean>) => {
              if (result.data) {
                this.navigateToUpdatePage(customer);
              } else {
                this.formData.email = '';
                this.cdr.markForCheck();
              }
            });
        }
      });
  }

  async navigateToUpdatePage(customer: CustomerDTO) {
    const processId = await this.processId$.pipe(first()).toPromise();
    this.router.navigate(['/kunde', processId, 'customer', 'create', 'webshop-p4m', 'update'], {
      queryParams: {
        formData: encodeFormData({
          ...mapCustomerDtoToCustomerCreateFormData(customer),
          p4m: this.formData.p4m,
        }),
      },
    });
  }

  initMarksAndValidators() {
    this.asyncLoyaltyCardValidatorFn = [this.checkLoyalityCardValidator];
    this.birthDateValidatorFns = [Validators.required, this.minBirthDateValidator()];
    if (this._customerType === 'webshop') {
      this.emailRequiredMark = true;
      this.emailValidatorFn = [Validators.required, Validators.email, validateEmail];
      this.asyncEmailVlaidtorFn = [this.emailExistsValidator];
      this.addressRequiredMarks = this.shippingAddressRequiredMarks;
      this.addressValidatorFns = this.shippingAddressValidators;
    } else {
      this.emailRequiredMark = false;
      this.emailValidatorFn = [Validators.email, validateEmail];
    }
  }

  fetchCustomerInfo(): Observable<CustomerDTO | null> {
    const email = this.formData.email;
    return this.customerService.getOnlineCustomerByEmail(email).pipe(
      map((result) => {
        if (result) {
          return result;
        }
        return null;
      }),
      catchError((err) => {
        this.modal.open({
          content: UiErrorModalComponent,
          data: err,
        });
        return [null];
      }),
    );
  }

  getInterests(): KeyValueDTOOfStringAndString[] {
    const interests: KeyValueDTOOfStringAndString[] = [];

    for (const key in this.formData.interests) {
      if (this.formData.interests[key]) {
        interests.push({ key, group: 'KUBI_INTERESSEN' });
      }
    }

    return interests;
  }

  getNewsletter(): KeyValueDTOOfStringAndString | undefined {
    if (this.formData.newsletter) {
      return { key: 'kubi_newsletter', group: 'KUBI_NEWSLETTER' };
    }
  }

  static MapCustomerInfoDtoToCustomerDto(customerInfoDto: CustomerInfoDTO): CustomerDTO {
    return {
      address: customerInfoDto.address,
      agentComment: customerInfoDto.agentComment,
      bonusCard: customerInfoDto.bonusCard,
      campaignCode: customerInfoDto.campaignCode,
      communicationDetails: customerInfoDto.communicationDetails,
      createdInBranch: customerInfoDto.createdInBranch,
      customerGroup: customerInfoDto.customerGroup,
      customerNumber: customerInfoDto.customerNumber,
      customerStatus: customerInfoDto.customerStatus,
      customerType: customerInfoDto.customerType,
      dateOfBirth: customerInfoDto.dateOfBirth,
      features: customerInfoDto.features,
      firstName: customerInfoDto.firstName,
      lastName: customerInfoDto.lastName,
      gender: customerInfoDto.gender,
      hasOnlineAccount: customerInfoDto.hasOnlineAccount,
      isGuestAccount: customerInfoDto.isGuestAccount,
      label: customerInfoDto.label,
      notificationChannels: customerInfoDto.notificationChannels,
      organisation: customerInfoDto.organisation,
      title: customerInfoDto.title,
      id: customerInfoDto.id,
      pId: customerInfoDto.pId,
    };
  }

  async saveCustomer(customer: CustomerDTO): Promise<CustomerDTO> {
    const isWebshop = this._customerType === 'webshop';
    let res: Result<CustomerDTO>;

    const { customerDto, customerInfoDto } = this.formData?._meta ?? {};

    if (customerDto) {
      customer = { ...customerDto, ...customer };
    } else if (customerInfoDto) {
      customer = { ...CreateP4MCustomerComponent.MapCustomerInfoDtoToCustomerDto(customerInfoDto), ...customer };
    }

    const p4mFeature = customer.features?.find((attr) => attr.key === 'p4mUser');
    if (p4mFeature) {
      p4mFeature.value = this.formData.p4m;
    } else {
      customer.features.push({
        key: 'p4mUser',
        value: this.formData.p4m,
      });
    }

    const interests = this.getInterests();

    if (interests.length > 0) {
      customer.features?.push(...interests);
      // TODO: Klärung wie Interessen zukünftig gespeichert werden
      // await this._loyaltyCardService
      //   .LoyaltyCardSaveInteressen({
      //     customerId: res.result.id,
      //     interessen: this.getInterests(),
      //   })
      //   .toPromise();
    }

    const newsletter = this.getNewsletter();

    if (newsletter) {
      customer.features.push(newsletter);
    } else {
      customer.features = customer.features.filter((feature) => feature.key !== 'kubi_newsletter' && feature.group !== 'KUBI_NEWSLETTER');
    }

    if (isWebshop) {
      if (customer.id > 0) {
        if (this.formData?._meta?.hasLocalityCard) {
          res = await this.customerService.updateStoreP4MToWebshopP4M(customer);
        } else {
          res = await this.customerService.updateToP4MOnlineCustomer(customer);
        }
      } else {
        res = await this.customerService.createOnlineCustomer(customer).toPromise();
      }
    } else {
      res = await this.customerService.createStoreCustomer(customer).toPromise();
    }

    return res.result;
  }
}
