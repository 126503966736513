import { Directive, HostListener, Input } from '@angular/core';
import { ProductCatalogNavigationService } from '@shared/services';

@Directive({
  selector: '[productImageNavigation]',
  standalone: true,
})
export class NavigateOnClickDirective {
  @Input('productImageNavigation') ean: string;

  constructor(private readonly _productCatalogNavigation: ProductCatalogNavigationService) {}

  @HostListener('click', ['$event'])
  async onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.ean) {
      await this._navigateToProductSearchDetails();
    }
  }

  private async _navigateToProductSearchDetails() {
    await this._productCatalogNavigation
      .getArticleDetailsPathByEan({
        processId: Date.now(),
        ean: this.ean,
        extras: { queryParams: { main_qs: this.ean } },
      })
      .navigate();
  }
}
