import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';
import { UiModalModule } from '@ui/modal';
import { UiSelectModule } from '@ui/select';
import { UiSpinnerModule } from 'apps/ui/spinner/src/lib/ui-spinner.module';
import { PrintModalComponent } from './modal-printer.component';

@NgModule({
  declarations: [PrintModalComponent],
  imports: [CommonModule, FormsModule, UiModalModule, UiIconModule, UiSelectModule, UiSpinnerModule],
  exports: [PrintModalComponent],
})
export class ModalPrinterModule {}
