/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PrintConfiguration, PrintConfigurationInterface } from './print-configuration';

import { CatalogPrintService } from './services/catalog-print.service';
import { CheckoutPrintService } from './services/checkout-print.service';
import { InventoryPrintService } from './services/inventory-print.service';
import { LoyaltyCardPrintService } from './services/loyalty-card-print.service';
import { OMSPrintService } from './services/omsprint.service';
import { PrintService } from './services/print.service';

/**
 * Provider for all Print services, plus PrintConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    PrintConfiguration,
    CatalogPrintService,
    CheckoutPrintService,
    InventoryPrintService,
    LoyaltyCardPrintService,
    OMSPrintService,
    PrintService
  ],
})
export class PrintModule {
  static forRoot(customParams: PrintConfigurationInterface): ModuleWithProviders<PrintModule> {
    return {
      ngModule: PrintModule,
      providers: [
        {
          provide: PrintConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
