/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CatConfiguration as __Configuration } from '../cat-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIDictionaryOfLongAndNullableInteger } from '../models/response-args-of-idictionary-of-long-and-nullable-integer';
import { LesepunkteRequest } from '../models/lesepunkte-request';
@Injectable({
  providedIn: 'root',
})
class PromotionService extends __BaseService {
  static readonly PromotionLesepunktePath = '/promotion/lesepunkte';
  static readonly PromotionLesepunkte2Path = '/stock/{stockId}/promotion/lesepunkte';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lesepunkte
   * @param params The `PromotionService.PromotionLesepunkteParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `items`: Ids und Mengen
   */
  PromotionLesepunkteResponse(params: PromotionService.PromotionLesepunkteParams): __Observable<__StrictHttpResponse<ResponseArgsOfIDictionaryOfLongAndNullableInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.items;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/promotion/lesepunkte`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIDictionaryOfLongAndNullableInteger>;
      })
    );
  }
  /**
   * Lesepunkte
   * @param params The `PromotionService.PromotionLesepunkteParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `items`: Ids und Mengen
   */
  PromotionLesepunkte(params: PromotionService.PromotionLesepunkteParams): __Observable<ResponseArgsOfIDictionaryOfLongAndNullableInteger> {
    return this.PromotionLesepunkteResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIDictionaryOfLongAndNullableInteger)
    );
  }

  /**
   * Lesepunkte
   * @param params The `PromotionService.PromotionLesepunkte2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `items`: Ids und Mengen
   */
  PromotionLesepunkte2Response(params: PromotionService.PromotionLesepunkte2Params): __Observable<__StrictHttpResponse<ResponseArgsOfIDictionaryOfLongAndNullableInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.items;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stock/${encodeURIComponent(String(params.stockId))}/promotion/lesepunkte`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIDictionaryOfLongAndNullableInteger>;
      })
    );
  }
  /**
   * Lesepunkte
   * @param params The `PromotionService.PromotionLesepunkte2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `items`: Ids und Mengen
   */
  PromotionLesepunkte2(params: PromotionService.PromotionLesepunkte2Params): __Observable<ResponseArgsOfIDictionaryOfLongAndNullableInteger> {
    return this.PromotionLesepunkte2Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIDictionaryOfLongAndNullableInteger)
    );
  }
}

module PromotionService {

  /**
   * Parameters for PromotionLesepunkte
   */
  export interface PromotionLesepunkteParams {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * Ids und Mengen
     */
    items: Array<LesepunkteRequest>;
  }

  /**
   * Parameters for PromotionLesepunkte2
   */
  export interface PromotionLesepunkte2Params {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * Ids und Mengen
     */
    items: Array<LesepunkteRequest>;
  }
}

export { PromotionService }
