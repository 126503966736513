/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CrmConfiguration as __Configuration } from '../crm-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfInputDTO } from '../models/response-args-of-input-dto';
import { ResponseArgsOfQuerySettingsDTO } from '../models/response-args-of-query-settings-dto';
import { ResponseArgsOfIEnumerableOfAutocompleteDTO } from '../models/response-args-of-ienumerable-of-autocomplete-dto';
import { AutocompleteTokenDTO } from '../models/autocomplete-token-dto';
import { ListResponseArgsOfCustomerInfoDTO } from '../models/list-response-args-of-customer-info-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfCustomerDTO } from '../models/response-args-of-customer-dto';
import { SaveCustomerValues } from '../models/save-customer-values';
import { CustomerDTO } from '../models/customer-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
import { ResponseArgsOfAssignedPayerDTO } from '../models/response-args-of-assigned-payer-dto';
import { ResponseArgsOfIEnumerableOfCustomerInfoDTO } from '../models/response-args-of-ienumerable-of-customer-info-dto';
import { ResponseArgsOfIEnumerableOfBonusCardInfoDTO } from '../models/response-args-of-ienumerable-of-bonus-card-info-dto';
import { ResponseArgsOfNullableBoolean } from '../models/response-args-of-nullable-boolean';
import { ListResponseArgsOfAssignedPayerDTO } from '../models/list-response-args-of-assigned-payer-dto';
import { ResponseArgsOfIEnumerableOfHistoryDTO } from '../models/response-args-of-ienumerable-of-history-dto';
@Injectable({
  providedIn: 'root',
})
class CustomerService extends __BaseService {
  static readonly CustomerCanExtendCustomerPath = '/customer/{customerId}/canextend';
  static readonly CustomerCustomerQuerySettingsPath = '/customer/s/settings';
  static readonly CustomerCustomerAutocompletePath = '/customer/s/complete';
  static readonly CustomerListCustomersPath = '/customer/s';
  static readonly CustomerGetCustomerPath = '/customer/{customerId}';
  static readonly CustomerUpdateCustomerPath = '/customer/{customerId}';
  static readonly CustomerPatchCustomerPath = '/customer/{customerId}';
  static readonly CustomerDeleteCustomerPath = '/customer/{customerId}';
  static readonly CustomerCreateCustomerPath = '/customer';
  static readonly CustomerAddPayerReferencePath = '/customer/{customerId}/payer';
  static readonly CustomerDeactivateCustomerPath = '/customer/{customerId}/deactivate';
  static readonly CustomerGetCustomerByBonuscardPath = '/customer/bonuscard/scan';
  static readonly CustomerGetBonuscardsPath = '/customer/{customerId}/bonuscard';
  static readonly CustomerEmailExistsPath = '/store/customer/emailexists';
  static readonly CustomerGetAssignedPayersByCustomerIdPath = '/customer/{customerId}/assignedpayers';
  static readonly CustomerModifyPayerReferencePath = '/customer/{customerId}/payer/{payerId}/modifydefaultflag';
  static readonly CustomerGetCustomerHistoryPath = '/customer/{customerId}/history';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Kann der übergebene Kunde erweitert werden (z.B. Onlinekonto, ...)
   * @param params The `CustomerService.CustomerCanExtendCustomerParams` containing the following parameters:
   *
   * - `customerId`: Kunde PK
   *
   * - `locale`: Lokalisierung
   */
  CustomerCanExtendCustomerResponse(params: CustomerService.CustomerCanExtendCustomerParams): __Observable<__StrictHttpResponse<ResponseArgsOfInputDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}/canextend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInputDTO>;
      })
    );
  }
  /**
   * Kann der übergebene Kunde erweitert werden (z.B. Onlinekonto, ...)
   * @param params The `CustomerService.CustomerCanExtendCustomerParams` containing the following parameters:
   *
   * - `customerId`: Kunde PK
   *
   * - `locale`: Lokalisierung
   */
  CustomerCanExtendCustomer(params: CustomerService.CustomerCanExtendCustomerParams): __Observable<ResponseArgsOfInputDTO> {
    return this.CustomerCanExtendCustomerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfInputDTO)
    );
  }

  /**
   * Wareneingang-Filter
   */
  CustomerCustomerQuerySettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/s/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      })
    );
  }
  /**
   * Wareneingang-Filter
   */
  CustomerCustomerQuerySettings(): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.CustomerCustomerQuerySettingsResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfQuerySettingsDTO)
    );
  }

  /**
   * @param payload undefined
   */
  CustomerCustomerAutocompleteResponse(payload: AutocompleteTokenDTO): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/customer/s/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  CustomerCustomerAutocomplete(payload: AutocompleteTokenDTO): __Observable<ResponseArgsOfIEnumerableOfAutocompleteDTO> {
    return this.CustomerCustomerAutocompleteResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfAutocompleteDTO)
    );
  }

  /**
   * Kundensuche
   * @param queryToken Suchkriterien
   */
  CustomerListCustomersResponse(queryToken: QueryTokenDTO): __Observable<__StrictHttpResponse<ListResponseArgsOfCustomerInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = queryToken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/customer/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfCustomerInfoDTO>;
      })
    );
  }
  /**
   * Kundensuche
   * @param queryToken Suchkriterien
   */
  CustomerListCustomers(queryToken: QueryTokenDTO): __Observable<ListResponseArgsOfCustomerInfoDTO> {
    return this.CustomerListCustomersResponse(queryToken).pipe(
      __map(_r => _r.body as ListResponseArgsOfCustomerInfoDTO)
    );
  }

  /**
   * Kunde
   * @param params The `CustomerService.CustomerGetCustomerParams` containing the following parameters:
   *
   * - `customerId`: Kunde PK
   *
   * - `eagerLoading`: eagerLoading
   */
  CustomerGetCustomerResponse(params: CustomerService.CustomerGetCustomerParams): __Observable<__StrictHttpResponse<ResponseArgsOfCustomerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCustomerDTO>;
      })
    );
  }
  /**
   * Kunde
   * @param params The `CustomerService.CustomerGetCustomerParams` containing the following parameters:
   *
   * - `customerId`: Kunde PK
   *
   * - `eagerLoading`: eagerLoading
   */
  CustomerGetCustomer(params: CustomerService.CustomerGetCustomerParams): __Observable<ResponseArgsOfCustomerDTO> {
    return this.CustomerGetCustomerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfCustomerDTO)
    );
  }

  /**
   * Änderung eines bestehenden Kunden
   * @param params The `CustomerService.CustomerUpdateCustomerParams` containing the following parameters:
   *
   * - `payload`: Kundendaten
   *
   * - `customerId`: Kunde PK
   */
  CustomerUpdateCustomerResponse(params: CustomerService.CustomerUpdateCustomerParams): __Observable<__StrictHttpResponse<ResponseArgsOfCustomerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCustomerDTO>;
      })
    );
  }
  /**
   * Änderung eines bestehenden Kunden
   * @param params The `CustomerService.CustomerUpdateCustomerParams` containing the following parameters:
   *
   * - `payload`: Kundendaten
   *
   * - `customerId`: Kunde PK
   */
  CustomerUpdateCustomer(params: CustomerService.CustomerUpdateCustomerParams): __Observable<ResponseArgsOfCustomerDTO> {
    return this.CustomerUpdateCustomerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfCustomerDTO)
    );
  }

  /**
   * Änderung eines bestehenden Kunden
   * @param params The `CustomerService.CustomerPatchCustomerParams` containing the following parameters:
   *
   * - `customerId`: Kunde PK
   *
   * - `customer`: Kundendaten
   *
   * - `eagerLoading`:
   */
  CustomerPatchCustomerResponse(params: CustomerService.CustomerPatchCustomerParams): __Observable<__StrictHttpResponse<ResponseArgsOfCustomerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.customer;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCustomerDTO>;
      })
    );
  }
  /**
   * Änderung eines bestehenden Kunden
   * @param params The `CustomerService.CustomerPatchCustomerParams` containing the following parameters:
   *
   * - `customerId`: Kunde PK
   *
   * - `customer`: Kundendaten
   *
   * - `eagerLoading`:
   */
  CustomerPatchCustomer(params: CustomerService.CustomerPatchCustomerParams): __Observable<ResponseArgsOfCustomerDTO> {
    return this.CustomerPatchCustomerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfCustomerDTO)
    );
  }

  /**
   * Löschen eines bestehenden Kunden
   *
   * Setzt den Datensatz auf gelöscht und anonymisiert benutzerbezogene Daten. Außerdem wird die Kundennummer CustomerNumber verändert.
   * @param params The `CustomerService.CustomerDeleteCustomerParams` containing the following parameters:
   *
   * - `customerId`: PK Kunden
   *
   * - `deletionComment`: Grund des Löschens
   */
  CustomerDeleteCustomerResponse(params: CustomerService.CustomerDeleteCustomerParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.deletionComment != null) __params = __params.set('deletionComment', params.deletionComment.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Löschen eines bestehenden Kunden
   *
   * Setzt den Datensatz auf gelöscht und anonymisiert benutzerbezogene Daten. Außerdem wird die Kundennummer CustomerNumber verändert.
   * @param params The `CustomerService.CustomerDeleteCustomerParams` containing the following parameters:
   *
   * - `customerId`: PK Kunden
   *
   * - `deletionComment`: Grund des Löschens
   */
  CustomerDeleteCustomer(params: CustomerService.CustomerDeleteCustomerParams): __Observable<ResponseArgsOfBoolean> {
    return this.CustomerDeleteCustomerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }

  /**
   * Anlage eines neuen Kunden
   * @param payload Kundendaten
   */
  CustomerCreateCustomerResponse(payload: SaveCustomerValues): __Observable<__StrictHttpResponse<ResponseArgsOfCustomerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/customer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCustomerDTO>;
      })
    );
  }
  /**
   * Anlage eines neuen Kunden
   * @param payload Kundendaten
   */
  CustomerCreateCustomer(payload: SaveCustomerValues): __Observable<ResponseArgsOfCustomerDTO> {
    return this.CustomerCreateCustomerResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfCustomerDTO)
    );
  }

  /**
   * Hinzufügen einer Regulierer-/Rechnungsadress-Referenz
   * @param params The `CustomerService.CustomerAddPayerReferenceParams` containing the following parameters:
   *
   * - `customerId`: Kunde PK
   *
   * - `payerId`: Regulierer/Rechnungsadresse PK
   *
   * - `isDefault`: Ist Standard Regulierer/Rechnungsaddresse
   *
   * - `eagerLoading`: eagerLoading (optional, default: 1)
   */
  CustomerAddPayerReferenceResponse(params: CustomerService.CustomerAddPayerReferenceParams): __Observable<__StrictHttpResponse<ResponseArgsOfAssignedPayerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.payerId != null) __params = __params.set('payerId', params.payerId.toString());
    if (params.isDefault != null) __params = __params.set('isDefault', params.isDefault.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}/payer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfAssignedPayerDTO>;
      })
    );
  }
  /**
   * Hinzufügen einer Regulierer-/Rechnungsadress-Referenz
   * @param params The `CustomerService.CustomerAddPayerReferenceParams` containing the following parameters:
   *
   * - `customerId`: Kunde PK
   *
   * - `payerId`: Regulierer/Rechnungsadresse PK
   *
   * - `isDefault`: Ist Standard Regulierer/Rechnungsaddresse
   *
   * - `eagerLoading`: eagerLoading (optional, default: 1)
   */
  CustomerAddPayerReference(params: CustomerService.CustomerAddPayerReferenceParams): __Observable<ResponseArgsOfAssignedPayerDTO> {
    return this.CustomerAddPayerReferenceResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfAssignedPayerDTO)
    );
  }

  /**
   * Deaktivieren eines bestehenden Kunden
   * @param params The `CustomerService.CustomerDeactivateCustomerParams` containing the following parameters:
   *
   * - `customerId`: Kunden PK
   *
   * - `deactivationComment`: Deaktivierungsgrund
   */
  CustomerDeactivateCustomerResponse(params: CustomerService.CustomerDeactivateCustomerParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.deactivationComment != null) __params = __params.set('deactivationComment', params.deactivationComment.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}/deactivate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Deaktivieren eines bestehenden Kunden
   * @param params The `CustomerService.CustomerDeactivateCustomerParams` containing the following parameters:
   *
   * - `customerId`: Kunden PK
   *
   * - `deactivationComment`: Deaktivierungsgrund
   */
  CustomerDeactivateCustomer(params: CustomerService.CustomerDeactivateCustomerParams): __Observable<ResponseArgsOfBoolean> {
    return this.CustomerDeactivateCustomerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }

  /**
   * Kundenkartenscan
   * @param input Scanner input
   */
  CustomerGetCustomerByBonuscardResponse(input?: null | string): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfCustomerInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (input != null) __params = __params.set('input', input.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/bonuscard/scan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfCustomerInfoDTO>;
      })
    );
  }
  /**
   * Kundenkartenscan
   * @param input Scanner input
   */
  CustomerGetCustomerByBonuscard(input?: null | string): __Observable<ResponseArgsOfIEnumerableOfCustomerInfoDTO> {
    return this.CustomerGetCustomerByBonuscardResponse(input).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfCustomerInfoDTO)
    );
  }

  /**
   * Kundenkarte
   * @param customerId PK Kunde
   */
  CustomerGetBonuscardsResponse(customerId: number): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfBonusCardInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/${encodeURIComponent(String(customerId))}/bonuscard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfBonusCardInfoDTO>;
      })
    );
  }
  /**
   * Kundenkarte
   * @param customerId PK Kunde
   */
  CustomerGetBonuscards(customerId: number): __Observable<ResponseArgsOfIEnumerableOfBonusCardInfoDTO> {
    return this.CustomerGetBonuscardsResponse(customerId).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfBonusCardInfoDTO)
    );
  }

  /**
   * Überprüft, ob für die übergebene E-Mail Adresse bereits ein Onlinekonto vorhanden ist
   * @param email E-Mail Adresse
   */
  CustomerEmailExistsResponse(email?: null | string): __Observable<__StrictHttpResponse<ResponseArgsOfNullableBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/store/customer/emailexists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfNullableBoolean>;
      })
    );
  }
  /**
   * Überprüft, ob für die übergebene E-Mail Adresse bereits ein Onlinekonto vorhanden ist
   * @param email E-Mail Adresse
   */
  CustomerEmailExists(email?: null | string): __Observable<ResponseArgsOfNullableBoolean> {
    return this.CustomerEmailExistsResponse(email).pipe(
      __map(_r => _r.body as ResponseArgsOfNullableBoolean)
    );
  }

  /**
   * @param params The `CustomerService.CustomerGetAssignedPayersByCustomerIdParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `take`:
   *
   * - `skip`:
   */
  CustomerGetAssignedPayersByCustomerIdResponse(params: CustomerService.CustomerGetAssignedPayersByCustomerIdParams): __Observable<__StrictHttpResponse<ListResponseArgsOfAssignedPayerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}/assignedpayers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfAssignedPayerDTO>;
      })
    );
  }
  /**
   * @param params The `CustomerService.CustomerGetAssignedPayersByCustomerIdParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `take`:
   *
   * - `skip`:
   */
  CustomerGetAssignedPayersByCustomerId(params: CustomerService.CustomerGetAssignedPayersByCustomerIdParams): __Observable<ListResponseArgsOfAssignedPayerDTO> {
    return this.CustomerGetAssignedPayersByCustomerIdResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfAssignedPayerDTO)
    );
  }

  /**
   * @param params The `CustomerService.CustomerModifyPayerReferenceParams` containing the following parameters:
   *
   * - `payerId`:
   *
   * - `customerId`:
   *
   * - `isDefault`:
   *
   * - `eagerLoading`:
   */
  CustomerModifyPayerReferenceResponse(params: CustomerService.CustomerModifyPayerReferenceParams): __Observable<__StrictHttpResponse<ResponseArgsOfAssignedPayerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.isDefault != null) __params = __params.set('isDefault', params.isDefault.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}/payer/${encodeURIComponent(String(params.payerId))}/modifydefaultflag`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfAssignedPayerDTO>;
      })
    );
  }
  /**
   * @param params The `CustomerService.CustomerModifyPayerReferenceParams` containing the following parameters:
   *
   * - `payerId`:
   *
   * - `customerId`:
   *
   * - `isDefault`:
   *
   * - `eagerLoading`:
   */
  CustomerModifyPayerReference(params: CustomerService.CustomerModifyPayerReferenceParams): __Observable<ResponseArgsOfAssignedPayerDTO> {
    return this.CustomerModifyPayerReferenceResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfAssignedPayerDTO)
    );
  }

  /**
   * @param params The `CustomerService.CustomerGetCustomerHistoryParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `locale`:
   */
  CustomerGetCustomerHistoryResponse(params: CustomerService.CustomerGetCustomerHistoryParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>;
      })
    );
  }
  /**
   * @param params The `CustomerService.CustomerGetCustomerHistoryParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `locale`:
   */
  CustomerGetCustomerHistory(params: CustomerService.CustomerGetCustomerHistoryParams): __Observable<ResponseArgsOfIEnumerableOfHistoryDTO> {
    return this.CustomerGetCustomerHistoryResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfHistoryDTO)
    );
  }
}

module CustomerService {

  /**
   * Parameters for CustomerCanExtendCustomer
   */
  export interface CustomerCanExtendCustomerParams {

    /**
     * Kunde PK
     */
    customerId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for CustomerGetCustomer
   */
  export interface CustomerGetCustomerParams {

    /**
     * Kunde PK
     */
    customerId: number;

    /**
     * eagerLoading
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for CustomerUpdateCustomer
   */
  export interface CustomerUpdateCustomerParams {

    /**
     * Kundendaten
     */
    payload: SaveCustomerValues;

    /**
     * Kunde PK
     */
    customerId: number;
  }

  /**
   * Parameters for CustomerPatchCustomer
   */
  export interface CustomerPatchCustomerParams {

    /**
     * Kunde PK
     */
    customerId: number;

    /**
     * Kundendaten
     */
    customer: CustomerDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for CustomerDeleteCustomer
   */
  export interface CustomerDeleteCustomerParams {

    /**
     * PK Kunden
     */
    customerId: number;

    /**
     * Grund des Löschens
     */
    deletionComment?: null | string;
  }

  /**
   * Parameters for CustomerAddPayerReference
   */
  export interface CustomerAddPayerReferenceParams {

    /**
     * Kunde PK
     */
    customerId: number;

    /**
     * Regulierer/Rechnungsadresse PK
     */
    payerId?: number;

    /**
     * Ist Standard Regulierer/Rechnungsaddresse
     */
    isDefault?: boolean;

    /**
     * eagerLoading (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for CustomerDeactivateCustomer
   */
  export interface CustomerDeactivateCustomerParams {

    /**
     * Kunden PK
     */
    customerId: number;

    /**
     * Deaktivierungsgrund
     */
    deactivationComment?: null | string;
  }

  /**
   * Parameters for CustomerGetAssignedPayersByCustomerId
   */
  export interface CustomerGetAssignedPayersByCustomerIdParams {
    customerId: number;
    take?: null | number;
    skip?: null | number;
  }

  /**
   * Parameters for CustomerModifyPayerReference
   */
  export interface CustomerModifyPayerReferenceParams {
    payerId: number;
    customerId: number;
    isDefault?: boolean;
    eagerLoading?: number;
  }

  /**
   * Parameters for CustomerGetCustomerHistory
   */
  export interface CustomerGetCustomerHistoryParams {
    customerId: number;
    locale?: null | string;
  }
}

export { CustomerService }
