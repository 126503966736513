@if ($offlineBannerVisible()) {
  <div [@fadeInOut] class="bg-brand text-white text-center fixed inset-x-0 top-0 z-tooltip p-4">
    <h3 class="font-bold grid grid-flow-col items-center justify-center text-xl gap-4">
      <div>
        <ng-icon name="matWifiOff"></ng-icon>
      </div>

      <div>Sie sind offline, keine Verbindung zum Netzwerk.</div>
    </h3>
    <p>Bereits geladene Ihnalte werden angezeigt, Interaktionen sind aktuell nicht möglich.</p>
  </div>
}
@if ($onlineBannerVisible()) {
  <div [@fadeInOut] class="bg-green-500 text-white text-center fixed inset-x-0 top-0 z-tooltip p-4">
    <h3 class="font-bold grid grid-flow-col items-center justify-center text-xl gap-4">
      <div>
        <ng-icon name="matWifi"></ng-icon>
      </div>

      <div>Sie sind wieder online.</div>
    </h3>
    <button class="fixed top-2 right-4 text-3xl w-12 h-12" type="button" (click)="$onlineBannerVisible.set(false)">
      <ng-icon name="matClose"></ng-icon>
    </button>
  </div>
}

<router-outlet></router-outlet>
