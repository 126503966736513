import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from '@core/application';
import { ActionHandler } from '@core/command';
import { Result } from '@domain/defs';
import { CustomerInfoDTO } from '@swagger/crm';

@Injectable()
export class CreateCustomerCommand extends ActionHandler<Result<CustomerInfoDTO[]>> {
  constructor(private _router: Router, private _application: ApplicationService) {
    super('CREATE_CUSTOMER');
  }

  async handler(data: Result<CustomerInfoDTO[]>): Promise<Result<CustomerInfoDTO[]>> {
    let customerType: string;
    if (data.result.length > 0) {
      const customerInfo = data.result[0];
      if (customerInfo.features) {
        if (customerInfo.features.some((f) => f.key === 'store')) {
          customerType = 'store';
        }
        if (customerInfo.features.some((f) => f.key === 'webshop')) {
          customerType = 'webshop';
        }
      }
    }

    if (!customerType) {
      customerType = 'store';
    }

    await this._router.navigate(['/kunde', this._application.activatedProcessId, 'customer', 'create', customerType]);
    return data;
  }
}
