<div class="select-filter-option-wrapper">
  <div class="isa-pt-15 isa-pb-15" [class.customer]="module === 'Customer'">
    <!-- <input
    [indeterminate]
      type="checkbox"
      [name]="option?.name"
      [id]="option?.key || option?.name"
      [ngModel]="option?.selected"
      [value]="true"
      (click)="toggle()"
    /> -->
    <ui-checkbox [name]="option?.name" [class.checked]="option?.selected" [ngModel]="option?.selected" (ngModelChange)="toggle()">
      <label [class.checked]="option?.selected" [for]="option?.key || option?.name">{{ option?.name }}</label>
    </ui-checkbox>
  </div>
  <button
    *ngIf="option?.options && option?.options.length > 0"
    type="button"
    class="isa-btn expand-button"
    [class.expanded]="option?.expanded"
    (click)="toggleExpanded()"
  >
    <ui-icon icon="arrow_head" rotate="90deg"></ui-icon>
  </button>
</div>

<ui-select-filter-option
  [class.expanded]="option?.expanded"
  *ngFor="let childOption of option?.options; let index = index"
  [filter]="filter"
  [option]="childOption"
></ui-select-filter-option>
