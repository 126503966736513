<ng-template>
  <div [class]="classList">
    <svg viewBox="0 25 50 25" class="triangle">
      <polygon points="0,50 50,50 25,25"></polygon>
    </svg>
    <div class="rounded bg-white py-4 px-5">
      <ui-datepicker-header></ui-datepicker-header>
      <div class="hr"></div>
      <ui-datepicker-body></ui-datepicker-body>
      <div class="text-center mb-px-10">
        <button *ngIf="!content" class="rounded-full font-bold text-white bg-brand py-px-15 px-px-25" (click)="onSave()">
          <ng-container>{{ saveLabel }}</ng-container>
        </button>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
