/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for Cat services
 */
@Injectable({
  providedIn: 'root',
})
export class CatConfiguration {
  rootUrl: string = 'https://isa-test.paragon-data.net';
}

export interface CatConfigurationInterface {
  rootUrl?: string;
}
