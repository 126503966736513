import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@ui/icon';

import { UiOrderByFilterComponent } from './order-by-filter.component';

@NgModule({
  imports: [CommonModule, UiIconModule],
  exports: [UiOrderByFilterComponent],
  declarations: [UiOrderByFilterComponent],
  providers: [],
})
export class UiOrderByFilterModule {}
