<button class="backdrop" [class.display-backdrop]="toggled" (click)="toggled = !toggled"></button>
<div class="ui-input-wrapper">
  <div class="ui-select-value">{{ label }}</div>
  <button *ngIf="!readonly" type="button" class="ui-select-toggle" [disabled]="disabled || readonly" (click)="toggle()">
    <ui-icon icon="arrow_head"></ui-icon>
  </button>
</div>
<div [class.hide]="!toggled" class="ui-select-dropdown-wrapper">
  <div class="ui-select-options">
    <ng-content select="ui-select-option"></ng-content>
  </div>
</div>
