<div class="flex flex-row items-center rounded p-4 text-white border border-solid" [class]="toastClass">
  <div *ngIf="toast.title" class="font-bold mr-4 shrink-0">
    {{ toast.title }}
  </div>
  <div class="grow">
    {{ toast.message }}
  </div>
  <div class="text-right grow-0" *ngIf="closeable">
    <button (click)="closeToast()">
      <shared-icon icon="close"></shared-icon>
    </button>
  </div>
</div>
