/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AvConfiguration, AvConfigurationInterface } from './av-configuration';

import { AvailabilityService } from './services/availability.service';
import { WebshopAvailabilityService } from './services/webshop-availability.service';

/**
 * Provider for all Av services, plus AvConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AvConfiguration,
    AvailabilityService,
    WebshopAvailabilityService
  ],
})
export class AvModule {
  static forRoot(customParams: AvConfigurationInterface): ModuleWithProviders<AvModule> {
    return {
      ngModule: AvModule,
      providers: [
        {
          provide: AvConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
