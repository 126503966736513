/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { IsaConfiguration as __Configuration } from '../isa-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgs } from '../models/response-args';
import { UserState } from '../models/user-state';
import { ResponseArgsOfUserState } from '../models/response-args-of-user-state';
import { Log } from '../models/log';
import { ResponseArgsOfIEnumerableOfLogEntry } from '../models/response-args-of-ienumerable-of-log-entry';
@Injectable({
  providedIn: 'root',
})
class UserStateService extends __BaseService {
  static readonly UserStateSetUserStatePath = '/isa/userstate';
  static readonly UserStateResetUserStatePath = '/isa/userstate';
  static readonly UserStateGetUserStatePath = '/isa/userstate';
  static readonly UserStateSaveLogPath = '/isa/logging';
  static readonly UserStateGetLogPath = '/isa/logging';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Benutzerdaten speichern
   * @param userState Content
   */
  UserStateSetUserStateResponse(userState: UserState): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = userState;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/isa/userstate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      })
    );
  }
  /**
   * Benutzerdaten speichern
   * @param userState Content
   */
  UserStateSetUserState(userState: UserState): __Observable<ResponseArgs> {
    return this.UserStateSetUserStateResponse(userState).pipe(
      __map(_r => _r.body as ResponseArgs)
    );
  }

  /**
   * Benutzerdaten löschen
   */
  UserStateResetUserStateResponse(): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/isa/userstate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      })
    );
  }
  /**
   * Benutzerdaten löschen
   */
  UserStateResetUserState(): __Observable<ResponseArgs> {
    return this.UserStateResetUserStateResponse().pipe(
      __map(_r => _r.body as ResponseArgs)
    );
  }

  /**
   * Benutzerdaten auslesen
   * @return Benutzerdaten
   */
  UserStateGetUserStateResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfUserState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/isa/userstate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfUserState>;
      })
    );
  }
  /**
   * Benutzerdaten auslesen
   * @return Benutzerdaten
   */
  UserStateGetUserState(): __Observable<ResponseArgsOfUserState> {
    return this.UserStateGetUserStateResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfUserState)
    );
  }

  /**
   * Logdaten speichern
   * @param log Logdaten
   */
  UserStateSaveLogResponse(log: Log): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = log;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/isa/logging`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Logdaten speichern
   * @param log Logdaten
   */
  UserStateSaveLog(log: Log): __Observable<null> {
    return this.UserStateSaveLogResponse(log).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Benutzerdaten auslesen
   * @param params The `UserStateService.UserStateGetLogParams` containing the following parameters:
   *
   * - `stop`:
   *
   * - `start`:
   *
   * @return Benutzerdaten
   */
  UserStateGetLogResponse(params: UserStateService.UserStateGetLogParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfLogEntry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.stop != null) __params = __params.set('stop', params.stop.toString());
    if (params.start != null) __params = __params.set('start', params.start.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/isa/logging`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfLogEntry>;
      })
    );
  }
  /**
   * Benutzerdaten auslesen
   * @param params The `UserStateService.UserStateGetLogParams` containing the following parameters:
   *
   * - `stop`:
   *
   * - `start`:
   *
   * @return Benutzerdaten
   */
  UserStateGetLog(params: UserStateService.UserStateGetLogParams): __Observable<ResponseArgsOfIEnumerableOfLogEntry> {
    return this.UserStateGetLogResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfLogEntry)
    );
  }
}

module UserStateService {

  /**
   * Parameters for UserStateGetLog
   */
  export interface UserStateGetLogParams {
    stop?: string;
    start?: string;
  }
}

export { UserStateService }
