import { ModuleWithProviders, NgModule } from '@angular/core';
import { NotificationsHub } from './notifications.hub';

@NgModule({})
export class NotificationsHubModule {
  static forRoot(): ModuleWithProviders<NotificationsHubModule> {
    return {
      ngModule: NotificationsHubModule,
      providers: [NotificationsHub],
    };
  }
}
