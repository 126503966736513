import { AbstractControl } from '@angular/forms';
import * as _ from 'lodash';

export function setInvalidPropertyErrors({
  invalidProperties,
  formGroup,
}: {
  invalidProperties: { [key: string]: string };
  formGroup: AbstractControl;
}) {
  for (const key in invalidProperties) {
    if (Object.prototype.hasOwnProperty.call(invalidProperties, key)) {
      const invalidProperty = invalidProperties[key];
      formGroup?.get(_.camelCase(key))?.setErrors({ invalidProperty });
    }
  }
}
