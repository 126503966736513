<div class="order-by-filter-button-wrapper">
  <button
    [attr.data-label]="label"
    class="order-by-filter-button"
    type="button"
    *ngFor="let label of orderByKeys"
    (click)="setActive(label)"
  >
    <span>
      {{ label }}
    </span>
    <ui-icon
      [class.asc]="label === activeOrderBy?.label && !activeOrderBy.desc"
      [class.desc]="label === activeOrderBy?.label && activeOrderBy.desc"
      icon="arrow"
      size="14px"
    ></ui-icon>
  </button>
</div>
