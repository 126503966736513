import { NgModule } from '@angular/core';
import { AddressSelectionModalModule } from './address-selection-modal';
import { CantAddCustomerToCartModalModule } from './cant-add-customer-to-cart-modal';
import { CantSelectGuestModalModule } from './cant-select-guest';
import { WebshopCustomnerAlreadyExistsModalModule } from './webshop-customer-already-exists';

@NgModule({
  imports: [
    AddressSelectionModalModule,
    CantAddCustomerToCartModalModule,
    CantSelectGuestModalModule,
    WebshopCustomnerAlreadyExistsModalModule,
  ],
  exports: [
    AddressSelectionModalModule,
    CantAddCustomerToCartModalModule,
    CantSelectGuestModalModule,
    WebshopCustomnerAlreadyExistsModalModule,
  ],
})
export class CustomerModalModuleModule {}
