import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TokenLoginComponent } from './token-login.component';

@NgModule({
  imports: [CommonModule],
  exports: [TokenLoginComponent],
  declarations: [TokenLoginComponent],
})
export class TokenLoginModule {}
