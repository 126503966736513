/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OmsConfiguration as __Configuration } from '../oms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfSupplierDTO } from '../models/list-response-args-of-supplier-dto';
import { ResponseArgsOfSupplierDTO } from '../models/response-args-of-supplier-dto';
@Injectable({
  providedIn: 'root',
})
class SupplierService extends __BaseService {
  static readonly SupplierGetSuppilersPath = '/order/supplier';
  static readonly SupplierGetSupplierPath = '/order/supplier/{supplierId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Listet alle Lieferanten
   * @param params The `SupplierService.SupplierGetSuppilersParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  SupplierGetSuppilersResponse(params: SupplierService.SupplierGetSuppilersParams): __Observable<__StrictHttpResponse<ListResponseArgsOfSupplierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/supplier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSupplierDTO>;
      })
    );
  }
  /**
   * Listet alle Lieferanten
   * @param params The `SupplierService.SupplierGetSuppilersParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  SupplierGetSuppilers(params: SupplierService.SupplierGetSuppilersParams): __Observable<ListResponseArgsOfSupplierDTO> {
    return this.SupplierGetSuppilersResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfSupplierDTO)
    );
  }

  /**
   * Lieferant
   * @param supplierId PK Lieferant
   */
  SupplierGetSupplierResponse(supplierId: number): __Observable<__StrictHttpResponse<ResponseArgsOfSupplierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/supplier/${encodeURIComponent(String(supplierId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfSupplierDTO>;
      })
    );
  }
  /**
   * Lieferant
   * @param supplierId PK Lieferant
   */
  SupplierGetSupplier(supplierId: number): __Observable<ResponseArgsOfSupplierDTO> {
    return this.SupplierGetSupplierResponse(supplierId).pipe(
      __map(_r => _r.body as ResponseArgsOfSupplierDTO)
    );
  }
}

module SupplierService {

  /**
   * Parameters for SupplierGetSuppilers
   */
  export interface SupplierGetSuppilersParams {
    take?: null | number;
    skip?: null | number;
  }
}

export { SupplierService }
