import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ApplicationService } from '@core/application';
import { Observable } from 'rxjs';

export abstract class SectionResolver {
  constructor(protected section: 'customer' | 'branch', protected applicationService: ApplicationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<string> | Promise<string> | string {
    this.applicationService.setSection(this.section);
    return this.section;
  }
}

@Injectable({ providedIn: 'root' })
export class CustomerSectionResolver extends SectionResolver {
  constructor(applicationService: ApplicationService) {
    super('customer', applicationService);
  }
}

@Injectable({ providedIn: 'root' })
export class BranchSectionResolver extends SectionResolver {
  constructor(applicationService: ApplicationService) {
    super('branch', applicationService);
  }
}
