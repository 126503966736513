import { Injectable } from '@angular/core';
import { InputDTO, InputType, OptionDTO } from '@swagger/crm';
import { FilterType, SelectFilter, SelectFilterOption } from './models';

@Injectable({ providedIn: 'root' })
export class UiFilterMappingService {
  constructor() {}

  fromInputDto(input: InputDTO): SelectFilter {
    return {
      key: input.key,
      name: input.label,
      max: input.options && input.options.max,
      type: this.inputTypeToType(input.type),
      options: input.options && input.options.values.map((v) => this.fromOptionDto(v)),
    } as SelectFilter;
  }

  fromOptionDto(option: OptionDTO): SelectFilterOption {
    return {
      type: 'select',
      name: option.label,
      id: option.key || option.value,
      selected: option.selected || false,
      initial_selected_state: option.selected || false,
      expanded: false,
      options: option.values && option.values.map((v) => this.fromOptionDto(v)),
    } as SelectFilterOption;
  }

  inputTypeToType(type: InputType): FilterType {
    return 'select';
  }
}
