import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { AddressDTO, CommunicationDetailsDTO, OrganisationDTO } from '@swagger/crm';

export interface AddressData {
  gender?: number;
  title?: string;
  firstName?: string;
  lastName?: string;
  address?: AddressDTO;
  organisation?: OrganisationDTO;
  communicationDetails?: CommunicationDetailsDTO;
}

@Pipe({
  name: 'address',
  pure: false,
  standalone: true,
})
export class AddressPipe implements PipeTransform {
  private result: string;
  private data: AddressData;
  private useComma = false;

  constructor(private cdr: ChangeDetectorRef) {}

  transform(data: AddressData, useComma?: boolean): string {
    this.data = data;
    this.useComma = useComma ?? this.useComma;
    this.getResult();

    return this.result;
  }

  async getResult() {
    let result = undefined;
    let parts = [
      this.data?.organisation?.name || '',
      this.data?.organisation?.department || '',
      [this.data?.lastName, this.data?.firstName].filter((f) => !!f).join(this.useComma ? ' ' : ', '),
      `${this.data?.address?.street || ''} ${this.data?.address?.streetNumber || ''}`,
      `${this.data?.address?.zipCode || ''} ${this.data?.address?.city || ''}`,
    ]
      .filter((value) => value != '')
      .filter((value) => value.trim());
    result = parts.join(this.useComma ? ', ' : ' | ');

    if (result !== this.result) {
      this.result = result;
      this.cdr.markForCheck();
    }
  }
}
