import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFilterInputOptionBoolComponent } from './filter-input-option-bool.component';
import { UiCheckboxModule } from '@ui/checkbox';
import { FormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';

@NgModule({
  imports: [CommonModule, FormsModule, UiCheckboxModule, UiIconModule],
  exports: [UiFilterInputOptionBoolComponent],
  declarations: [UiFilterInputOptionBoolComponent],
})
export class UiFilterInputOptionBoolModule {}
