import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiAutocompleteComponent } from './autocomplete.component';
import { UiAutocompleteItemComponent } from './autocomplete-item.component';

@NgModule({
  imports: [CommonModule],
  exports: [UiAutocompleteComponent, UiAutocompleteItemComponent],
  declarations: [UiAutocompleteComponent, UiAutocompleteItemComponent],
})
export class UiAutocompleteModule {}
