import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiInputOptionTriStateComponent } from './filter-input-option-tri-state.component';
import { FormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';
import { UiSwitchModule } from '@ui/switch';

@NgModule({
  imports: [CommonModule, UiSwitchModule, FormsModule, UiIconModule],
  exports: [UiInputOptionTriStateComponent],
  declarations: [UiInputOptionTriStateComponent],
})
export class UiInputOptionTriStateModule {}
