import { NgModule, Provider } from '@angular/core';
import { IconComponent } from './icon.component';
import { IconLoader, JsonIconLoader } from './loader';
import { IconRegistry } from './icon-registry';

export function provideIcon(loaderProvider?: Provider) {
  const providers: Provider[] = [IconRegistry];
  if (!loaderProvider) {
    providers.push({
      provide: IconLoader,
      useClass: JsonIconLoader,
    });
  } else {
    providers.push(loaderProvider);
  }

  return providers;
}

@NgModule({
  imports: [IconComponent],
  exports: [IconComponent],
})
export class IconModule {
  static forRoot(loaderProvider?: Provider) {
    return {
      ngModule: IconModule,
      providers: provideIcon(loaderProvider),
    };
  }
}
