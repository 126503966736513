import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
@Pipe({
  name: 'firstError',
  standalone: true,
})
export class FirstErrorPipe implements PipeTransform {
  transform(errors: ValidationErrors, label: string): string {
    if (errors) {
      const error = Object.keys(errors)[0];
      switch (error) {
        case 'min':
          return `${label} wird benötigt`; // gender validation for create (upgrade) online customer with gender min value of 2
        case 'max':
          return `${label} ist ungültig`;
        case 'required':
          return `${label} wird benötigt`;
        case 'email':
          return `${label} ist ungültig`;
        case 'pattern':
          return `${label} ist ungültig`;
        default:
          return errors[error];
      }
    }
    return undefined;
  }
}
