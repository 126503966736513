import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import packageInfo from 'package';
import { environment } from '../environments/environment';
import { RootStateService } from './store/root-state.service';
import { rootReducer } from './store/root.reducer';
import { RootState } from './store/root.state';

export function storeInLocalStorage(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === 'HYDRATE') {
      const initialState = RootStateService.LoadFromLocalStorage();

      if (initialState?.version === packageInfo.version) {
        return reducer(initialState, action);
      }
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<RootState>[] = !environment.production ? [storeFreeze, storeInLocalStorage] : [storeInLocalStorage];

@NgModule({
  imports: [
    StoreModule.forRoot(rootReducer, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ name: 'ISA Ngrx Application Store', connectInZone: true }),
  ],
})
export class AppStoreModule {}
