/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { PrintConfiguration as __Configuration } from '../print-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgs } from '../models/response-args';
import { PrintRequestOfLong } from '../models/print-request-of-long';
import { PrintRequestOfString } from '../models/print-request-of-string';
@Injectable({
  providedIn: 'root',
})
class InventoryPrintService extends __BaseService {
  static readonly InventoryPrintWarenbegleitscheinePath = '/print/warenbegleitscheine';
  static readonly InventoryPrintPrintReturnGroupPath = '/print/returngroup';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Warenbegleitscheine
   * @param data Remission PK
   */
  InventoryPrintWarenbegleitscheineResponse(data: PrintRequestOfLong): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/print/warenbegleitscheine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      })
    );
  }
  /**
   * Warenbegleitscheine
   * @param data Remission PK
   */
  InventoryPrintWarenbegleitscheine(data: PrintRequestOfLong): __Observable<ResponseArgs> {
    return this.InventoryPrintWarenbegleitscheineResponse(data).pipe(
      __map(_r => _r.body as ResponseArgs)
    );
  }

  /**
   * Remissions-Gruppe
   * @param data Remissionsgruppe
   */
  InventoryPrintPrintReturnGroupResponse(data: PrintRequestOfString): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/print/returngroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      })
    );
  }
  /**
   * Remissions-Gruppe
   * @param data Remissionsgruppe
   */
  InventoryPrintPrintReturnGroup(data: PrintRequestOfString): __Observable<ResponseArgs> {
    return this.InventoryPrintPrintReturnGroupResponse(data).pipe(
      __map(_r => _r.body as ResponseArgs)
    );
  }
}

module InventoryPrintService {
}

export { InventoryPrintService }
