<div class="bg-background-liste" *ngIf="showHeader">
  <div class="flex flex-row items-center justify-end h-14">
    <div class="package-count inline-flex flex-row items-center pr-5">{{ total ?? 0 }} Packstücke</div>
  </div>
</div>
<cdk-virtual-scroll-viewport
  [itemSize]="53"
  class="h-[calc(100vh-420px)] scroll-bar"
  (scrolledIndexChange)="scrollIndexChange.emit($event)"
>
  <a [routerLink]="[package.id]" *cdkVirtualFor="let package of packages; trackBy: trackByFn" [class.read-only]="!navigationEnabled">
    <page-package-list-item class="pt-px-2" [package]="package"></page-package-list-item>
  </a>

  <page-package-list-item-loader *ngIf="fetching"> </page-package-list-item-loader>
</cdk-virtual-scroll-viewport>
