import { Component, ChangeDetectionStrategy, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-switch',
  templateUrl: 'ui-switch.component.html',
  styleUrls: ['ui-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiSwitchComponent), multi: true }],
})
export class UiSwitchComponent implements ControlValueAccessor {
  private _value?: boolean;

  private onChange = (value: any) => {};

  private onTouched = () => {};

  get value() {
    return this._value;
  }

  @Input()
  labelOn: string;

  @Input()
  labelOff: string;

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(obj?: boolean): void {
    this._value = obj;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  setValue(value?: boolean) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.cdr.markForCheck();
  }
}
