import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@ui/icon';
import { UiSelectBulletComponent } from './select-bullet.component';

@NgModule({
  declarations: [UiSelectBulletComponent],
  imports: [CommonModule, UiIconModule],
  exports: [UiSelectBulletComponent],
})
export class UiSelectBulletModule {}
