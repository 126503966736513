import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Breadcrumb } from '../defs';

export interface BreadcrumbState extends EntityState<Breadcrumb> {}

export const featureName = 'core-breadcrumb';

export const breadcrumbAdapter = createEntityAdapter<Breadcrumb>();

export const INIT: BreadcrumbState = {
  ...breadcrumbAdapter.getInitialState(),
};
