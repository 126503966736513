export interface Breadcrumb {
  /**
   * Eindeutige ID für die Entity
   */
  id?: number;

  /**
   * Identifier für ein Teilbereich/ProzessId der Applikation
   */
  key: number | string;

  /**
   * Tags
   */
  tags?: string[];

  /**
   * Anzeigename
   */
  name: string;

  /**
   * Url
   */
  path: string | any[];

  /**
   * Query Parameter
   */
  params?: Object;

  /**
   * Timestamp
   */
  timestamp?: number;

  /**
   * Cahnged
   */
  changed?: number;

  /**
   * Applicatiuon Section
   */
  section: string;
}
