import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, EventEmitter, HostBinding, Input } from '@angular/core';

@Directive()
export abstract class UiFormControlDirective<T> {
  @Input()
  @HostBinding('attr.name')
  name: string = (Date.now() + Math.random()).toString(32);

  @Input()
  @HostBinding('attr.id')
  id: string = (Date.now() + Math.random()).toString(32);

  private _readonly = false;

  @HostBinding('readonly')
  @Input('readonly')
  _isReadonly: BooleanInput = false;

  get readonly(): boolean {
    return coerceBooleanProperty(this._isReadonly);
  }
  set readonly(value: boolean) {
    this._isReadonly = coerceBooleanProperty(value);
  }

  focused = new EventEmitter<boolean>();

  abstract type: string;

  abstract get valueEmpty(): boolean;

  abstract clear(): void;

  abstract focus(): void;
}
