import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ScanAdapter } from './scan-adapter';
import { SCAN_ADAPTER } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class ScanAdapterService {
  private _readyAdapters: Record<string, boolean> = {};

  constructor(@Inject(SCAN_ADAPTER) private readonly scanAdapters: ScanAdapter[]) {}

  async init(): Promise<void> {
    for (const adapter of this.scanAdapters) {
      const isReady = await adapter.init();
      this._readyAdapters[adapter.name] = isReady;
    }
  }

  adapters(): ScanAdapter[] {
    return [...this.scanAdapters];
  }

  getAdapter(name: string): ScanAdapter | undefined {
    return this._readyAdapters[name] && this.scanAdapters.find((adapter) => adapter.name === name);
  }

  isReady(): boolean {
    return Object.values(this._readyAdapters).some((ready) => ready);
  }

  scan(): Observable<string> {
    const adapterOrder = ['Native', 'Scandit', 'Dev'];

    let adapter: ScanAdapter;

    for (const name of adapterOrder) {
      adapter = this.getAdapter(name);

      if (adapter) {
        break;
      }
    }

    if (!adapter) {
      return throwError('No adapter found');
    }

    return adapter.scan();
  }
}
