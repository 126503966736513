<div class="w-64">
  <span class="page-package-list-item__package-number truncate">
    {{ package?.packageNumber }}
  </span>
  <span class="page-package-list-item__number-divider" *ngIf="package?.packageNumber && package?.deliveryNoteNumber"> | </span>
  <span class="page-package-list-item__delivery-note-number">
    {{ package?.deliveryNoteNumber }}
  </span>
</div>
<div class="w-28 page-package-list-item__estimated-delivery-date">
  {{ package?.estimatedDeliveryDate | date }}
</div>
<div class="w-32 page-package-list-item__items-count">{{ package?.items ?? '-' }} <span class="font-normal">Exemplare</span></div>
<div data-which="Statusmeldung" class="text-right grow">
  <div
    *ngIf="!hasAnnotation; else annotationTmpl"
    class="rounded inline-block px-4 text-white page-package-list-item__arrival-status whitespace-nowrap"
    data-what="arrival-status"
    [class]="package?.arrivalStatus | arrivalStatusColorClass"
  >
    {{ package?.arrivalStatus | arrivalStatus }}
  </div>
  <ng-template #annotationTmpl>
    <div data-what="annotation" class="page-package-list-item__annotation">
      {{ annotation }}
    </div>
  </ng-template>
</div>
