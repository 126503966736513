import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DateAdapter } from '@ui/common';
import { map } from 'rxjs/operators';
import { Datepicker } from '../datepicker';
@Component({
  selector: 'ui-datepicker-body',
  templateUrl: 'body.component.html',
  styleUrls: ['body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiDatepickerBodyComponent implements OnInit {
  selected$ = this.datepicker.selected$;
  displayed$ = this.datepicker.displayed$;
  min$ = this.datepicker.min$;
  max$ = this.datepicker.max$;

  dates$ = this.datepicker.displayed$.pipe(map((date) => this.getDateCellsForDate(date)));

  constructor(private datepicker: Datepicker, private dateAdapter: DateAdapter) {}

  ngOnInit() {}

  getDateCellsForDate(date: Date): Date[][] {
    const firstDateOfMonth = this.dateAdapter.getFirstDateOfMonth(date);
    const firstCalendarDate = this.dateAdapter.getFirstDateOfWeek(firstDateOfMonth);
    const lastDateOfMonth = this.dateAdapter.getLastDateOfMonth(date);
    const lastCalendarDate = this.dateAdapter.getLastDateOfWeek(lastDateOfMonth);
    const dates: Date[][] = [];

    const days = this.dateAdapter.getDifferenceInDays(lastCalendarDate, firstCalendarDate);

    for (let day = 0; day <= days; day++) {
      if (day % 7 === 0) {
        dates.push([]);
      }

      const current = this.dateAdapter.addCalendarDays(firstCalendarDate, day);
      dates[dates.length - 1].push(current);
    }

    return dates;
  }

  selectDate(date: Date) {
    this.datepicker.setSelected(date);
  }
}
