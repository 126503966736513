import { AsyncPipe, NgIf } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { EnvironmentService } from '@core/environment';
import { OnInitDirective } from '@shared/directives/element-lifecycle';
import { NEVER } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'shared-splitscreen',
  templateUrl: 'splitscreen.component.html',
  styleUrls: ['splitscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: { class: 'shared-splitscreen' },
  imports: [RouterOutlet, AsyncPipe, NgIf],
})
export class SharedSplitscreenComponent implements AfterViewInit {
  destroyRef = inject(DestroyRef);

  environment = inject(EnvironmentService);

  @ViewChildren('sideOutlet', { read: RouterOutlet })
  side: QueryList<RouterOutlet>;

  desktopLarge = signal(false);

  sideActivated = signal(false);

  ngAfterViewInit() {
    this.environment.matchDesktopLarge$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((desktopLarge) => {
      this.desktopLarge.set(desktopLarge);
      if (!desktopLarge) {
        this.sideActivated.set(false);
      }
    });
  }

  onActivate() {
    this.sideActivated.set(true);
  }

  onDeactivate() {
    this.sideActivated.set(false);
  }
}
