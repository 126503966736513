import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter, OnInit } from '@angular/core';
import { UiOrderBy } from '@ui/filter';

@Component({
  selector: 'ui-order-by-filter',
  templateUrl: 'order-by-filter.component.html',
  styleUrls: ['order-by-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiOrderByFilterComponent implements OnInit {
  @Input()
  orderBy: UiOrderBy[];

  @Output()
  selectedOrderByChange = new EventEmitter<UiOrderBy>();

  get orderByKeys() {
    return this.orderBy?.map((ob) => ob.label).filter((key, idx, self) => self.indexOf(key) === idx);
  }

  get activeOrderBy() {
    return this.orderBy?.find((f) => f.selected);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    setTimeout(() => this.cdr.markForCheck(), 0);
  }

  setActive(orderBy: string) {
    const active = this.activeOrderBy;
    const orderBys = this.orderBy?.filter((f) => f.label === orderBy);
    let next: UiOrderBy;

    if (orderBys?.length) {
      if (active?.label !== orderBy) {
        next = orderBys?.find((f) => !f.desc);
      } else if (!active.desc) {
        next = orderBys?.find((f) => f.desc);
      }
    }

    this.orderBy?.filter((f) => f.selected)?.forEach((f) => f.setSelected(false));

    next?.setSelected(true);

    this.selectedOrderByChange.next(next);
    this.cdr.markForCheck();
  }
}
