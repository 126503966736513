<div class="details-wrapper">
  <div class="img-wrapper">
    <img class="rounded shadow w-20" [src]="item?.product?.ean | productImage" [alt]="item?.product?.name" />
  </div>
  <div>
    <div class="text-sm">{{ item?.product?.contributors }}</div>
    <div class="font-bold">{{ item?.product?.name }}</div>
    <div class="font-bold mt-4 flex flex-row items-center">
      <img class="h-5 mr-2" [src]="iconSrc" [alt]="item?.product?.formatDetail" />
      <span>{{ item?.product?.formatDetail }}</span>
    </div>
    <div class="text-sm">{{ item?.product?.manufacturer }} | {{ item?.product?.ean }}</div>
    <div class="text-sm">{{ item?.product?.volume }} | {{ item?.product?.publicationDate | date }}</div>
  </div>
  <div class="text-right">
    <div class="font-bold text-xl mt-4">{{ item?.unitPrice?.value?.value | currency: 'EUR' }}</div>
    <div class="font-bold">
      <ui-quantity-dropdown [quantity]="itemQuantity$ | async" (quantityChange)="onQuantityChange($event)"></ui-quantity-dropdown>
    </div>
    <div class="relative">
      <div *ngIf="stockWarning$ | async" class="text-warning font-bold absolute right-0 top-0 whitespace-nowrap">
        Es befinden sich {{ availableQuantity$ | async }} Exemplare in der Filiale
      </div>
    </div>
  </div>
</div>
