import { Component, OnInit } from '@angular/core';
import { CommandService } from '@core/command';
import { UiModalRef } from '../defs/modal-ref';
import { DialogModel } from './dialog.model';

@Component({
  selector: 'ui-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss'],
})
export class UiDialogModalComponent implements OnInit {
  constructor(public modalRef: UiModalRef<any, DialogModel<any>>, private _command: CommandService) {}

  ngOnInit() {}

  async handleCommand(command: string) {
    if (command === 'CLOSE') {
      this.modalRef.close();
      return;
    }

    if (this.modalRef.data.handleCommand ?? true) {
      await this._command.handleCommand(command, this.modalRef.data.context);
    }

    this.modalRef.close(command);
  }
}
