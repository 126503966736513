import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFilterInputTextComponent } from './input-text.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormControlModule } from '@ui/form-control';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UiFormControlModule],
  exports: [UiFilterInputTextComponent],
  declarations: [UiFilterInputTextComponent],
})
export class UiFilterInputTextModule {}
