<div class="interests-description">Geben Sie Interessen an, um Ihre persönlichen Kontoangaben zu verfeinern.</div>
<div class="interests-wrapper" [formGroup]="control">
  <shared-checkbox
    *ngFor="let pair of interests | keyvalue; let idx = index"
    [formControlName]="pair.key"
    [tabindex]="tabIndexStart + idx"
    [autofocus]="focusAfterInit"
    [readonly]="readonly"
  >
    {{ pair.value }}
  </shared-checkbox>
</div>
