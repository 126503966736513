export interface ApplicationProcess {
  id: number;
  created?: number;
  activated?: number;
  name: string;
  section: 'customer' | 'branch';
  type?: string;
  data?: { [key: string]: any };
  closeable?: boolean;
  confirmClosing?: boolean;
}
