import { NgModule } from '@angular/core';
import { UiFilterGroupModule } from './filter-group/filter-group.module';
import { UiSelectedFilterOptionsModule } from './selected-filter-options';
import { ObserversModule } from '@angular/cdk/observers';

@NgModule({
  imports: [UiFilterGroupModule, UiSelectedFilterOptionsModule, ObserversModule],
  exports: [UiFilterGroupModule, UiSelectedFilterOptionsModule],
  declarations: [],
  providers: [],
})
export class UiFilterModule {}
