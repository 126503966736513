import { Injectable } from '@angular/core';
import { AbholfachService, AutocompleteTokenDTO, QueryTokenDTO } from '@swagger/oms';
import { DateAdapter } from '@ui/common';
import { memorize } from '@utils/common';
import { shareReplay } from 'rxjs/operators';
@Injectable()
export class DomainGoodsService {
  constructor(private abholfachService: AbholfachService, private dateAdapter: DateAdapter) {}

  searchWareneingang(queryToken: QueryTokenDTO) {
    return this.abholfachService.AbholfachWareneingang(queryToken);
  }

  searchWarenausgabe(queryToken: QueryTokenDTO) {
    return this.abholfachService.AbholfachWarenausgabe(queryToken);
  }

  wareneingangComplete(autocompleteToken: AutocompleteTokenDTO) {
    return this.abholfachService.AbholfachWareneingangAutocomplete(autocompleteToken);
  }

  warenausgabeComplete(autocompleteToken: AutocompleteTokenDTO) {
    return this.abholfachService.AbholfachWarenausgabeAutocomplete(autocompleteToken);
  }

  getWareneingangItemByOrderNumber(orderNumber: string) {
    return this.abholfachService.AbholfachWareneingang({
      filter: { all_branches: 'true', archive: 'true' },
      input: {
        qs: orderNumber,
      },
    });
  }

  getWarenausgabeItemByOrderNumber(orderNumber: string, archive: boolean) {
    return this.abholfachService.AbholfachWarenausgabe({
      filter: { all_branches: 'true', archive: `${archive}` },
      input: {
        qs: orderNumber,
      },
    });
  }

  getWarenausgabeItemByCompartment(compartmentCode: string, archive: boolean) {
    return this.abholfachService.AbholfachWarenausgabe({
      filter: { all_branches: 'true', archive: `${archive}` },
      input: {
        qs: compartmentCode,
      },
    });
  }

  getWareneingangItemByCustomerNumber(customerNumber: string) {
    // Suche anhand der Kundennummer mit Status Bestellt, nachbestellt, eingetroffen, weitergeleitet intern
    return this.abholfachService.AbholfachWareneingang({
      filter: { orderitemprocessingstatus: '16;128;8192;1048576' },
      input: {
        customer_name: customerNumber,
      },
    });
  }

  list() {
    const base = this.dateAdapter.today();
    const startDate = this.dateAdapter.addCalendarDays(base, -5);
    const endDate = this.dateAdapter.addCalendarDays(base, 1);
    const queryToken: QueryTokenDTO = {
      filter: {
        orderitemprocessingstatus: '16;8192;1024;512;2048',
        estimatedshippingdate: `"${startDate.toJSON()}"-"${endDate.toJSON()}"`,
      },
      orderBy: [{ by: 'estimatedshippingdate' }],
      skip: 0,
      take: 20,
    };
    return this.searchWareneingang(queryToken);
  }

  @memorize()
  goodsInQuerySettings() {
    return this.abholfachService.AbholfachWareneingangQuerySettings().pipe(shareReplay());
  }

  @memorize()
  goodsOutQuerySettings() {
    return this.abholfachService.AbholfachWarenausgabeQuerySettings().pipe(shareReplay());
  }

  goodsInList(queryToken: QueryTokenDTO) {
    return this.abholfachService.AbholfachWareneingangsliste(queryToken);
  }

  @memorize()
  goodsInListQuerySettings() {
    return this.abholfachService.AbholfachWareneingangslisteQuerySettings().pipe(shareReplay());
  }

  goodsInCleanupList() {
    return this.abholfachService.AbholfachAbholfachbereinigungsliste();
  }

  goodsInReservationList(queryToken: QueryTokenDTO) {
    return this.abholfachService.AbholfachReservierungen(queryToken);
  }

  goodsInRemissionPreviewList() {
    return this.abholfachService.AbholfachAbholfachremissionsvorschau();
  }

  createGoodsInRemissionFromPreviewList() {
    return this.abholfachService.AbholfachCreateAbholfachremission();
  }
}
