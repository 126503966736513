import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ApplicationService } from '@core/application';
import { Config } from '@core/config';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CanActivateRemissionGuard {
  constructor(
    private readonly _applicationService: ApplicationService,
    private readonly _config: Config,
    private readonly _router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const process = await this._applicationService.getProcessById$(this._config.get('process.ids.remission')).pipe(first()).toPromise();
    if (!process) {
      await this._applicationService.createProcess({
        id: this._config.get('process.ids.remission'),
        type: 'remission',
        section: 'branch',
        name: 'Remission',
      });
    }

    this._applicationService.activateProcess(this._config.get('process.ids.remission'));

    if (!!process?.data?.active && !state.url.includes(`/filiale/remission/${process?.data?.active}`)) {
      const queryParams = process?.data?.queryParams ?? {};
      await this._router.navigate(['/filiale', 'remission', process?.data?.active, 'list'], { queryParams });
      return false;
    } else {
      return true;
    }
  }
}
