import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentType',
  pure: true,
  standalone: true,
})
export class PaymentTypePipe implements PipeTransform {
  paymentTypeText = {
    0: '',
    1: 'bei Abholung',
    2: 'Kostenfrei',
    4: 'Bar',
    8: 'Bankeinzug',
    16: 'Einzugsermächtigung',
    32: 'EC-Karte',
    64: 'Kreditkarte',
    128: 'Gegen Rechnung',
    256: 'Vorauskasse',
    512: 'Gutschein',
    1024: 'Sammelrechnung',
    2048: 'PayPal',
    4069: 'InstantTransfer',
    8192: 'PayOnDelivery',
    16384: 'BonusCard',
  };

  transform(value: any): any {
    return this.paymentTypeText[value] || '';
  }
}
