import { ModuleWithProviders, NgModule } from '@angular/core';
import { DomainDashboardService } from './dashboard.service';

@NgModule({})
export class DomainIsaModule {
  static forRoot(): ModuleWithProviders<DomainIsaModule> {
    return {
      ngModule: DomainIsaModule,
      providers: [DomainDashboardService],
    };
  }
}
