import { Injectable } from '@angular/core';
import { ActionHandler, CommandService } from '@core/command';
import { OrderItemsContext } from './order-items.context';
import { UiModalService } from '@ui/modal';
import { ReorderModalComponent, ReorderResult } from '@modal/reorder';
import { DomainCheckoutService } from '@domain/checkout';
import { AvailabilityDTO2, OrderItemListItemDTO } from '@swagger/oms';
import { ToasterService } from '@shared/shell';

@Injectable()
export class ReOrderActionHandler extends ActionHandler<OrderItemsContext> {
  constructor(
    private _command: CommandService,
    private _domainCheckoutService: DomainCheckoutService,
    private _uiModal: UiModalService,
    private _toastService: ToasterService
  ) {
    super('REORDER');
  }

  async handler(data: OrderItemsContext): Promise<OrderItemsContext> {
    const updatedItems: OrderItemListItemDTO[] = [];
    for (const orderItem of data.items) {
      const result = await this._uiModal
        .open<ReorderResult, { item: OrderItemListItemDTO; showReasons: boolean }>({
          content: ReorderModalComponent,
          title: 'Artikel nachbestellen',
          data: {
            item: { ...orderItem, quantity: data.itemQuantity?.get(orderItem.orderItemSubsetId) ?? orderItem.quantity },
            showReasons: true,
          },
        })
        .afterClosed$.toPromise();
      if (result.data) {
        if (result.data.action === 'REORDER') {
          const reorderResult = await this.reorder(result.data.item, result.data.availability, result.data.comment);
          const resItem = reorderResult.item1;

          this.createToast(result?.data?.comment);

          updatedItems.push({
            ...result.data.item,
            orderItemSubsetId: resItem.id,
            compartmentCode: resItem.compartmentCode,
            compartmentInfo: resItem.compartmentInfo,
            estimatedShippingDate: resItem.estimatedShippingDate,
            isPrebooked: resItem.isPrebooked,
            processingStatus: 8192,
            processingStatusDate: resItem.processingStatusDate,
            quantity: resItem.quantity,
            specialComment: resItem.specialComment,
            ssc: resItem.ssc,
            sscText: resItem.sscText,
          });
        } else if (result.data.action === 'NOTAVAILABLE') {
          let context = { ...data, items: [orderItem] };
          context = await this._command.handleCommand('NOTAVAILABLE', context);
          updatedItems.push(...context.items);
        }
      } else {
        return data;
      }
    }

    return { ...data, items: updatedItems };
  }

  createToast(comment?: string) {
    switch (comment) {
      case 'Artikel unverkäuflich, (und physisch in der Filiale vorhanden)':
      case 'Falscher Titel geliefert (richtiges Etikett)':
        break;
      default:
        this._toastService.open({
          message: 'Artikel wurde nachbestellt',
        });
    }
  }

  async reorder(orderItem: OrderItemListItemDTO, availability: AvailabilityDTO2, comment: string) {
    return await this._domainCheckoutService
      .reorder(orderItem.orderId, orderItem.orderItemId, orderItem.orderItemSubsetId, {
        quantity: orderItem.quantity,
        comment,
        availability: {
          availabilityType: availability.status,
          ssc: availability.ssc,
          sscText: availability.sscText,
          supplier: {
            id: availability.supplierId,
          },
          isPrebooked: availability.isPrebooked,
          estimatedShippingDate: availability.at,
          price: availability.price,
          inStock: availability.qty,
        },
      })
      .toPromise();
  }
}
