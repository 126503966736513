/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CatConfiguration as __Configuration } from '../cat-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfItemDTO } from '../models/list-response-args-of-item-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ListResponseArgsOfAutocompleteDTO } from '../models/list-response-args-of-autocomplete-dto';
import { AutocompleteTokenDTO } from '../models/autocomplete-token-dto';
import { ResponseArgsOfItemDTO } from '../models/response-args-of-item-dto';
import { ResponseArgsOfUISettingsDTO } from '../models/response-args-of-uisettings-dto';
import { ResponseArgsOfIEnumerableOfInputGroupDTO } from '../models/response-args-of-ienumerable-of-input-group-dto';
import { ResponseArgsOfIEnumerableOfOrderByDTO } from '../models/response-args-of-ienumerable-of-order-by-dto';
import { ResponseArgsOfIEnumerableOfQueryTokenDTO } from '../models/response-args-of-ienumerable-of-query-token-dto';
import { ResponseArgsOfIEnumerableOfItemDTO } from '../models/response-args-of-ienumerable-of-item-dto';
@Injectable({
  providedIn: 'root',
})
class SearchService extends __BaseService {
  static readonly SearchTopPath = '/s/top';
  static readonly SearchTop2Path = '/stock/{stockId}/s/top';
  static readonly SearchSearchPath = '/s';
  static readonly SearchSearch2Path = '/stock/{stockId}/s';
  static readonly SearchAutocompletePath = '/s/complete';
  static readonly SearchAutocomplete2Path = '/stock/{stockId}/s/complete';
  static readonly SearchByIdPath = '/s/byid';
  static readonly SearchById2Path = '/stock/{stockId}/s/byid';
  static readonly SearchByEANPath = '/s/byean';
  static readonly SearchByEAN2Path = '/stock/{stockId}/s/byean';
  static readonly SearchByEAN3Path = '/branch/{branchNumber}/s/byean';
  static readonly SearchDetailPath = '/s/{id}';
  static readonly SearchDetail2Path = '/stock/{stockId}/s/{id}';
  static readonly SearchDetailByEANPath = '/s/ean/{ean}';
  static readonly SearchDetailByEAN2Path = '/stock/{stockId}/ean/{ean}';
  static readonly SearchSettingsPath = '/s/settings';
  static readonly SearchSearchFilterPath = '/s/filter';
  static readonly SearchSearchSortPath = '/s/sort';
  static readonly SearchHistoryPath = '/s/history';
  static readonly SearchGetRecommendationsPath = '/s/recommendations/{digId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * TOP-Suche
   * @param params The `SearchService.SearchTopParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchkriterien
   */
  SearchTopResponse(params: SearchService.SearchTopParams): __Observable<__StrictHttpResponse<ListResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryToken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/s/top`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * TOP-Suche
   * @param params The `SearchService.SearchTopParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchkriterien
   */
  SearchTop(params: SearchService.SearchTopParams): __Observable<ListResponseArgsOfItemDTO> {
    return this.SearchTopResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfItemDTO)
    );
  }

  /**
   * TOP-Suche
   * @param params The `SearchService.SearchTop2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchkriterien
   */
  SearchTop2Response(params: SearchService.SearchTop2Params): __Observable<__StrictHttpResponse<ListResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryToken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stock/${encodeURIComponent(String(params.stockId))}/s/top`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * TOP-Suche
   * @param params The `SearchService.SearchTop2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchkriterien
   */
  SearchTop2(params: SearchService.SearchTop2Params): __Observable<ListResponseArgsOfItemDTO> {
    return this.SearchTop2Response(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfItemDTO)
    );
  }

  /**
   * Suche
   * @param params The `SearchService.SearchSearchParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchkriterien
   */
  SearchSearchResponse(params: SearchService.SearchSearchParams): __Observable<__StrictHttpResponse<ListResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryToken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Suche
   * @param params The `SearchService.SearchSearchParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchkriterien
   */
  SearchSearch(params: SearchService.SearchSearchParams): __Observable<ListResponseArgsOfItemDTO> {
    return this.SearchSearchResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfItemDTO)
    );
  }

  /**
   * Suche
   * @param params The `SearchService.SearchSearch2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchkriterien
   */
  SearchSearch2Response(params: SearchService.SearchSearch2Params): __Observable<__StrictHttpResponse<ListResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryToken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stock/${encodeURIComponent(String(params.stockId))}/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Suche
   * @param params The `SearchService.SearchSearch2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchkriterien
   */
  SearchSearch2(params: SearchService.SearchSearch2Params): __Observable<ListResponseArgsOfItemDTO> {
    return this.SearchSearch2Response(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfItemDTO)
    );
  }

  /**
   * Autocomplete
   * @param params The `SearchService.SearchAutocompleteParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchbegriff
   */
  SearchAutocompleteResponse(params: SearchService.SearchAutocompleteParams): __Observable<__StrictHttpResponse<ListResponseArgsOfAutocompleteDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryToken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/s/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfAutocompleteDTO>;
      })
    );
  }
  /**
   * Autocomplete
   * @param params The `SearchService.SearchAutocompleteParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchbegriff
   */
  SearchAutocomplete(params: SearchService.SearchAutocompleteParams): __Observable<ListResponseArgsOfAutocompleteDTO> {
    return this.SearchAutocompleteResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfAutocompleteDTO)
    );
  }

  /**
   * Autocomplete
   * @param params The `SearchService.SearchAutocomplete2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchbegriff
   */
  SearchAutocomplete2Response(params: SearchService.SearchAutocomplete2Params): __Observable<__StrictHttpResponse<ListResponseArgsOfAutocompleteDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryToken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stock/${encodeURIComponent(String(params.stockId))}/s/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfAutocompleteDTO>;
      })
    );
  }
  /**
   * Autocomplete
   * @param params The `SearchService.SearchAutocomplete2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `queryToken`: Suchbegriff
   */
  SearchAutocomplete2(params: SearchService.SearchAutocomplete2Params): __Observable<ListResponseArgsOfAutocompleteDTO> {
    return this.SearchAutocomplete2Response(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfAutocompleteDTO)
    );
  }

  /**
   * Suche nach IDs
   * @param params The `SearchService.SearchByIdParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `ids`: PKs
   */
  SearchByIdResponse(params: SearchService.SearchByIdParams): __Observable<__StrictHttpResponse<ListResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.ids;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/s/byid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Suche nach IDs
   * @param params The `SearchService.SearchByIdParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `ids`: PKs
   */
  SearchById(params: SearchService.SearchByIdParams): __Observable<ListResponseArgsOfItemDTO> {
    return this.SearchByIdResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfItemDTO)
    );
  }

  /**
   * Suche nach IDs
   * @param params The `SearchService.SearchById2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `ids`: PKs
   */
  SearchById2Response(params: SearchService.SearchById2Params): __Observable<__StrictHttpResponse<ListResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.ids;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stock/${encodeURIComponent(String(params.stockId))}/s/byid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Suche nach IDs
   * @param params The `SearchService.SearchById2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `ids`: PKs
   */
  SearchById2(params: SearchService.SearchById2Params): __Observable<ListResponseArgsOfItemDTO> {
    return this.SearchById2Response(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfItemDTO)
    );
  }

  /**
   * Suche nach EAN
   * @param params The `SearchService.SearchByEANParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `eans`: EANs
   *
   * - `branchNumber`: Filiale-Nr (optional)
   */
  SearchByEANResponse(params: SearchService.SearchByEANParams): __Observable<__StrictHttpResponse<ListResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.eans;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/s/byean`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Suche nach EAN
   * @param params The `SearchService.SearchByEANParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `eans`: EANs
   *
   * - `branchNumber`: Filiale-Nr (optional)
   */
  SearchByEAN(params: SearchService.SearchByEANParams): __Observable<ListResponseArgsOfItemDTO> {
    return this.SearchByEANResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfItemDTO)
    );
  }

  /**
   * Suche nach EAN
   * @param params The `SearchService.SearchByEAN2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `eans`: EANs
   *
   * - `branchNumber`: Filiale-Nr (optional)
   */
  SearchByEAN2Response(params: SearchService.SearchByEAN2Params): __Observable<__StrictHttpResponse<ListResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.eans;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stock/${encodeURIComponent(String(params.stockId))}/s/byean`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Suche nach EAN
   * @param params The `SearchService.SearchByEAN2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `eans`: EANs
   *
   * - `branchNumber`: Filiale-Nr (optional)
   */
  SearchByEAN2(params: SearchService.SearchByEAN2Params): __Observable<ListResponseArgsOfItemDTO> {
    return this.SearchByEAN2Response(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfItemDTO)
    );
  }

  /**
   * Suche nach EAN
   * @param params The `SearchService.SearchByEAN3Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `eans`: EANs
   *
   * - `branchNumber`: Filiale-Nr (optional)
   */
  SearchByEAN3Response(params: SearchService.SearchByEAN3Params): __Observable<__StrictHttpResponse<ListResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.eans;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/branch/${encodeURIComponent(String(params.branchNumber))}/s/byean`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Suche nach EAN
   * @param params The `SearchService.SearchByEAN3Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `eans`: EANs
   *
   * - `branchNumber`: Filiale-Nr (optional)
   */
  SearchByEAN3(params: SearchService.SearchByEAN3Params): __Observable<ListResponseArgsOfItemDTO> {
    return this.SearchByEAN3Response(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfItemDTO)
    );
  }

  /**
   * Detail
   * @param params The `SearchService.SearchDetailParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `id`: PK
   *
   * - `doNotTrack`:
   */
  SearchDetailResponse(params: SearchService.SearchDetailParams): __Observable<__StrictHttpResponse<ResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.doNotTrack != null) __params = __params.set('doNotTrack', params.doNotTrack.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/s/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Detail
   * @param params The `SearchService.SearchDetailParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `id`: PK
   *
   * - `doNotTrack`:
   */
  SearchDetail(params: SearchService.SearchDetailParams): __Observable<ResponseArgsOfItemDTO> {
    return this.SearchDetailResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfItemDTO)
    );
  }

  /**
   * Detail
   * @param params The `SearchService.SearchDetail2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `id`: PK
   *
   * - `doNotTrack`:
   */
  SearchDetail2Response(params: SearchService.SearchDetail2Params): __Observable<__StrictHttpResponse<ResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.doNotTrack != null) __params = __params.set('doNotTrack', params.doNotTrack.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stock/${encodeURIComponent(String(params.stockId))}/s/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Detail
   * @param params The `SearchService.SearchDetail2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `id`: PK
   *
   * - `doNotTrack`:
   */
  SearchDetail2(params: SearchService.SearchDetail2Params): __Observable<ResponseArgsOfItemDTO> {
    return this.SearchDetail2Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfItemDTO)
    );
  }

  /**
   * Detail by EAN
   * @param params The `SearchService.SearchDetailByEANParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `ean`: EAN
   */
  SearchDetailByEANResponse(params: SearchService.SearchDetailByEANParams): __Observable<__StrictHttpResponse<ResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/s/ean/${encodeURIComponent(String(params.ean))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Detail by EAN
   * @param params The `SearchService.SearchDetailByEANParams` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `ean`: EAN
   */
  SearchDetailByEAN(params: SearchService.SearchDetailByEANParams): __Observable<ResponseArgsOfItemDTO> {
    return this.SearchDetailByEANResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfItemDTO)
    );
  }

  /**
   * Detail by EAN
   * @param params The `SearchService.SearchDetailByEAN2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `ean`: EAN
   */
  SearchDetailByEAN2Response(params: SearchService.SearchDetailByEAN2Params): __Observable<__StrictHttpResponse<ResponseArgsOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stock/${encodeURIComponent(String(params.stockId))}/ean/${encodeURIComponent(String(params.ean))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfItemDTO>;
      })
    );
  }
  /**
   * Detail by EAN
   * @param params The `SearchService.SearchDetailByEAN2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK (optional)
   *
   * - `ean`: EAN
   */
  SearchDetailByEAN2(params: SearchService.SearchDetailByEAN2Params): __Observable<ResponseArgsOfItemDTO> {
    return this.SearchDetailByEAN2Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfItemDTO)
    );
  }

  /**
   * Settings
   */
  SearchSettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfUISettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/s/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfUISettingsDTO>;
      })
    );
  }
  /**
   * Settings
   */
  SearchSettings(): __Observable<ResponseArgsOfUISettingsDTO> {
    return this.SearchSettingsResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfUISettingsDTO)
    );
  }

  /**
   * Filter
   */
  SearchSearchFilterResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfInputGroupDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/s/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfInputGroupDTO>;
      })
    );
  }
  /**
   * Filter
   */
  SearchSearchFilter(): __Observable<ResponseArgsOfIEnumerableOfInputGroupDTO> {
    return this.SearchSearchFilterResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfInputGroupDTO)
    );
  }

  /**
   * Filter
   */
  SearchSearchSortResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderByDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/s/sort`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderByDTO>;
      })
    );
  }
  /**
   * Filter
   */
  SearchSearchSort(): __Observable<ResponseArgsOfIEnumerableOfOrderByDTO> {
    return this.SearchSearchSortResponse().pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfOrderByDTO)
    );
  }

  /**
   * Suchhistorie
   * @param take Take
   */
  SearchHistoryResponse(take?: null | number): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfQueryTokenDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (take != null) __params = __params.set('take', take.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/s/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfQueryTokenDTO>;
      })
    );
  }
  /**
   * Suchhistorie
   * @param take Take
   */
  SearchHistory(take?: null | number): __Observable<ResponseArgsOfIEnumerableOfQueryTokenDTO> {
    return this.SearchHistoryResponse(take).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfQueryTokenDTO)
    );
  }

  /**
   * Get Recommendation
   * @param params The `SearchService.SearchGetRecommendationsParams` containing the following parameters:
   *
   * - `digId`:
   *
   * - `sessionId`: Process Id
   *
   * @return ResponseArgs of Recomendations
   */
  SearchGetRecommendationsResponse(params: SearchService.SearchGetRecommendationsParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sessionId != null) __params = __params.set('sessionId', params.sessionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/s/recommendations/${encodeURIComponent(String(params.digId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfItemDTO>;
      })
    );
  }
  /**
   * Get Recommendation
   * @param params The `SearchService.SearchGetRecommendationsParams` containing the following parameters:
   *
   * - `digId`:
   *
   * - `sessionId`: Process Id
   *
   * @return ResponseArgs of Recomendations
   */
  SearchGetRecommendations(params: SearchService.SearchGetRecommendationsParams): __Observable<ResponseArgsOfIEnumerableOfItemDTO> {
    return this.SearchGetRecommendationsResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfItemDTO)
    );
  }
}

module SearchService {

  /**
   * Parameters for SearchTop
   */
  export interface SearchTopParams {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * Suchkriterien
     */
    queryToken: QueryTokenDTO;
  }

  /**
   * Parameters for SearchTop2
   */
  export interface SearchTop2Params {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * Suchkriterien
     */
    queryToken: QueryTokenDTO;
  }

  /**
   * Parameters for SearchSearch
   */
  export interface SearchSearchParams {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * Suchkriterien
     */
    queryToken: QueryTokenDTO;
  }

  /**
   * Parameters for SearchSearch2
   */
  export interface SearchSearch2Params {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * Suchkriterien
     */
    queryToken: QueryTokenDTO;
  }

  /**
   * Parameters for SearchAutocomplete
   */
  export interface SearchAutocompleteParams {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * Suchbegriff
     */
    queryToken: AutocompleteTokenDTO;
  }

  /**
   * Parameters for SearchAutocomplete2
   */
  export interface SearchAutocomplete2Params {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * Suchbegriff
     */
    queryToken: AutocompleteTokenDTO;
  }

  /**
   * Parameters for SearchById
   */
  export interface SearchByIdParams {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * PKs
     */
    ids: Array<number>;
  }

  /**
   * Parameters for SearchById2
   */
  export interface SearchById2Params {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * PKs
     */
    ids: Array<number>;
  }

  /**
   * Parameters for SearchByEAN
   */
  export interface SearchByEANParams {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * EANs
     */
    eans: Array<string>;

    /**
     * Filiale-Nr (optional)
     */
    branchNumber: null | string;
  }

  /**
   * Parameters for SearchByEAN2
   */
  export interface SearchByEAN2Params {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * EANs
     */
    eans: Array<string>;

    /**
     * Filiale-Nr (optional)
     */
    branchNumber: null | string;
  }

  /**
   * Parameters for SearchByEAN3
   */
  export interface SearchByEAN3Params {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * EANs
     */
    eans: Array<string>;

    /**
     * Filiale-Nr (optional)
     */
    branchNumber: null | string;
  }

  /**
   * Parameters for SearchDetail
   */
  export interface SearchDetailParams {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * PK
     */
    id: number;
    doNotTrack?: boolean;
  }

  /**
   * Parameters for SearchDetail2
   */
  export interface SearchDetail2Params {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * PK
     */
    id: number;
    doNotTrack?: boolean;
  }

  /**
   * Parameters for SearchDetailByEAN
   */
  export interface SearchDetailByEANParams {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * EAN
     */
    ean: null | string;
  }

  /**
   * Parameters for SearchDetailByEAN2
   */
  export interface SearchDetailByEAN2Params {

    /**
     * Lager PK (optional)
     */
    stockId: null | number;

    /**
     * EAN
     */
    ean: null | string;
  }

  /**
   * Parameters for SearchGetRecommendations
   */
  export interface SearchGetRecommendationsParams {
    digId: null | string;

    /**
     * Process Id
     */
    sessionId?: null | string;
  }
}

export { SearchService }
