import { CustomerDTO, Gender } from '@swagger/crm';

export interface CreateCustomerQueryParams {
  p4mNumber?: string;
  customerId?: number;
  gender?: Gender;
  title?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  info?: string;
  organisationName?: string;
  organisationDepartment?: string;
  organisationVatId?: string;
}
