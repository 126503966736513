/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OmsConfiguration as __Configuration } from '../oms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString } from '../models/response-args-of-value-tuple-of-ienumerable-of-display-order-dtoand-ienumerable-of-key-value-dtoof-string-and-string';
import { KulturPassOrderValues } from '../models/kultur-pass-order-values';
import { ResponseArgsOfIEnumerableOfDisplayOrderDTO } from '../models/response-args-of-ienumerable-of-display-order-dto';
import { ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO } from '../models/response-args-of-value-tuple-of-order-item-subset-dtoand-order-item-subset-dto';
import { ReorderValues } from '../models/reorder-values';
import { ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString } from '../models/response-args-of-ienumerable-of-key-value-dtoof-string-and-string';
import { ResponseArgsOfSupplierOrderResult } from '../models/response-args-of-supplier-order-result';
@Injectable({
  providedIn: 'root',
})
class OrderCheckoutService extends __BaseService {
  static readonly OrderCheckoutCreateKulturPassOrderPath = '/order/kulturpass';
  static readonly OrderCheckoutCreateOrderPOSTPath = '/order/checkout/{checkoutId}';
  static readonly OrderCheckoutCreateOrderPUTPath = '/order/checkout/{checkoutId}';
  static readonly OrderCheckoutReorderPath = '/order/{orderId}/orderitem/{orderItemId}/orderitemsubset/{orderItemSubsetId}/reorder';
  static readonly OrderCheckoutGetReorderReasonsPath = '/order/reorder/reasons';
  static readonly OrderCheckoutOrderAtSupplierPath = '/order/{orderId}';
  static readonly OrderCheckoutOrderSubsetItemAtSupplierPath = '/order/{orderId}/orderitem/{orderItemId}/orderitemsubset/{orderItemSubsetId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * KulturPass-Filialentnahme
   * @param params The `OrderCheckoutService.OrderCheckoutCreateKulturPassOrderParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `locale`:
   *
   * @return  or
   */
  OrderCheckoutCreateKulturPassOrderResponse(params: OrderCheckoutService.OrderCheckoutCreateKulturPassOrderParams): __Observable<__StrictHttpResponse<ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString | ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/kulturpass`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString | ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString>;
      })
    );
  }
  /**
   * KulturPass-Filialentnahme
   * @param params The `OrderCheckoutService.OrderCheckoutCreateKulturPassOrderParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `locale`:
   *
   * @return  or
   */
  OrderCheckoutCreateKulturPassOrder(params: OrderCheckoutService.OrderCheckoutCreateKulturPassOrderParams): __Observable<ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString | ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString> {
    return this.OrderCheckoutCreateKulturPassOrderResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString | ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString)
    );
  }

  /**
   * @param params The `OrderCheckoutService.OrderCheckoutCreateOrderPOSTParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   *
   * @return  or
   */
  OrderCheckoutCreateOrderPOSTResponse(params: OrderCheckoutService.OrderCheckoutCreateOrderPOSTParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfDisplayOrderDTO | ResponseArgsOfIEnumerableOfDisplayOrderDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/checkout/${encodeURIComponent(String(params.checkoutId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfDisplayOrderDTO | ResponseArgsOfIEnumerableOfDisplayOrderDTO>;
      })
    );
  }
  /**
   * @param params The `OrderCheckoutService.OrderCheckoutCreateOrderPOSTParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   *
   * @return  or
   */
  OrderCheckoutCreateOrderPOST(params: OrderCheckoutService.OrderCheckoutCreateOrderPOSTParams): __Observable<ResponseArgsOfIEnumerableOfDisplayOrderDTO | ResponseArgsOfIEnumerableOfDisplayOrderDTO> {
    return this.OrderCheckoutCreateOrderPOSTResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfDisplayOrderDTO | ResponseArgsOfIEnumerableOfDisplayOrderDTO)
    );
  }

  /**
   * @param params The `OrderCheckoutService.OrderCheckoutCreateOrderPUTParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   *
   * @return  or
   */
  OrderCheckoutCreateOrderPUTResponse(params: OrderCheckoutService.OrderCheckoutCreateOrderPUTParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfDisplayOrderDTO | ResponseArgsOfIEnumerableOfDisplayOrderDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/order/checkout/${encodeURIComponent(String(params.checkoutId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfDisplayOrderDTO | ResponseArgsOfIEnumerableOfDisplayOrderDTO>;
      })
    );
  }
  /**
   * @param params The `OrderCheckoutService.OrderCheckoutCreateOrderPUTParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   *
   * @return  or
   */
  OrderCheckoutCreateOrderPUT(params: OrderCheckoutService.OrderCheckoutCreateOrderPUTParams): __Observable<ResponseArgsOfIEnumerableOfDisplayOrderDTO | ResponseArgsOfIEnumerableOfDisplayOrderDTO> {
    return this.OrderCheckoutCreateOrderPUTResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfDisplayOrderDTO | ResponseArgsOfIEnumerableOfDisplayOrderDTO)
    );
  }

  /**
   * Bestellposten erneut bestellen
   * Das aktuelle OrderItemSubset wird auf Storniert (Händler) gesetzt und ein neues OrderItemSubset erzeugt
   * @param params The `OrderCheckoutService.OrderCheckoutReorderParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderId`: PK Bestellung
   *
   * - `data`: Daten zur Änderung des Bearbeitungsstatus
   */
  OrderCheckoutReorderResponse(params: OrderCheckoutService.OrderCheckoutReorderParams): __Observable<__StrictHttpResponse<ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(params.orderId))}/orderitem/${encodeURIComponent(String(params.orderItemId))}/orderitemsubset/${encodeURIComponent(String(params.orderItemSubsetId))}/reorder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO>;
      })
    );
  }
  /**
   * Bestellposten erneut bestellen
   * Das aktuelle OrderItemSubset wird auf Storniert (Händler) gesetzt und ein neues OrderItemSubset erzeugt
   * @param params The `OrderCheckoutService.OrderCheckoutReorderParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderId`: PK Bestellung
   *
   * - `data`: Daten zur Änderung des Bearbeitungsstatus
   */
  OrderCheckoutReorder(params: OrderCheckoutService.OrderCheckoutReorderParams): __Observable<ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO> {
    return this.OrderCheckoutReorderResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO)
    );
  }

  /**
   * Nachbestellgründe
   * @param locale Lokalisierung
   */
  OrderCheckoutGetReorderReasonsResponse(locale?: null | string): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/reorder/reasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString>;
      })
    );
  }
  /**
   * Nachbestellgründe
   * @param locale Lokalisierung
   */
  OrderCheckoutGetReorderReasons(locale?: null | string): __Observable<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString> {
    return this.OrderCheckoutGetReorderReasonsResponse(locale).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString)
    );
  }

  /**
   * Für alle noch nicht beim Lieferanten bestellen Posten der Bestellung wird einer Lieferantenbestellung erzeugt
   * @param orderId Bestellung PK
   * @return  or
   */
  OrderCheckoutOrderAtSupplierResponse(orderId: number): __Observable<__StrictHttpResponse<ResponseArgsOfSupplierOrderResult | ResponseArgsOfSupplierOrderResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(orderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfSupplierOrderResult | ResponseArgsOfSupplierOrderResult>;
      })
    );
  }
  /**
   * Für alle noch nicht beim Lieferanten bestellen Posten der Bestellung wird einer Lieferantenbestellung erzeugt
   * @param orderId Bestellung PK
   * @return  or
   */
  OrderCheckoutOrderAtSupplier(orderId: number): __Observable<ResponseArgsOfSupplierOrderResult | ResponseArgsOfSupplierOrderResult> {
    return this.OrderCheckoutOrderAtSupplierResponse(orderId).pipe(
      __map(_r => _r.body as ResponseArgsOfSupplierOrderResult | ResponseArgsOfSupplierOrderResult)
    );
  }

  /**
   * Lieferantenbestellung für eine Bestellpostenteilmenge erzeugen
   * @param params The `OrderCheckoutService.OrderCheckoutOrderSubsetItemAtSupplierParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: Bestellpostenteilmenge PK
   *
   * - `orderItemId`: Bestellposten PK
   *
   * - `orderId`: Bestellung PK
   *
   * @return  or
   */
  OrderCheckoutOrderSubsetItemAtSupplierResponse(params: OrderCheckoutService.OrderCheckoutOrderSubsetItemAtSupplierParams): __Observable<__StrictHttpResponse<ResponseArgsOfSupplierOrderResult | ResponseArgsOfSupplierOrderResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(params.orderId))}/orderitem/${encodeURIComponent(String(params.orderItemId))}/orderitemsubset/${encodeURIComponent(String(params.orderItemSubsetId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfSupplierOrderResult | ResponseArgsOfSupplierOrderResult>;
      })
    );
  }
  /**
   * Lieferantenbestellung für eine Bestellpostenteilmenge erzeugen
   * @param params The `OrderCheckoutService.OrderCheckoutOrderSubsetItemAtSupplierParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: Bestellpostenteilmenge PK
   *
   * - `orderItemId`: Bestellposten PK
   *
   * - `orderId`: Bestellung PK
   *
   * @return  or
   */
  OrderCheckoutOrderSubsetItemAtSupplier(params: OrderCheckoutService.OrderCheckoutOrderSubsetItemAtSupplierParams): __Observable<ResponseArgsOfSupplierOrderResult | ResponseArgsOfSupplierOrderResult> {
    return this.OrderCheckoutOrderSubsetItemAtSupplierResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfSupplierOrderResult | ResponseArgsOfSupplierOrderResult)
    );
  }
}

module OrderCheckoutService {

  /**
   * Parameters for OrderCheckoutCreateKulturPassOrder
   */
  export interface OrderCheckoutCreateKulturPassOrderParams {
    payload: KulturPassOrderValues;
    locale?: null | string;
  }

  /**
   * Parameters for OrderCheckoutCreateOrderPOST
   */
  export interface OrderCheckoutCreateOrderPOSTParams {
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for OrderCheckoutCreateOrderPUT
   */
  export interface OrderCheckoutCreateOrderPUTParams {
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for OrderCheckoutReorder
   */
  export interface OrderCheckoutReorderParams {

    /**
     * PK Bestellpostenteilmenge
     */
    orderItemSubsetId: number;

    /**
     * PK Bestellposten
     */
    orderItemId: number;

    /**
     * PK Bestellung
     */
    orderId: number;

    /**
     * Daten zur Änderung des Bearbeitungsstatus
     */
    data: ReorderValues;
  }

  /**
   * Parameters for OrderCheckoutOrderSubsetItemAtSupplier
   */
  export interface OrderCheckoutOrderSubsetItemAtSupplierParams {

    /**
     * Bestellpostenteilmenge PK
     */
    orderItemSubsetId: number;

    /**
     * Bestellposten PK
     */
    orderItemId: number;

    /**
     * Bestellung PK
     */
    orderId: number;
  }
}

export { OrderCheckoutService }
