import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFilterComponent } from './ui-filter.component';
import { UiInputGroupSelectorPipe } from './pipe';
import { UiFilterInputGroupMainModule } from './filter-group/filter-input-group-main';
import { UiFilterFilterGroupMainModule } from './filter-group/filter-filter-group-main';
import { UiFilterFilterGroupFilterModule } from './filter-group/filter-filter-group-filter';
import { UiFilterInputModule } from './shared/filter-input';

@NgModule({
  imports: [
    CommonModule,
    UiFilterInputGroupMainModule,
    UiFilterFilterGroupMainModule,
    UiFilterFilterGroupFilterModule,
    UiFilterInputModule,
  ],
  exports: [
    UiFilterComponent,
    UiInputGroupSelectorPipe,
    UiFilterInputGroupMainModule,
    UiFilterFilterGroupMainModule,
    UiFilterFilterGroupFilterModule,
    UiFilterInputModule,
  ],
  declarations: [UiFilterComponent, UiInputGroupSelectorPipe],
})
export class UiFilterNextModule {}
