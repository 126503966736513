import { Injectable } from '@angular/core';
import { NativeContainerService } from 'native-container';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ScanAdapter } from './scan-adapter';

@Injectable()
export class NativeScanAdapter implements ScanAdapter {
  readonly name = 'Native';

  constructor(private readonly nativeContainerService: NativeContainerService) {}

  init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      resolve(this.nativeContainerService.isNative);
    });
  }

  scan(): Observable<string> {
    return this.nativeContainerService.openScanner('scanBook').pipe(
      filter((result) => result.status === 'SUCCESS'),
      map((result) => result.data),
      take(1)
    );
  }
}
