import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationProcess, ApplicationService } from '@core/application';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CanActivateCartWithProcessIdGuard {
  constructor(private readonly _applicationService: ApplicationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const process = await this._applicationService
      .getProcessById$(+route.params.processId)
      .pipe(first())
      .toPromise();

    // if (!(process?.type === 'cart')) {
    //   // TODO:
    //   // Anderer Prozesstyp mit gleicher Id - Was soll gemacht werden?
    //   return false;
    // }

    if (!process) {
      const processes = await this._applicationService.getProcesses$('customer').pipe(first()).toPromise();
      await this._applicationService.createProcess({
        id: +route.params.processId,
        type: 'cart',
        section: 'customer',
        name: `Vorgang ${this.processNumber(processes)}`,
      });
    }

    this._applicationService.activateProcess(+route.params.processId);
    return true;
  }

  processNumber(processes: ApplicationProcess[]) {
    const processNumbers = processes?.map((process) => Number(process?.name?.replace(/\D/g, '')));
    return !!processNumbers && processNumbers?.length > 0 ? Math.max(...processNumbers) + 1 : 1;
  }
}
