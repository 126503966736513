import { Injectable } from '@angular/core';
import { DomainOmsService } from '../oms.service';
import { OrderItemListItemDTO, StatusValues } from '@swagger/oms';
import { ChangeOrderItemStatusBaseActionHandler } from './change-order-item-status-base.action-handler';
import { OrderItemsContext } from './order-items.context';

@Injectable()
export class ArrivedActionHandler extends ChangeOrderItemStatusBaseActionHandler {
  constructor(domainOmsService: DomainOmsService) {
    super(domainOmsService, 'ARRIVED');
  }

  getStatusValues(orderItem: OrderItemListItemDTO, context: OrderItemsContext): StatusValues {
    return {
      processingStatus: 128,
      compartmentInfo: context.compartmentInfo,
      compartmentNumber: context.compartmentCode,
      quantity: context.itemQuantity?.get(orderItem.orderItemSubsetId) || orderItem.quantity,
    };
  }
}
