import { Injectable } from '@angular/core';
import { UiModalRef, UiModalService } from '@ui/modal';
import { MessageModalData } from './message-modal.data';
import { MessageModalComponent } from './message-modal.component';

@Injectable({ providedIn: 'root' })
export class MessageModalService {
  constructor(private _modal: UiModalService) {}

  open(data: MessageModalData): UiModalRef<void, MessageModalComponent> {
    return this._modal.open({
      title: data.title,
      content: MessageModalComponent,
      data,
    });
  }
}
