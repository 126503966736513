import { ElementRef, TemplateRef } from '@angular/core';
import { UiOverlayPositionX, UiOverlayPositionY } from './overlay-positions';

export interface UiOverlayTrigger {
  templateRef: TemplateRef<any>;

  content: ElementRef;

  xPosition: UiOverlayPositionX;

  yPosition: UiOverlayPositionY;

  xOffset: number;

  yOffset: number;

  close: () => void;
}
