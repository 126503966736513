import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  TemplateRef,
  ContentChild,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UiOverlayPositionX, UiOverlayPositionY, UiOverlayTrigger } from '@ui/common';

@Component({
  selector: 'ui-quantity-dropdown-content',
  templateUrl: 'quantity-dropdown-content.component.html',
  styleUrls: ['quantity-dropdown-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityDropdownContentComponent implements OnChanges, UiOverlayTrigger {
  @ViewChild(TemplateRef)
  templateRef: TemplateRef<any>;

  @Input()
  quantity: number;

  @Input() range?: number = 999;
  rangeArray: Array<number>;

  @Input() showTrash = true;

  @ContentChild('content')
  content: ElementRef<any>;

  @Input()
  xPosition: UiOverlayPositionX;

  @Input()
  yPosition: UiOverlayPositionY;

  @Input()
  xOffset: number;

  @Input()
  yOffset: number;

  @Output()
  quantityChange = new EventEmitter<number>();

  @Output()
  enableCustomInput = new EventEmitter<void>();

  close = () => {};

  get classList() {
    return ['qp', `x-position-${this.xPosition}`, `y-position-${this.yPosition}`];
  }

  constructor() {}

  ngOnChanges({ range }: SimpleChanges): void {
    if (range?.currentValue && range.currentValue <= 9) {
      this.rangeArray = Array.from(Array(this.range), (_, i) => i + 1);
    } else {
      this.rangeArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    }
  }

  setQuantity(quantity: number) {
    this.quantity = quantity;
    this.quantityChange.emit(quantity);
  }
}
