export interface GeoLocation {
  altitude?: number;
  latitude?: number;
  longitude?: number;
}

export function geoDistance(startLocation: GeoLocation, endLocation: GeoLocation): number {
  if (!(!!startLocation && !!endLocation)) return 0;

  if (startLocation.latitude === endLocation.latitude && startLocation.longitude === endLocation.longitude) {
    return 0;
  } else {
    const radlat1 = (Math.PI * startLocation.latitude) / 180;
    const radlat2 = (Math.PI * endLocation.latitude) / 180;
    const theta = startLocation.longitude - endLocation.longitude;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    return dist;
  }
}
