import { Injectable } from '@angular/core';
import { IconLoader } from './icon-loader';
import { Config } from '@core/config';
import { HttpClient } from '@angular/common/http';
import { IconConfig } from '../interfaces';

@Injectable()
export class JsonIconLoader extends IconLoader {
  constructor(private readonly config: Config, private readonly http: HttpClient) {
    super();
  }

  getIcons(): Promise<IconConfig> {
    return this.http.get<IconConfig>(this.config.get('@shared/icon')).toPromise();
  }
}
