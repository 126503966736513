<ng-template>
  <div [class]="classList">
    <svg viewBox="0 25 50 25" class="triangle">
      <polygon points="0,50 50,50 25,25"></polygon>
    </svg>
    <div class="ui-dropdown-wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
