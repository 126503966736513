import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFilterFilterGroupFilterComponent } from './filter-filter-group-filter.component';
import { UiFilterInputOptionsModule } from '../../shared/filter-input-options';
import { UiIconModule } from '@ui/icon';
import { UiFilterInputModule } from '../../shared/filter-input';

@NgModule({
  imports: [CommonModule, UiIconModule, UiFilterInputOptionsModule, UiFilterInputModule],
  exports: [UiFilterFilterGroupFilterComponent],
  declarations: [UiFilterFilterGroupFilterComponent],
})
export class UiFilterFilterGroupFilterModule {}
