import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiCommonModule } from '@ui/common';
import { UiIconModule } from '@ui/icon';
import { ModalNotificationsListItemComponent } from './notifications-list-item/notifications-list-item.component';
import { ModalNotificationsRemissionGroupComponent } from './notifications-remission-group/notifications-remission-group.component';
import { ModalNotificationsReservationGroupComponent } from './notifications-reservation-group/notifications-reservation-group.component';
import { ModalNotificationsTaskCalendarGroupComponent } from './notifications-task-calendar-group/notifications-task-calendar-group.component';
import { ModalNotificationsUpdateGroupComponent } from './notifications-update-group/notifications-update-group.component';
import { ModalNotificationsComponent } from './notifications.component';
import { ModalNotificationsPackageInspectionGroupComponent } from './notifications-package-inspection-group/notifications-package-inspection-group.component';

@NgModule({
  imports: [CommonModule, UiCommonModule, UiIconModule, RouterModule],
  declarations: [
    ModalNotificationsComponent,
    ModalNotificationsReservationGroupComponent,
    ModalNotificationsRemissionGroupComponent,
    ModalNotificationsTaskCalendarGroupComponent,
    ModalNotificationsUpdateGroupComponent,
    ModalNotificationsListItemComponent,
    ModalNotificationsPackageInspectionGroupComponent,
  ],
  exports: [
    ModalNotificationsComponent,
    ModalNotificationsReservationGroupComponent,
    ModalNotificationsRemissionGroupComponent,
    ModalNotificationsTaskCalendarGroupComponent,
    ModalNotificationsUpdateGroupComponent,
    ModalNotificationsListItemComponent,
    ModalNotificationsPackageInspectionGroupComponent,
  ],
})
export class ModalNotificationsModule {}
