import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsletterFormBlockComponent } from './newsletter-form-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from '@shared/components/checkbox';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CheckboxComponent],
  exports: [NewsletterFormBlockComponent],
  declarations: [NewsletterFormBlockComponent],
})
export class NewsletterFormBlockModule {}
