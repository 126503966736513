import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AddToShoppingCartDTO,
  AvailabilityDTO,
  BranchDTO,
  BuyerDTO,
  BuyerResult,
  CheckoutDTO,
  CommunicationDetailsDTO,
  DestinationDTO,
  NotificationChannel,
  OLAAvailabilityDTO,
  PayerDTO,
  PaymentDTO,
  PaymentType,
  ResponseArgsOfDestinationDTO,
  ShippingAddressDTO,
  ShoppingCartDTO,
  StoreCheckoutService,
  UpdateShoppingCartItemDTO,
  InputDTO,
  ItemPayload,
  StoreCheckoutShoppingCartService,
  StoreCheckoutPaymentService,
  StoreCheckoutBuyerService,
  StoreCheckoutPayerService,
  StoreCheckoutBranchService,
  ItemsResult,
  KulturPassService,
  ProductDTO,
  KulturPassResult,
} from '@swagger/checkout';
import {
  DisplayOrderDTO,
  DisplayOrderItemDTO,
  OrderCheckoutService,
  ReorderValues,
  ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString,
} from '@swagger/oms';
import { isNullOrUndefined, memorize } from '@utils/common';
import { combineLatest, Observable, of, concat, isObservable, throwError, interval as rxjsInterval } from 'rxjs';
import {
  bufferCount,
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
  startWith,
} from 'rxjs/operators';

import * as DomainCheckoutSelectors from './store/domain-checkout.selectors';
import * as DomainCheckoutActions from './store/domain-checkout.actions';
import { DomainAvailabilityService, ItemData } from '@domain/availability';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationService } from '@core/application';
import { CustomerDTO } from '@swagger/crm';
import { Config } from '@core/config';
import parseDuration from 'parse-duration';

@Injectable()
export class DomainCheckoutService {
  get olaExpiration() {
    const exp = this._config.get('@domain/checkout.olaExpiration') ?? '5m';
    return parseDuration(exp);
  }

  constructor(
    private store: Store<any>,
    private _config: Config,
    private applicationService: ApplicationService,
    private storeCheckoutService: StoreCheckoutService,
    private orderCheckoutService: OrderCheckoutService,
    private availabilityService: DomainAvailabilityService,
    private _shoppingCartService: StoreCheckoutShoppingCartService,
    private _paymentService: StoreCheckoutPaymentService,
    private _buyerService: StoreCheckoutBuyerService,
    private _payerService: StoreCheckoutPayerService,
    private _branchService: StoreCheckoutBranchService,
    private _kulturpassService: KulturPassService
  ) {}

  //#region shoppingcart
  getShoppingCart({ processId, latest }: { processId: number; latest?: boolean }): Observable<ShoppingCartDTO> {
    let _latest = latest;
    return this.store.select(DomainCheckoutSelectors.selectShoppingCartByProcessId, { processId }).pipe(
      filter((cart) => {
        if (isNullOrUndefined(cart)) {
          this.createShoppingCart({ processId }).subscribe();
          return false;
        } else if (cart && _latest) {
          _latest = false;
          this._shoppingCartService
            .StoreCheckoutShoppingCartGetShoppingCart({
              shoppingCartId: cart.id,
            })
            .pipe(
              map((response) => response.result),
              tap((shoppingCart) =>
                this.store.dispatch(
                  DomainCheckoutActions.setShoppingCart({
                    processId,
                    shoppingCart,
                  })
                )
              )
            )
            .subscribe();
          return false;
        }
        return true;
      })
    );
  }

  createShoppingCart({ processId }: { processId: number }): Observable<ShoppingCartDTO> {
    return this._shoppingCartService.StoreCheckoutShoppingCartCreateShoppingCart().pipe(
      map((response) => response.result),
      tap((shoppingCart) =>
        this.store.dispatch(
          DomainCheckoutActions.setShoppingCart({
            processId,
            shoppingCart,
          })
        )
      )
    );
  }

  addItemToShoppingCart({ processId, items }: { processId: number; items: AddToShoppingCartDTO[] }): Observable<ShoppingCartDTO> {
    return this.getShoppingCart({ processId }).pipe(
      first(),
      mergeMap((cart) =>
        this._shoppingCartService
          .StoreCheckoutShoppingCartAddItemToShoppingCart({
            items,
            shoppingCartId: cart.id,
          })
          .pipe(
            map((response) => response.result),
            tap((shoppingCart) => {
              this.store.dispatch(
                DomainCheckoutActions.setShoppingCart({
                  processId,
                  shoppingCart,
                })
              );
            }),
            tap((shoppingCart) => this.updateProcessCount(processId, shoppingCart?.items?.length))
          )
      )
    );
  }

  setDestinationForCustomer({
    processId,
    customerFeatures,
  }: {
    processId: number;
    customerFeatures: { [key: string]: string };
  }): Observable<BuyerResult> {
    return this.getShoppingCart({ processId }).pipe(
      first(),
      mergeMap((shoppingCart) =>
        this._shoppingCartService
          .StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyer({
            shoppingCartId: shoppingCart?.id,
            payload: { customerFeatures },
          })
          .pipe(map((response) => response.result))
      )
    );
  }

  canAddDestination({ processId, destinationDTO }: { processId: number; destinationDTO: DestinationDTO }) {
    return this.getShoppingCart({ processId }).pipe(
      first(),
      mergeMap((cart) =>
        this._shoppingCartService.StoreCheckoutShoppingCartCanAddDestination({
          shoppingCartId: cart.id,
          payload: destinationDTO,
        })
      ),
      map((response) => {
        if (response.result?.ok) {
          return true;
        }
        return Object.values(response.invalidProperties).join('; ') || response.message;
      })
    );
  }

  canAddItem({
    processId,
    availability,
    orderType,
  }: {
    processId: number;
    availability: OLAAvailabilityDTO;
    orderType: string;
  }): Observable<true | string> {
    return this.getShoppingCart({ processId }).pipe(
      first(),
      withLatestFrom(this.store.select(DomainCheckoutSelectors.selectCustomerFeaturesByProcessId, { processId })),
      mergeMap(([shoppingCart, customerFeatures]) =>
        this._shoppingCartService
          .StoreCheckoutShoppingCartCanAddItem({
            shoppingCartId: shoppingCart?.id,
            payload: {
              customerFeatures,
              availabilities: [availability],
              orderType,
            },
          })
          .pipe(
            map((response) => {
              if (response.result.ok) {
                return true;
              }
              return response.message;
            })
          )
      )
    );
  }

  canAddItemsKulturpass(payload: ProductDTO[]): Observable<KulturPassResult[]> {
    return this._kulturpassService.KulturPassCanAddForKulturPass({ payload }).pipe(map((response) => response?.result));
  }

  canAddItems({
    processId,
    payload,
    orderType,
  }: {
    processId: number;
    payload: ItemPayload[];
    orderType: string;
  }): Observable<ItemsResult[]> {
    return this.getShoppingCart({ processId }).pipe(
      first(),
      withLatestFrom(this.store.select(DomainCheckoutSelectors.selectCustomerFeaturesByProcessId, { processId })),
      mergeMap(([shoppingCart, customerFeatures]) => {
        payload = payload?.map((p) => {
          return {
            ...p,
            customerFeatures,
            orderType,
          };
        });
        return this._shoppingCartService
          .StoreCheckoutShoppingCartCanAddItems({
            shoppingCartId: shoppingCart.id,
            payload,
          })
          .pipe(
            map((response) => {
              // TODO: remove this when the API is fixed
              return (response.result as unknown) as ItemsResult[];
            })
          );
      })
    );
  }

  updateShoppingCartItemAvailability({
    shoppingCartId,
    shoppingCartItemId,
    availability,
  }: {
    shoppingCartId: number;
    shoppingCartItemId: number;
    availability: AvailabilityDTO;
  }) {
    return this._shoppingCartService
      .StoreCheckoutShoppingCartUpdateShoppingCartItemAvailability({
        shoppingCartId,
        shoppingCartItemId,
        availability,
      })
      .pipe(
        map((response) => response.result),
        tap((shoppingCart) => {
          this.store.dispatch(
            DomainCheckoutActions.addShoppingCartItemAvailabilityToHistoryByShoppingCartId({
              shoppingCartId,
              availability,
              shoppingCartItemId,
            })
          );
        })
      );
  }

  updateItemInShoppingCart({
    processId,
    shoppingCartItemId,
    update,
  }: {
    processId: number;
    shoppingCartItemId: number;
    update: UpdateShoppingCartItemDTO;
  }): Observable<ShoppingCartDTO> {
    return this.getShoppingCart({ processId, latest: true }).pipe(
      first(),
      mergeMap((shoppingCart) =>
        this._shoppingCartService
          .StoreCheckoutShoppingCartUpdateShoppingCartItem({
            shoppingCartId: shoppingCart.id,
            shoppingCartItemId,
            values: update,
          })
          .pipe(
            map((response) => response.result),
            tap((shoppingCart) => {
              this.store.dispatch(DomainCheckoutActions.setShoppingCart({ processId, shoppingCart }));

              if (update.availability) {
                this.store.dispatch(
                  DomainCheckoutActions.addShoppingCartItemAvailabilityToHistory({
                    processId,
                    availability: update.availability,
                    shoppingCartItemId,
                  })
                );
              }

              this.updateProcessCount(processId, shoppingCart?.items?.length);
            })
          )
      )
    );
  }

  //#endregion

  //#region Checkout

  getCheckout({ processId, refresh }: { processId: number; refresh?: boolean }): Observable<CheckoutDTO> {
    let _refresh = refresh;
    return this.store.select(DomainCheckoutSelectors.selectCheckoutByProcessId, { processId }).pipe(
      filter((checkout) => {
        if (isNullOrUndefined(checkout) || _refresh) {
          _refresh = false;
          this.createCheckout({ processId }).subscribe();
          return false;
        }
        return true;
      })
    );
  }

  createCheckout({ processId }: { processId: number }) {
    return this.getShoppingCart({ processId }).pipe(
      first(),
      mergeMap((shoppingCart) =>
        this.storeCheckoutService
          .StoreCheckoutCreateOrRefreshCheckout({
            shoppingCartId: shoppingCart?.id,
          })
          .pipe(
            map((response) => response.result),
            tap((checkout) => this.store.dispatch(DomainCheckoutActions.setCheckout({ processId, checkout })))
          )
      )
    );
  }

  /* @internal */
  _setNotificationChannels({
    processId,
    notificationChannels,
  }: {
    processId: number;
    notificationChannels: NotificationChannel;
  }): Observable<CheckoutDTO> {
    return this.getCheckout({ processId }).pipe(
      first(),
      mergeMap((checkout) =>
        this.storeCheckoutService
          .StoreCheckoutSetNotificationChannels({
            checkoutId: checkout.id,
            notificationChannel: notificationChannels,
          })
          .pipe(
            map((response) => response.result),
            tap((checkout) => this.store.dispatch(DomainCheckoutActions.setCheckout({ processId, checkout })))
          )
      )
    );
  }

  getPayment({ processId }: { processId: number }): Observable<PaymentDTO> {
    return this.getCheckout({ processId }).pipe(
      first(),
      mergeMap((checkout) =>
        this._paymentService
          .StoreCheckoutPaymentGetCheckoutPayment({
            checkoutId: checkout.id,
          })
          .pipe(map((response) => response.result))
      )
    );
  }

  getOlaErrors({ processId }: { processId: number }): Observable<number[]> {
    return this.store.select(DomainCheckoutSelectors.selectOlaErrorsByProcessId, { processId });
  }

  setPayment({ processId, paymentType }: { processId: number; paymentType: PaymentType }): Observable<CheckoutDTO> {
    return this.getCheckout({ processId }).pipe(
      first(),
      mergeMap((checkout) =>
        this._paymentService
          .StoreCheckoutPaymentSetPaymentType({
            checkoutId: checkout?.id,
            paymentType,
          })
          .pipe(
            map((response) => response.result),
            tap((checkout) => this.store.dispatch(DomainCheckoutActions.setCheckout({ processId, checkout })))
          )
      )
    );
  }

  _setBuyer({ processId, buyer }: { processId: number; buyer: BuyerDTO }): Observable<CheckoutDTO> {
    return this.getCheckout({ processId }).pipe(
      first(),
      mergeMap((checkout) => {
        console.log('checkout', checkout, processId);
        return this._buyerService
          .StoreCheckoutBuyerSetBuyerPOST({
            checkoutId: checkout?.id,
            buyerDTO: buyer,
          })
          .pipe(
            map((response) => response.result),
            tap((checkout) => this.store.dispatch(DomainCheckoutActions.setCheckout({ processId, checkout })))
          );
      })
    );
  }

  _setPayer({ processId, payer }: { processId: number; payer: PayerDTO }): Observable<CheckoutDTO> {
    return this.getCheckout({ processId }).pipe(
      first(),
      mergeMap((checkout) =>
        this._payerService
          .StoreCheckoutPayerSetPayerPOST({
            checkoutId: checkout?.id,
            payerDTO: payer,
          })
          .pipe(
            map((response) => response.result),
            tap((checkout) => this.store.dispatch(DomainCheckoutActions.setCheckout({ processId, checkout })))
          )
      )
    );
  }

  setSpecialCommentOnItem({ processId, specialComment }: { processId: number; specialComment: string }) {
    const shoppingCart$ = this.getShoppingCart({ processId }).pipe(first());

    return shoppingCart$.pipe(
      mergeMap((cart) =>
        concat(
          ...cart.items.map((item) =>
            this._shoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItem({
              shoppingCartId: cart.id,
              shoppingCartItemId: item.id,
              values: { specialComment },
            })
          )
        ).pipe(bufferCount(cart.items?.length))
      )
    );
  }

  checkAvailabilities({ processId }: { processId: number }): Observable<any> {
    const shoppingCart$ = this.getShoppingCart({ processId }).pipe(first());
    const itemsToCheck$ = shoppingCart$.pipe(
      map((cart) => cart?.items?.filter((item) => item?.data?.features?.orderType === 'Download' && !item.data.availability.lastRequest))
    );

    return itemsToCheck$.pipe(
      withLatestFrom(shoppingCart$),
      switchMap(async ([items, cart]) => {
        const errorIds = [];

        for (const item of items) {
          const availability = await this.availabilityService
            .getDownloadAvailability({
              item: {
                ean: item.data.product.ean,
                itemId: Number(item.data.product.catalogProductNumber),
                price: item.data.availability.price,
              },
            })
            .toPromise();

          if (!availability || !this.availabilityService.isAvailable({ availability })) {
            errorIds.push(item.id);
          } else {
            await this.updateShoppingCartItemAvailability({
              shoppingCartId: cart.id,
              shoppingCartItemId: item.id,
              availability: {
                ...availability,
                lastRequest: new Date().toISOString(),
              },
            }).toPromise();
          }
        }

        this.setOlaErrors({ processId, errorIds });

        if (errorIds.length > 0) {
          throw throwError(new Error(`Artikel nicht verfügbar`));
        } else {
          return of(undefined);
        }
      })
    );
  }

  updateAvailabilities({ processId }: { processId: number }): Observable<any> {
    const shoppingCart$ = this.getShoppingCart({ processId }).pipe(first());
    const itemsToUpdate$ = shoppingCart$.pipe(
      map((cart) =>
        cart?.items?.filter(
          (item) => item?.data?.features?.orderType === 'DIG-Versand' || item?.data?.features?.orderType === 'B2B-Versand'
        )
      )
    );

    return itemsToUpdate$.pipe(
      mergeMap((itemsToUpdate) => {
        if (!(itemsToUpdate?.length > 0)) {
          return of(undefined);
        }

        return concat(
          ...itemsToUpdate.map(async (itemToUpdate) => {
            const orderType = itemToUpdate?.data?.features?.orderType;
            let availability$: Observable<AvailabilityDTO>;
            if (orderType === 'DIG-Versand') {
              availability$ = this.availabilityService.getDigDeliveryAvailability({
                item: {
                  ean: itemToUpdate.data.product.ean,
                  itemId: Number(itemToUpdate.data.product.catalogProductNumber),
                  price: itemToUpdate.data.availability.price,
                },
                quantity: itemToUpdate.data.quantity,
              });
            } else if (orderType === 'B2B-Versand') {
              const branch = await this.applicationService.getSelectedBranch$(processId).pipe(first()).toPromise();
              availability$ = this.availabilityService.getB2bDeliveryAvailability({
                item: {
                  ean: itemToUpdate.data.product.ean,
                  itemId: Number(itemToUpdate.data.product.catalogProductNumber),
                  price: itemToUpdate.data.availability.price,
                },
                quantity: itemToUpdate.data.quantity,
                branch,
              });
            }

            if (!isObservable(availability$)) {
              return of(undefined);
            }

            return availability$.pipe(
              mergeMap((availability) => {
                let updatedAvailability = availability;
                // Bei Preisupdate der Versandoptionen soll immer der Preis genommen werden, der im Warenkorb steht
                if (orderType === 'DIG-Versand' || orderType === 'B2B-Versand') {
                  const itemPrice = itemToUpdate.data.availability.price;
                  updatedAvailability = {
                    ...availability,
                    price: itemPrice,
                  };
                }
                return this.updateItemInShoppingCart({
                  processId,
                  shoppingCartItemId: itemToUpdate.id,
                  update: {
                    availability: updatedAvailability,
                  },
                });
              })
            );
          })
        ).pipe(bufferCount(itemsToUpdate.length));
      })
    );
  }

  async refreshAvailability({
    processId,
    shoppingCartItemId,
  }: {
    processId: number;
    shoppingCartItemId: number;
  }): Promise<AvailabilityDTO> {
    const shoppingCart = await this.getShoppingCart({ processId }).pipe(first()).toPromise();
    const item = shoppingCart?.items.find((item) => item.id === shoppingCartItemId)?.data;

    if (!item) {
      return;
    }

    const itemData: ItemData = {
      ean: item.product.ean,
      itemId: Number(item.product.catalogProductNumber),
      price: item.availability.price,
    };

    let availability: AvailabilityDTO;

    switch (item.features.orderType) {
      case 'Abholung':
        const abholung = await this.availabilityService
          .getPickUpAvailability({
            item: itemData,
            branch: item.destination?.data?.targetBranch?.data,
            quantity: item.quantity,
          })
          .toPromise();
        availability = abholung[0];
        break;
      case 'Rücklage':
        const ruecklage = await this.availabilityService
          .getTakeAwayAvailability({
            item: itemData,
            quantity: item.quantity,
            branch: item.destination?.data?.targetBranch?.data,
          })
          .toPromise();
        availability = ruecklage;
        break;
      case 'Download':
        const download = await this.availabilityService
          .getDownloadAvailability({
            item: itemData,
          })
          .toPromise();

        availability = download;
        break;

      case 'Versand':
        const versand = await this.availabilityService
          .getDeliveryAvailability({
            item: itemData,
            quantity: item.quantity,
          })
          .toPromise();

        availability = versand;
        break;

      case 'DIG-Versand':
        const digVersand = await this.availabilityService
          .getDigDeliveryAvailability({
            item: itemData,
            quantity: item.quantity,
          })
          .toPromise();

        availability = digVersand;
        break;

      case 'B2B-Versand':
        const b2bVersand = await this.availabilityService
          .getB2bDeliveryAvailability({
            item: itemData,
            quantity: item.quantity,
          })
          .toPromise();

        availability = b2bVersand;
        break;
    }

    await this.updateItemInShoppingCart({
      processId,
      update: { availability },
      shoppingCartItemId: item.id,
    }).toPromise();

    return availability;
  }

  /**
   * Check if the availability of all items is valid
   * @param param0 Process Id
   * @returns true if the availability of all items is valid
   */
  validateOlaStatus({ processId, interval }: { processId: number; interval?: number }): Observable<boolean> {
    return rxjsInterval(interval ?? this.olaExpiration / 10).pipe(
      startWith(0),
      switchMap(() =>
        this.store.select(DomainCheckoutSelectors.selectCheckoutEntityByProcessId, { processId }).pipe(
          map((entity) => {
            const now = Date.now();
            if (!entity || !entity.shoppingCart || !entity.shoppingCart.items) {
              return;
            }

            const itemAvailabilityTimestamp = entity.itemAvailabilityTimestamp ?? {};
            const shoppingCart = entity.shoppingCart;

            const timestamps = shoppingCart.items
              ?.map((i) => i.data)
              ?.filter((item) => !!item?.features?.orderType)
              ?.map((item) => {
                const orderType = item.features.orderType;

                let timestamp = itemAvailabilityTimestamp[`${item.id}_${orderType}`];

                if (timestamp) {
                  return timestamp;
                }

                if (orderType.endsWith('Versand')) {
                  timestamp =
                    itemAvailabilityTimestamp[`${item.id}_Versand`] ??
                    itemAvailabilityTimestamp[`${item.id}_DIG-Versand`] ??
                    itemAvailabilityTimestamp[`${item.id}_B2B-Versand`];
                }

                return timestamp;
              })
              ?.filter((timestamp) => !!timestamp);

            if (timestamps?.length > 0) {
              const oldestTimestamp = Math.min(...timestamps);
              const expirationTimestamp = oldestTimestamp + this.olaExpiration;
              return expirationTimestamp > now;
            }

            return false;
          })
        )
      ),
      distinctUntilChanged()
    );
  }

  validateAvailabilities({ processId }: { processId: number }): Observable<boolean> {
    return this.getShoppingCart({ processId }).pipe(
      map((shoppingCart) => {
        const items = shoppingCart?.items?.map((item) => item.data) || [];
        return items.every((i) => this.availabilityService.isAvailable({ availability: i.availability }));
      })
    );
  }

  checkoutIsValid({ processId }: { processId: number }): Observable<boolean> {
    const olaStatus$ = this.validateOlaStatus({ processId, interval: 250 });

    const availabilities$ = this.validateAvailabilities({ processId });

    return combineLatest([olaStatus$, availabilities$]).pipe(map(([olaStatus, availabilities]) => olaStatus && availabilities));
  }

  completeCheckout({ processId }: { processId: number }): Observable<DisplayOrderDTO[]> {
    const refreshShoppingCart$ = this.getShoppingCart({ processId, latest: true }).pipe(first());
    const refreshCheckout$ = this.getCheckout({ processId, refresh: true }).pipe(first());

    const itemOrderOptions$ = this.getShoppingCart({ processId }).pipe(
      first(),
      map((shoppingCart) => {
        const shoppingCartItems = shoppingCart?.items?.map((item) => item.data) || [];

        const hasTakeAway = shoppingCartItems.some((item) => item.features.orderType === 'Rücklage');
        const hasPickUp = shoppingCartItems.some((item) => item.features.orderType === 'Abholung');
        const hasDownload = shoppingCartItems.some((item) => item.features.orderType === 'Download');
        const hasDelivery = shoppingCartItems.some((item) => item.features.orderType === 'Versand');
        const hasDigDelivery = shoppingCartItems.some((item) => item.features.orderType === 'DIG-Versand');
        const hasB2BDelivery = shoppingCartItems.some((item) => item.features.orderType === 'B2B-Versand');

        return {
          hasTakeAway,
          hasPickUp,
          hasDownload,
          hasDelivery,
          hasDigDelivery,
          hasB2BDelivery,
        };
      }),
      shareReplay()
    );

    const customerTypes$ = this.getCustomerFeatures({ processId }).pipe(
      first(),
      map((features) => {
        const isOnline = !!features?.webshop;
        const isGuest = !!features?.guest;
        const isB2B = !!features?.b2b;
        const hasCustomerCard = !!features?.p4mUser;
        const isStaff = !!features?.staff;

        return { isOnline: isOnline, isGuest, isB2B, hasCustomerCard, isStaff };
      })
    );

    const setSpecialComment$ = this.getSpecialComment({ processId }).pipe(
      first(),
      mergeMap((specialComment) => {
        if (!!specialComment) {
          return this.setSpecialCommentOnItem({ processId, specialComment });
        }
        return of(specialComment);
      })
    );

    const shippingAddressDestination$ = this.getCheckout({ processId }).pipe(
      first(),
      map((checkout) => checkout?.destinations?.filter((dest) => dest.data.target === 2 || dest.data.target === 16)),
      shareReplay()
    );

    const setBuyer$ = this.getBuyer({ processId }).pipe(
      first(),
      mergeMap((buyer) => this._setBuyer({ processId, buyer }))
    );

    const setNotificationChannels$ = this.getNotificationChannels({ processId }).pipe(
      first(),
      mergeMap((notificationChannels) => this._setNotificationChannels({ processId, notificationChannels }))
    );

    const setPayer$ = combineLatest([itemOrderOptions$, customerTypes$]).pipe(
      first(),
      mergeMap(([itemOrderOptions, customerTypes]) => {
        if (
          customerTypes.isB2B ||
          itemOrderOptions.hasB2BDelivery ||
          itemOrderOptions.hasDelivery ||
          itemOrderOptions.hasDigDelivery ||
          itemOrderOptions.hasDownload
        ) {
          return this.getPayer({ processId }).pipe(first());
        }
        return of(undefined);
      }),
      mergeMap((payer) => (payer ? this._setPayer({ processId, payer }) : of(undefined)))
    );

    const updateDestination$ = itemOrderOptions$.pipe(
      withLatestFrom(this.getCustomerFeatures({ processId })),
      mergeMap(([{ hasDownload, hasDelivery, hasDigDelivery, hasB2BDelivery }, customerFeatures]) => {
        const needsUpdate = hasDownload || hasDelivery || hasDigDelivery || hasB2BDelivery;
        return needsUpdate ? this.setDestinationForCustomer({ processId, customerFeatures }) : of(undefined);
      })
    );

    const checkAvailabilities$ = this.checkAvailabilities({ processId });

    const updateAvailabilities$ = this.updateAvailabilities({ processId });

    const setPaymentType$ = itemOrderOptions$.pipe(
      mergeMap(({ hasDownload, hasDelivery, hasDigDelivery, hasB2BDelivery }) => {
        const paymentType = hasDownload || hasDelivery || hasDigDelivery || hasB2BDelivery ? 128 /* Rechnung */ : 4; /* Bar */
        return this.setPayment({ processId, paymentType });
      }),
      shareReplay()
    );

    const setDestination$ = combineLatest([
      this.getCheckout({ processId }).pipe(first()),
      shippingAddressDestination$,
      this.getShippingAddress({ processId }).pipe(first()),
    ]).pipe(
      mergeMap(([checkout, destinations, shippingAddress]) => {
        const obs: Observable<ResponseArgsOfDestinationDTO>[] = [];
        if (destinations?.length > 0) {
          destinations.forEach((destination) => {
            const updatedDestination: DestinationDTO = { ...destination.data, shippingAddress: { ...shippingAddress } };
            obs.push(
              this.storeCheckoutService.StoreCheckoutUpdateDestination({
                checkoutId: checkout.id,
                destinationId: destination.id,
                destinationDTO: updatedDestination,
              })
            );
          });
          return concat(...obs).pipe(bufferCount(destinations?.length));
        }
        return of(destinations);
      }),
      shareReplay()
    );

    const completeOrder$ = this.getCheckout({ processId }).pipe(
      first(),
      mergeMap((checkout) =>
        this.orderCheckoutService
          .OrderCheckoutCreateOrderPOST({
            checkoutId: checkout.id,
          })
          .pipe(
            catchError((error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 409) {
                  this.store.dispatch(DomainCheckoutActions.setOrders({ orders: error.error.result }));
                }
                return throwError(error);
              }
            }),
            map((response) => {
              this.store.dispatch(DomainCheckoutActions.setOrders({ orders: response.result }));
              return response.result;
            })
          )
      )
    );

    return of(undefined)
      .pipe(
        mergeMap((_) => updateDestination$.pipe(tap(console.log.bind(window, 'updateDestination$')))),
        mergeMap((_) => refreshShoppingCart$.pipe(tap(console.log.bind(window, 'refreshShoppingCart$')))),
        mergeMap((_) => setSpecialComment$.pipe(tap(console.log.bind(window, 'setSpecialComment$')))),
        mergeMap((_) => refreshCheckout$.pipe(tap(console.log.bind(window, 'refreshCheckout$')))),
        mergeMap((_) => checkAvailabilities$.pipe(tap(console.log.bind(window, 'checkAvailabilities$')))),
        mergeMap((_) => updateAvailabilities$.pipe(tap(console.log.bind(window, 'updateAvailabilities$'))))
      )
      .pipe(
        mergeMap((_) => setBuyer$.pipe(tap(console.log.bind(window, 'setBuyer$')))),
        mergeMap((_) => setNotificationChannels$.pipe(tap(console.log.bind(window, 'setNotificationChannels$')))),
        mergeMap((_) => setPayer$.pipe(tap(console.log.bind(window, 'setPayer$')))),
        mergeMap((_) => setPaymentType$.pipe(tap(console.log.bind(window, 'setPaymentType$')))),
        mergeMap((_) => setDestination$.pipe(tap(console.log.bind(window, 'setDestination$')))),
        mergeMap((_) => completeOrder$.pipe(tap(console.log.bind(window, 'completeOrder$'))))
      );
  }

  completeKulturpassOrder({
    processId,
    orderItemSubsetId,
  }: {
    processId: number;
    orderItemSubsetId: number;
  }): Observable<ResponseArgsOfValueTupleOfIEnumerableOfDisplayOrderDTOAndIEnumerableOfKeyValueDTOOfStringAndString> {
    const refreshShoppingCart$ = this.getShoppingCart({ processId, latest: true }).pipe(first());
    const refreshCheckout$ = this.getCheckout({ processId, refresh: true }).pipe(first());

    const setBuyer$ = this.getBuyer({ processId }).pipe(
      first(),
      mergeMap((buyer) => this._setBuyer({ processId, buyer }))
    );

    const setPayer$ = this.getPayer({ processId }).pipe(
      first(),
      mergeMap((payer) => this._setPayer({ processId, payer }))
    );

    const checkAvailabilities$ = this.checkAvailabilities({ processId });

    const updateAvailabilities$ = this.updateAvailabilities({ processId });

    return refreshShoppingCart$.pipe(
      mergeMap((_) => refreshCheckout$),
      mergeMap((_) => checkAvailabilities$),
      mergeMap((_) => updateAvailabilities$),
      mergeMap((_) => setBuyer$),
      mergeMap((_) => setPayer$),
      mergeMap((checkout) =>
        this.orderCheckoutService.OrderCheckoutCreateKulturPassOrder({
          payload: {
            checkoutId: checkout.id,
            orderItemSubsetId: String(orderItemSubsetId),
          },
        })
      )
    );
  }

  updateDestination({
    processId,
    destinationId,
    destination,
  }: {
    processId: number;
    destinationId: number;
    destination: DestinationDTO;
  }): Observable<DestinationDTO> {
    return this.getCheckout({ processId }).pipe(
      first(),
      mergeMap((checkout) =>
        this.storeCheckoutService
          .StoreCheckoutUpdateDestination({
            destinationId,
            checkoutId: checkout.id,
            destinationDTO: destination,
          })
          .pipe(
            map((response) => response.result),
            tap((destination) =>
              this.store.dispatch(
                DomainCheckoutActions.setCheckoutDestination({
                  processId,
                  destination,
                })
              )
            )
          )
      )
    );
  }

  //#endregion

  //#region Common

  // Fix für Ticket #4619 Versand Artikel im Warenkob -> keine Änderung bei Kundendaten erfassen
  // Auskommentiert, da dieser Aufruf oftmals mit gleichen Parametern aufgerufen wird (ohne ausgewählten Kunden nur ein leeres Objekt bei customerFeatures)
  // memorize macht keinen deepCompare von Objekten und denkt hier, dass immer der gleiche Return Wert zurückkommt, allerdings ist das hier oft nicht der Fall
  // und der Decorator memorized dann fälschlicherweise
  // @memorize()
  canSetCustomer({
    processId,
    customerFeatures,
  }: {
    processId: number;
    customerFeatures?: { [key: string]: string };
  }): Observable<{ ok?: boolean; filter?: { [key: string]: string }; message?: string; create?: InputDTO }> {
    return this.getShoppingCart({ processId })
      .pipe(
        first(),
        mergeMap((shoppingCart) =>
          this._shoppingCartService
            .StoreCheckoutShoppingCartCanAddBuyer({
              shoppingCartId: shoppingCart.id,
              payload: { customerFeatures },
            })
            .pipe(
              map((response) => ({
                ok: response.result.ok,
                filter: response.result.queryToken?.filter || {},
                message: response.message,
                create: response.result.create,
              }))
            )
        )
      )
      .pipe(shareReplay(1));
  }

  setNotificationChannels({ processId, notificationChannels }: { processId: number; notificationChannels: NotificationChannel }): void {
    this.store.dispatch(DomainCheckoutActions.setNotificationChannels({ processId, notificationChannels }));
  }

  getNotificationChannels({ processId }: { processId: number }): Observable<NotificationChannel> {
    return this.store.select(DomainCheckoutSelectors.selectNotificationChannels, { processId });
  }

  setBuyerCommunicationDetails({ processId, mobile, email }: { processId: number; mobile?: string; email?: string }): void {
    this.store.dispatch(DomainCheckoutActions.setBuyerCommunicationDetails({ processId, mobile, email }));
  }

  getBuyerCommunicationDetails({ processId }: { processId: number }): Observable<CommunicationDetailsDTO> {
    return this.store.select(DomainCheckoutSelectors.selectBuyerCommunicationDetails, { processId });
  }

  getSetableCustomerTypes(processId: number): Observable<{ [key: string]: boolean }> {
    return this.canSetCustomer({ processId, customerFeatures: undefined }).pipe(
      map((res) => {
        let setableTypes: { [key: string]: boolean } = {
          store: false,
          guest: false,
          webshop: false,
          b2b: false,
        };

        res.create?.options?.values?.forEach((option) => {
          setableTypes[option.value] = option.enabled !== false;
        });

        // if (Object.keys(res.filter).length === 0) {
        //   return setableTypes;
        // }

        // const customerTypes = res.filter?.customertype?.split(';') || [];
        // const customerAttributes = res.filter?.customerattributes?.split(';') || [];

        // const typesAndAttributes = [...customerTypes, ...customerAttributes];
        // if (typesAndAttributes.includes('webshop') && !typesAndAttributes.includes('!guest')) {
        //   typesAndAttributes.push('guest');
        // }

        // for (const key in setableTypes) {
        //   if (Object.prototype.hasOwnProperty.call(setableTypes, key)) {
        //     if (typesAndAttributes.includes(key)) {
        //       setableTypes[key] = true;
        //     } else if (typesAndAttributes.includes(`!${key}`)) {
        //       setableTypes[key] = false;
        //     } else {
        //       setableTypes[key] = false;
        //     }
        //   }
        // }

        return setableTypes;
      })
    );
  }

  @memorize()
  getBranches(): Observable<BranchDTO[]> {
    return this._branchService
      .StoreCheckoutBranchGetBranches({
        take: 999,
      })
      .pipe(
        map((r) => {
          return r.result.filter(
            (branch) => branch.status === 1 && branch.branchType === 1 && branch.isOnline === true && branch.isShippingEnabled === true
          );
        }),
        shareReplay()
      );
  }

  reorder(orderId: number, orderItemId: number, orderItemSubsetId: number, data: ReorderValues) {
    return this.orderCheckoutService
      .OrderCheckoutReorder({ orderId, orderItemId, orderItemSubsetId, data })
      .pipe(map((response) => response.result));
  }

  setOlaErrors({ processId, errorIds }: { processId: number; errorIds: number[] }) {
    this.store.dispatch(
      DomainCheckoutActions.setOlaError({
        processId,
        olaErrorIds: errorIds,
      })
    );
  }

  getCustomerFeatures({ processId }: { processId: number }): Observable<{ [key: string]: string }> {
    return this.store.select(DomainCheckoutSelectors.selectCustomerFeaturesByProcessId, { processId });
  }

  setShippingAddress({ processId, shippingAddress }: { processId: number; shippingAddress: ShippingAddressDTO }) {
    this.store.dispatch(DomainCheckoutActions.setShippingAddress({ processId, shippingAddress }));
  }

  getShippingAddress({ processId }: { processId: number }): Observable<ShippingAddressDTO> {
    return this.store.select(DomainCheckoutSelectors.selectShippingAddressByProcessId, { processId });
  }

  removeProcess({ processId }: { processId: number }) {
    this.store.dispatch(DomainCheckoutActions.removeProcess({ processId }));
  }

  setCustomer({ processId, customerDto }: { processId: number; customerDto: CustomerDTO }) {
    this.store.dispatch(DomainCheckoutActions.setCustomer({ processId, customer: customerDto }));
  }

  getCustomer({ processId }: { processId: number }): Observable<CustomerDTO> {
    return this.store.select(DomainCheckoutSelectors.selectCustomerByProcessId, { processId });
  }

  setPayer({ processId, payer }: { processId: number; payer: PayerDTO }) {
    this.store.dispatch(DomainCheckoutActions.setPayer({ processId, payer }));
  }

  getPayer({ processId }: { processId: number }): Observable<PayerDTO> {
    return this.store.select(DomainCheckoutSelectors.selectPayerByProcessId, { processId });
  }

  setBuyer({ processId, buyer }: { processId: number; buyer: BuyerDTO }) {
    this.store.dispatch(DomainCheckoutActions.setBuyer({ processId, buyer }));
  }

  getBuyer({ processId }: { processId: number }): Observable<BuyerDTO> {
    return this.store.select(DomainCheckoutSelectors.selectBuyerByProcessId, { processId });
  }

  getOrders(): Observable<DisplayOrderDTO[]> {
    return this.store.select(DomainCheckoutSelectors.selectOrders);
  }

  updateOrderItem(item: DisplayOrderItemDTO) {
    this.store.dispatch(DomainCheckoutActions.updateOrderItem({ item }));
  }

  removeAllOrders() {
    this.store.dispatch(DomainCheckoutActions.removeAllOrders());
  }

  setSpecialComment({ processId, agentComment }: { processId: number; agentComment: string }) {
    this.store.dispatch(DomainCheckoutActions.setSpecialComment({ processId, agentComment }));
  }

  getSpecialComment({ processId }: { processId: number }) {
    return this.store.select(DomainCheckoutSelectors.selectSpecialComment, { processId });
  }
  //#endregion

  //#region Common

  private updateProcessCount(processId: number, count: number) {
    this.applicationService.patchProcessData(processId, { count });
  }
  //#endregion
}
