import { coerceArray, coerceStringArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { Config } from '@core/config';
import { isNullOrUndefined } from '@utils/common';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { asapScheduler, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _initialized = new BehaviorSubject<boolean>(false);
  get initialized$() {
    return this._initialized.asObservable();
  }

  private _authConfig: AuthConfig;

  constructor(private _config: Config, private readonly _oAuthService: OAuthService) {
    this._oAuthService.events?.subscribe((event) => {
      if (event.type === 'token_received') {
        console.log('SSO Token Expiration:', new Date(this._oAuthService.getAccessTokenExpiration()));
      }
    });
  }

  async init() {
    if (this._initialized.getValue()) {
      throw new Error('AuthService is already initialized');
    }

    this._authConfig = this._config.get('@core/auth');

    this._authConfig.redirectUri = window.location.origin;

    this._authConfig.silentRefreshRedirectUri = window.location.origin + '/silent-refresh.html';
    this._authConfig.useSilentRefresh = true;

    this._oAuthService.configure(this._authConfig);
    this._oAuthService.tokenValidationHandler = new JwksValidationHandler();

    this._oAuthService.setupAutomaticSilentRefresh();
    try {
      await this._oAuthService.loadDiscoveryDocumentAndTryLogin();
    } catch (error) {
      this.login();
    }

    this._initialized.next(true);
  }

  isAuthenticated() {
    return this._oAuthService.hasValidIdToken();
  }

  getToken() {
    return this._oAuthService.getAccessToken();
  }

  getClaims() {
    const token = this._oAuthService.getAccessToken();
    return this.parseJwt(token);
  }

  getClaimByKey(key: string) {
    const claims = this.getClaims();
    if (isNullOrUndefined(claims)) {
      return null;
    }
    return claims[key];
  }

  parseJwt(token: string) {
    if (isNullOrUndefined(token)) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    const encoded = window.atob(base64);
    return JSON.parse(encoded);
  }

  login() {
    this._oAuthService.initLoginFlow();
  }

  setKeyCardToken(token: string) {
    this._oAuthService.customQueryParams = {
      temp_token: token,
    };
  }

  async logout() {
    await this._oAuthService.revokeTokenAndLogout();
    // asapScheduler.schedule(() => {
    //   window.location.reload();
    // }, 250);
  }

  hasRole(role: string | string[]) {
    const roles = coerceArray(role);

    const userRoles = this.getClaimByKey('role');

    if (isNullOrUndefined(userRoles)) {
      return false;
    }

    return roles.every((r) => userRoles.includes(r));
  }

  async refresh() {
    try {
      if (this._authConfig.responseType.includes('code') && this._authConfig.scope.includes('offline_access')) {
        await this._oAuthService.refreshToken();
      } else {
        await this._oAuthService.silentRefresh();
      }
    } catch (error) {
      console.error(error);
    }
  }
}
