import { Injectable } from '@angular/core';
import { ActionHandler } from '@core/command';

/** Dummy Command um Fehlermeldungen aus dem Diloag zu verhinden */
@Injectable()
export class RemitCommand extends ActionHandler<any> {
  constructor() {
    super('remit');
  }

  handler(ctx: any): any {
    return ctx;
  }
}
