export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
  OFF = 4,

  // aliases
  debug = 0,
  info = 1,
  warn = 2,
  error = 3,
  off = 4,
}
