import { NgModule } from '@angular/core';
import { OrderStatusPipe } from './order-status.pipe';
import { AddressPipe } from './address.pipe';
import { CountryPipe } from './country.pipe';
import { OrderTypePipe } from './order-type.pipe';
import { ProcessingStatusPipe } from './processing-status.pipe';
import { PaymentTypePipe } from './payment-type.pipe';
import { ClientChannelPipe } from './client-channel.pipe';
import { OrderTargetPipe } from './order-target.pipe';
import { CustomerNamePipe } from './name.pipe';

@NgModule({
  exports: [
    CountryPipe,
    AddressPipe,
    OrderTypePipe,
    ProcessingStatusPipe,
    OrderStatusPipe,
    PaymentTypePipe,
    ClientChannelPipe,
    OrderTargetPipe,
    CustomerNamePipe,
  ],
  declarations: [CountryPipe, OrderTypePipe, ProcessingStatusPipe, OrderStatusPipe, ClientChannelPipe, OrderTargetPipe],
  imports: [CustomerNamePipe, AddressPipe, PaymentTypePipe],
})
export class CustomerPipesModule {}
