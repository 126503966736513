import { Injectable } from '@angular/core';
import { ActionHandler } from '@core/command';
import { DomainReceiptService } from '../receipt.service';
import { OrderItemsContext } from './order-items.context';

@Injectable()
export class CreateShippingNoteActionHandler extends ActionHandler<OrderItemsContext> {
  constructor(private domainReceiptService: DomainReceiptService) {
    super('CREATE_SHIPPINGNOTE');
  }

  async handler(data: OrderItemsContext): Promise<OrderItemsContext> {
    const orderItemStatusIds = data.items.map((item) => item.orderItemSubsetId);

    const response = await this.domainReceiptService
      .createShippingNotes({
        orderItemStatusIds,
      })
      .toPromise();

    return {
      ...data,
      receipts: response.result,
    };
  }
}
