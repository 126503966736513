import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CORE_CONFIG_LOADER } from '@core/config';
import { Config } from './config';
import { ConfigModuleOptions } from './config-module-options';
import { CORE_JSON_CONFIG_LOADER_URL } from './tokens';

export function _initializeConfigFactory(config: Config) {
  return () => config.init();
}

@NgModule({})
export class ConfigModule {
  static forRoot(options: ConfigModuleOptions): ModuleWithProviders<ConfigModule> {
    const configLoaderProvider = {
      provide: CORE_CONFIG_LOADER,
      useClass: options.useConfigLoader,
    };

    return {
      ngModule: ConfigModule,
      providers: [
        Config,
        configLoaderProvider,
        options.jsonConfigLoaderUrl ? { provide: CORE_JSON_CONFIG_LOADER_URL, useValue: options.jsonConfigLoaderUrl } : null,
      ],
    };
  }
}
