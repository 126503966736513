import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApplicationService } from '@core/application';
import { take } from 'rxjs/operators';

export const ProcessIdGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean | UrlTree> => {
  const application = inject(ApplicationService);
  const router = inject(Router);

  const process = await application.getLastActivatedProcessWithSection$('customer').pipe(take(1)).toPromise();

  const processId = process?.id ?? Date.now();

  const originalUrl = state.url?.split('?')[0] ?? '';

  let url: string = '';

  if (originalUrl.startsWith('/kunde')) {
    url = originalUrl.replace('/kunde', `/kunde/${processId}`);
  } else {
    url = originalUrl;
  }

  if (originalUrl === url) {
    return true;
  }

  await router.navigateByUrl(url);
  return false;
};
