import { Component, OnInit } from '@angular/core';
import { UiModalRef } from './defs';

@Component({
  selector: 'ui-message-modal',
  template: `
    <p class="message">{{ modalRef.data.message }}</p>
    <div class="actions">
      <button (click)="modalRef.close(true)">{{ modalRef?.data?.closeAction || 'OK' }}</button>
    </div>
  `,
  styleUrls: ['./message-modal.component.scss'],
})
export class UiMessageModalComponent {
  constructor(public modalRef: UiModalRef) {}
}
