import { createAction, props } from '@ngrx/store';
import { Breadcrumb } from '../defs';

const prefix = '[CORE-BREADCRUMB]';

/**
 * Action um Breadcrumb zum State hinzufügen
 */
export const addBreadcrumb = createAction(`${prefix} Add Breadcrumb`, props<{ breadcrumb: Breadcrumb }>());

/**
 * Action um Breadcrumb im State zu ändern
 */
export const updateBreadcrumb = createAction(`${prefix} Update Breadcrumb`, props<{ id: number; changes: Partial<Breadcrumb> }>());

/**
 * Action um Breadcrumb im State zu entfernen
 */
export const removeBreadcrumb = createAction(`${prefix} Remove Breadcrumb`, props<{ id: number }>());

/**
 * Action um mehrere Breadcrumbs im State zu entfernen
 */
export const removeManyBreadcrumb = createAction(`${prefix} Remove Many Breadcrumb`, props<{ ids: number[] }>());
