import { Injectable } from '@angular/core';
import { ReceiptOrderItemSubsetReferenceValues, ReceiptService } from '@swagger/oms';
import { memorize } from '@utils/common';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class DomainReceiptService {
  constructor(private receiptService: ReceiptService) {}

  createShippingNotes(params: ReceiptService.ReceiptCreateShippingNote2Params) {
    return this.receiptService.ReceiptCreateShippingNote2(params);
  }

  @memorize({ ttl: 1000 })
  getReceipts(payload: ReceiptOrderItemSubsetReferenceValues) {
    return this.receiptService
      .ReceiptGetReceiptsByOrderItemSubset({
        payload: payload,
      })
      .pipe(shareReplay(1));
  }
}
