import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganisationFormBlockComponent } from './organisation-form-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlComponent } from '@shared/components/form-control';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormControlComponent],
  exports: [OrganisationFormBlockComponent],
  declarations: [OrganisationFormBlockComponent],
})
export class OrganisationFormBlockModule {}
