/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AvConfiguration as __Configuration } from '../av-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfAvailabilityDTO } from '../models/response-args-of-ienumerable-of-availability-dto';
import { AvailabilityRequestDTO } from '../models/availability-request-dto';
@Injectable({
  providedIn: 'root',
})
class AvailabilityService extends __BaseService {
  static readonly AvailabilityStoreAvailabilityPath = '/availability/store';
  static readonly AvailabilityShippingAvailabilityPath = '/availability/shipping';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lieferbarkeitsabfrage für Abholung
   * Für jede AvailabilityRequestDTO müssen mindestens folgende Werte gesetzt sein: ItemId oder EAN, Qty, sowie ShopId oder BranchNumber
   * @param request undefined
   */
  AvailabilityStoreAvailabilityResponse(request: Array<AvailabilityRequestDTO>): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfAvailabilityDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/availability/store`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfAvailabilityDTO>;
      })
    );
  }
  /**
   * Lieferbarkeitsabfrage für Abholung
   * Für jede AvailabilityRequestDTO müssen mindestens folgende Werte gesetzt sein: ItemId oder EAN, Qty, sowie ShopId oder BranchNumber
   * @param request undefined
   */
  AvailabilityStoreAvailability(request: Array<AvailabilityRequestDTO>): __Observable<ResponseArgsOfIEnumerableOfAvailabilityDTO> {
    return this.AvailabilityStoreAvailabilityResponse(request).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfAvailabilityDTO)
    );
  }

  /**
   * Lieferbarkeitsabfrage für Versand
   * Für jede AvailabilityRequestDTO müssen mindestens folgende Werte gesetzt sein: ItemId oder EAN, Qty, sowie ShopId oder BranchNumber
   * @param request undefined
   */
  AvailabilityShippingAvailabilityResponse(request: Array<AvailabilityRequestDTO>): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfAvailabilityDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/availability/shipping`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfAvailabilityDTO>;
      })
    );
  }
  /**
   * Lieferbarkeitsabfrage für Versand
   * Für jede AvailabilityRequestDTO müssen mindestens folgende Werte gesetzt sein: ItemId oder EAN, Qty, sowie ShopId oder BranchNumber
   * @param request undefined
   */
  AvailabilityShippingAvailability(request: Array<AvailabilityRequestDTO>): __Observable<ResponseArgsOfIEnumerableOfAvailabilityDTO> {
    return this.AvailabilityShippingAvailabilityResponse(request).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfAvailabilityDTO)
    );
  }
}

module AvailabilityService {
}

export { AvailabilityService }
