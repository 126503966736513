import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFilterFilterGroupMainComponent } from './filter-filter-group-main.component';
import { UiFilterInputChipModule } from '../../shared/filter-input-chip';

@NgModule({
  imports: [CommonModule, UiFilterInputChipModule],
  exports: [UiFilterFilterGroupMainComponent],
  declarations: [UiFilterFilterGroupMainComponent],
})
export class UiFilterFilterGroupMainModule {}
