/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { WwsConfiguration, WwsConfigurationInterface } from './wws-configuration';

import { PackageService } from './services/package.service';
import { ProductListService } from './services/product-list.service';
import { StockService } from './services/stock.service';
import { SupplierService } from './services/supplier.service';
import { WareneingangService } from './services/wareneingang.service';

/**
 * Provider for all Wws services, plus WwsConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    WwsConfiguration,
    PackageService,
    ProductListService,
    StockService,
    SupplierService,
    WareneingangService
  ],
})
export class WwsModule {
  static forRoot(customParams: WwsConfigurationInterface): ModuleWithProviders<WwsModule> {
    return {
      ngModule: WwsModule,
      providers: [
        {
          provide: WwsConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
