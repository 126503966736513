import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApplicationState } from './application.state';
export const selectApplicationState = createFeatureSelector<ApplicationState>('core-application');

export const selectTitle = createSelector(selectApplicationState, (s) => s.title);

export const selectSection = createSelector(selectApplicationState, (s) => s.section);

export const selectProcesses = createSelector(selectApplicationState, (s) => s.processes);

export const selectActivatedProcess = createSelector(selectApplicationState, (s) =>
  s?.processes?.reduce((process, current) => {
    if (!process) {
      return current;
    }
    return process.activated > current.activated ? process : current;
  }, undefined)
);
