import { AddressFormBlockData } from '../address';
import { NameFormBlockData } from '../name/name-form-block-data';
import { OrganisationFormBlockData } from '../organisation/organisation-form-block-data';

export interface DeviatingAddressFormBlockData {
  deviatingAddress?: boolean;
  organisation?: OrganisationFormBlockData;
  name?: NameFormBlockData;
  address?: AddressFormBlockData;
  email?: string;
  phoneNumbers?: { mobile?: string; phone?: string };
}
