<ng-template>
  <div [class]="classList">
    <svg viewBox="0 25 50 25" class="triangle" [class.triangle-warning]="warning">
      <polygon points="0,50 50,50 25,25"></polygon>
    </svg>
    <div class="tooltip-box" [class.tooltip-warning]="warning">
      <p><ng-content></ng-content></p>
      <button class="close" *ngIf="closeable" (click)="close()">
        <ui-icon icon="close" size="14px"></ui-icon>
      </button>
    </div>
  </div>
</ng-template>
