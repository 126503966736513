import {
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UiOverlayTrigger } from '@ui/common';
import { UiDropdownItemDirective } from './dropdown-item.directive';
import { DropdownPositionX, DropdownPositionY } from './dropdown-positions';

@Component({
  selector: 'ui-dropdown',
  templateUrl: './dropdown.component.html',
  exportAs: 'uiDropdown',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dropdown.scss'],
})
export class UiDropdownComponent implements UiOverlayTrigger, OnInit {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  @ContentChildren(UiDropdownItemDirective)
  items: QueryList<UiDropdownItemDirective>;

  content: ElementRef<any>;

  @Input()
  xPosition: DropdownPositionX;

  @Input()
  yPosition: DropdownPositionY;

  @Input()
  xOffset = 0;

  @Input()
  yOffset = 0;

  close = () => {};

  get classList() {
    return ['ui-dropdown-panel', `x-position-${this.xPosition}`, `y-position-${this.yPosition}`];
  }

  constructor() {}

  ngOnInit(): void {}

  @HostListener('window:keydown', ['$event'])
  keyup(event: KeyboardEvent) {
    let optionIndex = this.items?.toArray()?.findIndex((o) => o.focused);

    if (event.key === 'ArrowUp') {
      event.preventDefault();

      optionIndex--;
      if (optionIndex < 0) {
        optionIndex = 0;
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();

      optionIndex++;
      if (this.items.length - 1 < optionIndex) {
        return;
      }
    } else {
      return;
    }

    this.items?.toArray()[optionIndex]?.focus();
  }
}
