import { Subject } from 'rxjs';
import { UiInput } from './ui-input';
import { IUiOption, UiOption } from './ui-option';

export interface IUiInputOptions {
  readonly max?: number;
  readonly values?: Array<IUiOption>;
}

export class UiInputOptions implements IUiInputOptions {
  //#region implements IUiFilterInputOptions
  private _max?: number;
  get max() {
    return this._max;
  }

  private _values?: Array<UiOption>;
  get values() {
    return this._values;
  }
  //#endregion

  readonly changes = new Subject<{ keys: (keyof IUiInputOptions)[]; inputOptions: UiInputOptions }>();

  constructor(public readonly parent?: UiInput) {}

  getSelectedOptions(): UiOption[] {
    return this.values?.map((o) => o.getSelectedOptions()).reduce((agg, options) => [...agg, ...options], []) || [];
  }

  getUnselectedOptions(): UiOption[] {
    return this.values?.map((o) => o.getUnselectedOptions()).reduce((agg, options) => [...agg, ...options], []) || [];
  }

  toObject(): IUiInputOptions {
    return {
      max: this.max,
      values: this.values?.map((value) => value.toObject()),
    };
  }

  static create(inputOptions: IUiInputOptions, parent?: UiInput) {
    const target = new UiInputOptions(parent);

    target._max = inputOptions?.max;
    target._values = inputOptions?.values?.map((value) => UiOption.create(value, target));

    return target;
  }
}
