import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Breadcrumb } from '../defs';
import { breadcrumbAdapter, BreadcrumbState, featureName } from './breadcrumb.state';
import { isArray } from '@utils/common';

const selectFeature = createFeatureSelector<BreadcrumbState>(featureName);

const { selectAll, selectEntities } = breadcrumbAdapter.getSelectors(selectFeature);

/**
 * Gibt alle Breadcrumb Entities als Array zurück
 */
export const selectBreadcrumbs = selectAll;

/**
 * Gibt alle Breadcrumb Entities als Array zurück die den key enthalten
 */
export const selectBreadcrumbById = createSelector(selectEntities, (entities, id: number) => entities[id]);

/**
 * Gibt alle Breadcrumb Entities als Array zurück die den key enthalten
 */
export const selectBreadcrumbsByKey = createSelector(selectAll, (entities, key: string) => entities.filter((crumb) => crumb.key == key));

/**
 * Gibt alle Breadcrumb Entities als Array zurück die den key und tag enthalten
 */
export const selectBreadcrumbsByKeyAndTag = createSelector(
  selectAll,
  (entities: Breadcrumb[], { key, tag }: { key: string; tag: string }) =>
    entities.filter((crumb) => crumb.key == key && isArray(crumb.tags) && crumb.tags.includes(tag))
);

/**
 * Gibt alle Breadcrumb Entities als Array zurück die den key und tags enthalten
 */
export const selectBreadcrumbsByKeyAndTags = createSelector(
  selectAll,
  (entities: Breadcrumb[], { key, tags }: { key: string; tags: string[] }) =>
    entities.filter((crumb) => crumb.key == key && isArray(crumb.tags) && tags.every((tag) => crumb.tags.includes(tag)))
);

/**
 * Gibt alle Breadcrumb Entities als Array zurück die die tags enthalten
 */
export const selectBreadcrumbsBySection = createSelector(selectAll, (entities: Breadcrumb[], { section }: { section: string }) =>
  entities.filter((crumb) => crumb.section === section)
);
