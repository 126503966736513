import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiIconComponent } from './icon.component';
import { UiIconBadgeComponent } from './icon-badge/icon-badge.component';
import { UISvgIconComponent } from './svg-icon.component';
import { IconRegistry } from './icon-registry';
import { UI_ICON_CFG } from './tokens';
import { UiIconConfig } from './icon-config';

export function _rootIconRegistryFactory(config: UiIconConfig): IconRegistry {
  const registry = new IconRegistry();

  if (config?.fallback) {
    registry.setFallback(config.fallback);
  }
  if (config?.aliases) {
    registry.alias(...config.aliases);
  }
  if (config?.icons) {
    registry.register(...config.icons);
  }

  if (config?.viewBox) {
    registry.setViewBox(config.viewBox);
  }

  return registry;
}

@NgModule({
  imports: [CommonModule],
  declarations: [UiIconComponent, UiIconBadgeComponent, UISvgIconComponent],
  exports: [UiIconComponent, UiIconBadgeComponent, UISvgIconComponent],
})
export class UiIconModule {
  static forRoot(config?: UiIconConfig): ModuleWithProviders<UiIconModule> {
    const providers: Provider[] = [
      {
        provide: IconRegistry,
        useFactory: _rootIconRegistryFactory,
        deps: [UI_ICON_CFG],
      },
    ];

    if (config) {
      providers.push({
        provide: UI_ICON_CFG,
        useValue: config,
      });
    }

    return {
      ngModule: UiIconModule,
      providers,
    };
  }
}
