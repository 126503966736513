import { NgModule, ModuleWithProviders } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { applicationReducer } from './store';
import { ApplicationService } from './application.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class CoreApplicationModule {
  static forRoot(): ModuleWithProviders<CoreApplicationModule> {
    return {
      ngModule: RootCoreApplicationModule,
    };
  }
}

@NgModule({
  imports: [StoreModule.forFeature('core-application', applicationReducer)],
  providers: [ApplicationService],
})
export class RootCoreApplicationModule {}
