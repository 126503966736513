import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { IconRegistry } from './icon-registry';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'shared-icon',
  template: `
    <svg [style.width.rem]="size / 16" [style.height.rem]="size / 16" [attr.viewBox]="viewBox">
      <path fill="currentColor" [attr.d]="data" />
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class IconComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  icon: string;

  data: string;

  viewBox: string;

  @Input()
  size: number = 24;

  private _onDestroy$ = new Subject<void>();

  constructor(private readonly _iconRegistry: IconRegistry, private readonly _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._iconRegistry.updated.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      this.updateIcon();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon) {
      this.updateIcon();
    }
  }

  updateIcon(): void {
    const icon = this._iconRegistry.get(this.icon);
    this.data = icon?.data;
    this.viewBox = icon?.viewBox;
    this._cdr.markForCheck();
  }
}
