/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CrmConfiguration, CrmConfigurationInterface } from './crm-configuration';

import { AddressService } from './services/address.service';
import { CountryService } from './services/country.service';
import { CustomerService } from './services/customer.service';
import { LoyaltyCardService } from './services/loyalty-card.service';
import { PayerService } from './services/payer.service';
import { ShippingAddressService } from './services/shipping-address.service';

/**
 * Provider for all Crm services, plus CrmConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CrmConfiguration,
    AddressService,
    CountryService,
    CustomerService,
    LoyaltyCardService,
    PayerService,
    ShippingAddressService
  ],
})
export class CrmModule {
  static forRoot(customParams: CrmConfigurationInterface): ModuleWithProviders<CrmModule> {
    return {
      ngModule: CrmModule,
      providers: [
        {
          provide: CrmConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
