import { QueryTokenDTO } from '@swagger/cat';

export interface MessageBoardItemDTO {
  uId?: string;

  type?: string;

  category?: string;

  command?: string;

  headline?: string;

  text?: string;

  queryToken?: QueryTokenDTO;

  expirationDate?: string;
}
