import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ui-icon-badge',
  templateUrl: 'icon-badge.component.html',
  styleUrls: ['icon-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiIconBadgeComponent {
  @Input()
  icon: string;

  @Input()
  alt: string;

  @Input()
  area: 'customer' | 'branch' = 'customer';
}
