import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiDateInputDirective } from './ui-date-input.directive';

import { UiInputDirective } from './ui-input.directive';
import { UiCheckboxInputDirective } from './ui-checkbox-input.directive';
import { UiCaptureDirective } from './ui-capture-input.directive';

@NgModule({
  imports: [CommonModule, UiDateInputDirective],
  exports: [UiInputDirective, UiCheckboxInputDirective, UiDateInputDirective, UiCaptureDirective],
  declarations: [UiInputDirective, UiCheckboxInputDirective, UiCaptureDirective],
  providers: [],
})
export class UiInputModule {}
