<ng-container *ifRole="'Store'">
  <shared-checkbox
    *ngIf="customerType !== 'b2b'"
    [ngModel]="p4mUser"
    (ngModelChange)="setValue({ p4mUser: !p4mUser })"
    [disabled]="p4mReadonly || readonly"
  >
    Kundenkarte
  </shared-checkbox>
</ng-container>
<ng-container *ngFor="let option of filteredOptions$ | async">
  <shared-checkbox
    *ngIf="option?.enabled !== false"
    [ngModel]="option.value === customerType"
    (ngModelChange)="setValue({ customerType: $event ? option.value : undefined })"
    [disabled]="readonly"
    [name]="option.value"
  >
    {{ option.label }}
  </shared-checkbox>
</ng-container>
