import { Injectable } from '@angular/core';
import { DomainOmsService } from '../oms.service';
import { OrderItemListItemDTO, StatusValues } from '@swagger/oms';
import { ChangeOrderItemStatusBaseActionHandler } from './change-order-item-status-base.action-handler';
import { OrderItemsContext } from './order-items.context';

@Injectable()
export class ReserverdActionHandler extends ChangeOrderItemStatusBaseActionHandler {
  constructor(domainOmsService: DomainOmsService) {
    super(domainOmsService, 'RESERVED');
  }

  getStatusValues(orderItem: OrderItemListItemDTO, context: OrderItemsContext): StatusValues {
    return { processingStatus: 33554432 };
  }
}
