import { PriceDTO, ReturnItemDTO, ReturnSuggestionDTO } from '@swagger/remi';

export interface RemissionListItem {
  dtoType: 'return' | 'suggestion';
  dto: ReturnItemDTO | ReturnSuggestionDTO;

  title: string;
  ean: string;
  productGroup: string;
  format: string;
  formatDetail: string;

  price: PriceDTO;
  placementType: string;
  department: string;

  inStock: number;
  remainingQuantity: number;
  remissionQuantity: number;
  remissionReason?: string;
  assortment?: string;
}
