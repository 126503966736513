import { Pipe, PipeTransform } from '@angular/core';
import { SelectFilterOption } from '../../models';

@Pipe({
  name: 'selectedFilters',
})
export class SelectedFiltersPipe implements PipeTransform {
  transform(filters: SelectFilterOption[], itemsToShow?: number): SelectFilterOption[] {
    return this.flatten(filters, 'options')
      .filter((option) => option.selected)
      .reduce((acc, curr) => acc.concat(curr), [])
      .slice(0, itemsToShow);
  }

  private flatten<T>(arr: T[], property: keyof T): T[] {
    return arr.reduce(
      (acc, curr) => acc.concat(Array.isArray(curr[property]) ? this.flatten((curr[property] as unknown) as T[], property) : curr),
      []
    );
  }
}
