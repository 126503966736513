/**
 * Pick a value from an object at a given path.
 * @param path path of the value to pick
 * @param obj object to pick from
 * @returns the value at the path or undefined
 * @throws if obj is not an object
 */
export function pick<T = any>(path: string, obj: Object): T {
  const paths = path.split('.');

  // check if obj is null or undefined
  if (obj == null) {
    return undefined;
  }

  // check if obj is of type object and not an array
  // and throw an error if not
  if (typeof obj !== 'object' || Array.isArray(obj)) {
    throw new Error(`${obj} is not an object`);
  }

  let result = obj;

  // loop through the path and pick the value
  // early exit if the path is empty
  for (const path of paths) {
    result = result[path];
    if (result == null) {
      return undefined;
    }
  }
  return result as T;
}
