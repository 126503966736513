<ng-container [formGroup]="control">
  <shared-form-control label="Straße">
    <input
      placeholder="Straße"
      class="input-control"
      type="text"
      formControlName="street"
      [tabindex]="tabIndexStart"
      [autofocus]="focusAfterInit"
      [readonly]="readonly"
    />
  </shared-form-control>
  <shared-form-control label="Hausnummer">
    <input
      placeholder="Hausnummer"
      class="input-control"
      type="text"
      formControlName="streetNumber"
      [tabindex]="tabIndexStart + 1"
      [readonly]="readonly"
    />
  </shared-form-control>
  <shared-form-control label="PLZ">
    <input
      placeholder="PLZ"
      class="input-control"
      type="text"
      formControlName="zipCode"
      [tabindex]="tabIndexStart + 2"
      [readonly]="readonly"
    />
  </shared-form-control>
  <shared-form-control label="Ort">
    <input
      placeholder="Ort"
      class="input-control"
      type="text"
      formControlName="city"
      [tabindex]="tabIndexStart + 3"
      [readonly]="readonly"
    />
  </shared-form-control>

  <shared-form-control class="col-span-2" label="Adresszusatz">
    <input
      placeholder="Adresszusatz"
      class="input-control"
      type="text"
      formControlName="info"
      [tabindex]="tabIndexStart + 4"
      [readonly]="readonly"
    />
  </shared-form-control>

  <shared-form-control class="col-span-2" label="Land">
    <shared-select placeholder="Land" formControlName="country" [tabindex]="tabIndexStart + 5" [readonly]="readonly">
      <shared-select-option *ngFor="let country of countries || (countries$ | async)" [value]="country.isO3166_A_3">
        {{ country.name }}
      </shared-select-option>
    </shared-select>
  </shared-form-control>
</ng-container>
