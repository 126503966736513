import { NgModule } from '@angular/core';
import { DevScanAdapter } from './dev.scan-adapter';
import { NativeScanAdapter } from './native.scan-adapter';
import { SCAN_ADAPTER } from './tokens';

@NgModule({})
export class ScanAdapterModule {
  static forRoot() {
    return {
      ngModule: ScanAdapterModule,
      providers: [
        { provide: SCAN_ADAPTER, useClass: NativeScanAdapter, multi: true },
        { provide: SCAN_ADAPTER, useClass: DevScanAdapter, multi: true },
      ],
      // Use for testing:
      // providers: [{ provide: SCAN_ADAPTER, useClass: dev ? DevScanAdapter : NativeScanAdapter, multi: true }],
    };
  }
}
