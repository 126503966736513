import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, replaceStr: string = '', replaceWith: string = ''): string {
    return value?.replace(new RegExp(replaceStr, 'gi'), replaceWith);
  }
}
