import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BirthDateFormBlockComponent } from './birth-date-form-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControlComponent } from '@shared/components/form-control';
import { UiDateInputDirective } from '@ui/input';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormControlComponent, UiDateInputDirective],
  exports: [BirthDateFormBlockComponent],
  declarations: [BirthDateFormBlockComponent],
})
export class BirthDateFormBlockModule {}
