import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AcceptAGBFormBlockComponent } from './accept-agb-form-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from '@shared/components/checkbox';

@NgModule({
  imports: [CommonModule, CheckboxComponent, ReactiveFormsModule],
  exports: [AcceptAGBFormBlockComponent],
  declarations: [AcceptAGBFormBlockComponent],
})
export class AcceptAGBFormBlockModule {}
