import { Action, createReducer, on } from '@ngrx/store';
import { breadcrumbAdapter, BreadcrumbState, INIT } from './breadcrumb.state';

import * as actions from './breadcrumb.actions';

const _breadcrumbReducer = createReducer(
  INIT,
  on(actions.addBreadcrumb, (s, a) => breadcrumbAdapter.addOne(a.breadcrumb, s)),
  on(actions.updateBreadcrumb, (s, a) => breadcrumbAdapter.updateOne(a, s)),
  on(actions.removeBreadcrumb, (s, a) => breadcrumbAdapter.removeOne(a.id, s)),
  on(actions.removeManyBreadcrumb, (s, a) => breadcrumbAdapter.removeMany(a.ids, s))
);

export function breadcrumbReducer(state: BreadcrumbState, action: Action) {
  return _breadcrumbReducer(state, action);
}
