import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InterestsFormBlockComponent } from './interests-form-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from '@shared/components/checkbox';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CheckboxComponent],
  exports: [InterestsFormBlockComponent],
  declarations: [InterestsFormBlockComponent],
})
export class InterestsFormBlockModule {}
