import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageBoardItemDTO } from 'apps/hub/notifications/src/lib/defs';

@Component({
  selector: 'modal-notifications-package-inspection-group',
  templateUrl: 'notifications-package-inspection-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNotificationsPackageInspectionGroupComponent {
  @Input()
  notifications: MessageBoardItemDTO[];

  @Output()
  navigated = new EventEmitter<void>();

  constructor(private _router: Router) {}

  itemSelected(item: MessageBoardItemDTO) {
    this._router.navigate(['/filiale/package-inspection/packages']);
    this.navigated.emit();
  }
}
