import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'supplierName',
})
export class SupplierNamePipe implements PipeTransform {
  readonly supplierMap = {
    Z: 'Zentrallager',
    L: 'Libri',
    K: 'KNV',
    I: 'Ingram',
    G: 'Gardner',
    KA: 'KNV Ausland',
    LV3: 'Verlag',
    H: 'Hugendubel',
    F: 'Filialbestand',
    DIG: 'Hugendubel Digital',
  };

  transform(value: string): string {
    return this.supplierMap[value] || '';
  }
}
