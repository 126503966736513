import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DomainPackageInspectionService } from '@domain/package-inspection';
import { PackageArrivalStatusDTO, PackageDTO2 } from '@swagger/wws';
import { UiModalRef } from '@ui/modal';
import { WrongDestinationModalData } from './wrong-destination-modal.data';
import { PackageListModule } from '@shared/components/package-inspection/package-list';

@Component({
  selector: 'page-package-inspection-wrong-destination-modal',
  templateUrl: 'wrong-destination-modal.component.html',
  styleUrls: ['wrong-destination-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PackageListModule],
})
export class WrongDestinationModalComponent {
  get packages(): PackageDTO2[] {
    const packages = this._ref.data?.packages ?? [];

    // return top 5 packages
    return packages.slice(0, 10);
  }

  get packageCount(): number {
    return this.packages.length;
  }

  constructor(
    private _ref: UiModalRef<void, WrongDestinationModalData>,
    private _packageInspectionService: DomainPackageInspectionService
  ) {}

  close(): void {
    this._ref.close();
  }

  async done(): Promise<void> {
    let promises: Promise<PackageArrivalStatusDTO>[] = [];
    for (const pkg of this.packages) {
      promises.push(this._packageInspectionService.changePackageStatus(pkg, '8').toPromise());
    }

    await Promise.all(promises);
    this.close();
  }

  changeStatus(status: string): Promise<PackageArrivalStatusDTO[]> {
    let promises: Promise<PackageArrivalStatusDTO>[] = [];
    for (const pkg of this.packages) {
      promises.push(this._packageInspectionService.changePackageStatus(pkg, status).toPromise());
    }

    return Promise.all(promises);
  }
}
