<p class="text-center mb-6">
  {{ message }}
</p>
<div class="message-modal-actions grid grid-flow-col gap-4 items-center justify-center">
  <button
    *ngFor="let action of actions"
    type="button"
    class="btn rounded-full font-bold text-p1 border-[2px] border-solid border-brand px-6"
    [class.bg-brand]="action.primary"
    [class.hover:bg-brand]="action.primary"
    [class.text-white]="action.primary"
    [class.bg-white]="!action.primary"
    [class.text-brand]="!action.primary"
    [class.hover:bg-white]="!action.primary"
    [class.hover:text-brand]="!action.primary"
    (click)="clickAction(action)"
  >
    {{ action.label }}
  </button>
</div>
