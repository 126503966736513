import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { IfRoleDirective } from './if-role.directive';
@NgModule({
  declarations: [IfRoleDirective],
  exports: [IfRoleDirective],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        OAuthModule.forRoot({
          resourceServer: { sendAccessToken: true },
        }).providers,
      ],
    };
  }
}
