import { ShippingAddressDTO } from '@swagger/crm';

function getDefaultShippingAddress(shippingAddresses: ShippingAddressDTO[]): ShippingAddressDTO {
  if (Array.isArray(shippingAddresses)) {
    const defaults = shippingAddresses.filter((f) => f.isDefault).map((f) => new Date(f.isDefault));

    if (defaults.length > 0) {
      const latest = Math.max(...defaults.map((f) => f.getTime()));
      return shippingAddresses.find((p) => new Date(p.isDefault).getTime() === latest);
    }
  }
  return undefined;
}

export const ShippingAddressHelper = {
  getDefaultShippingAddress,
};
