import { Pipe, PipeTransform } from '@angular/core';
import { Group } from '../defs';
import { groupBy } from '../defs/group-by';

@Pipe({
  name: 'groupBy',
  pure: true,
  standalone: true,
})
export class GroupByPipe implements PipeTransform {
  transform<TItem, TGroup>(value: TItem[], selectGroup: (item: TItem) => TGroup): Group<TGroup, TItem>[] {
    if (typeof selectGroup !== 'function') {
      console.warn(`Function selectGroup is required.`);
      return [];
    }
    return groupBy(value, selectGroup);
  }
}
