import { Injectable } from '@angular/core';
import { DomainPackageInspectionService } from '@domain/package-inspection';
import { PackageDTO2 } from '@swagger/wws';
import { UiModalRef, UiModalService } from '@ui/modal';
import { Observable } from 'rxjs';
import { WrongDestinationModalComponent } from './wrong-destination-modal.component';
import { WrongDestinationModalData } from './wrong-destination-modal.data';

@Injectable({ providedIn: 'root' })
export class WrongDestinationModalService {
  constructor(private _packageInspectionService: DomainPackageInspectionService, private _modal: UiModalService) {}

  fetchPackages(): Observable<PackageDTO2[]> {
    return this._packageInspectionService.queryPackages({
      filter: { status: '8' },
    });
  }

  open(data: WrongDestinationModalData): UiModalRef<void, WrongDestinationModalData> {
    return this._modal.open({
      content: WrongDestinationModalComponent,
      data,
    });
  }

  async fetchAndOpen(): Promise<UiModalRef<void, WrongDestinationModalData> | undefined> {
    const packages = await this.fetchPackages().toPromise();

    if (!packages || packages.length === 0) {
      return undefined;
    }

    return this.open({ packages });
  }
}
