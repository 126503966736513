import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateP4MCustomerComponent } from './create-p4m-customer.component';
import {
  AddressFormBlockModule,
  BirthDateFormBlockModule,
  InterestsFormBlockModule,
  NameFormBlockModule,
  OrganisationFormBlockModule,
  P4mNumberFormBlockModule,
  NewsletterFormBlockModule,
  DeviatingAddressFormBlockComponentModule,
  AcceptAGBFormBlockModule,
  EmailFormBlockModule,
  PhoneNumbersFormBlockModule,
} from '../../components/form-blocks';
import { CustomerTypeSelectorModule } from '../../components/customer-type-selector';
import { UiSpinnerModule } from '@ui/spinner';
import { UiIconModule } from '@ui/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    CustomerTypeSelectorModule,
    AddressFormBlockModule,
    BirthDateFormBlockModule,
    InterestsFormBlockModule,
    NameFormBlockModule,
    OrganisationFormBlockModule,
    P4mNumberFormBlockModule,
    NewsletterFormBlockModule,
    DeviatingAddressFormBlockComponentModule,
    AcceptAGBFormBlockModule,
    EmailFormBlockModule,
    PhoneNumbersFormBlockModule,
    UiSpinnerModule,
    UiIconModule,
    RouterModule,
  ],
  exports: [CreateP4MCustomerComponent],
  declarations: [CreateP4MCustomerComponent],
})
export class CreateP4MCustomerModule {}
