<shared-form-control label="E-Mail">
  <input
    placeholder="E-Mail"
    type="mail"
    class="input-control"
    [formControl]="control"
    [tabindex]="tabIndexStart"
    [autofocus]="focusAfterInit"
    [readonly]="readonly"
  />
</shared-form-control>
