import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { PackageDTO2 } from '@swagger/wws';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { asapScheduler, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'page-package-list',
  templateUrl: 'package-list.component.html',
  styleUrls: ['package-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'page-package-list',
  },
  exportAs: 'pagePackageList',
})
export class PackageListComponent implements AfterViewInit, OnDestroy {
  @Input()
  packages: PackageDTO2[] = [];

  @Input()
  fetching: boolean = false;

  @Input()
  showHeader: boolean = true;

  @Input()
  total: number = 0;

  @Input()
  initialScrollIndex: number = 0;

  @Output()
  allItemsRendered = new EventEmitter<void>();

  @Output()
  scrollIndexChange = new EventEmitter<number>();

  @ViewChild(CdkVirtualScrollViewport, { static: true, read: CdkVirtualScrollViewport })
  cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  private _navigationEnabled: BooleanInput = true;
  @Input()
  get navigationEnabled(): BooleanInput {
    return coerceBooleanProperty(this._navigationEnabled);
  }

  set navigationEnabled(value: BooleanInput) {
    this._navigationEnabled = value;
  }

  private _onDestroy$ = new Subject<void>();

  constructor() {}

  ngAfterViewInit(): void {
    this.cdkVirtualScrollViewport.renderedRangeStream.pipe(takeUntil(this._onDestroy$)).subscribe((range) => {
      if (range.end === this.packages?.length) {
        this.allItemsRendered.emit();
      }
    });

    if (this.initialScrollIndex > 0) {
      asapScheduler.schedule(() => {
        this.cdkVirtualScrollViewport.scrollToIndex(this.initialScrollIndex);
      }, 10);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  trackByFn = (index: number, item: PackageDTO2) => item.id;

  scrollToIndex(index: number) {
    this.cdkVirtualScrollViewport.scrollToIndex(index);
  }
}
