<div #selectFilter class="isa-card">
  <div class="isa-mt-10">
    <p class="range-filter-headline">{{ filter.description }}</p>
    <div [formGroup]="formGroup" class="range-input-wrapper">
      <input
        class="range-filter-input"
        uiInput
        type="string"
        [tabindex]="1"
        [formControlName]="'start'"
        [placeholder]="filter.options[0].name"
      />
      <input
        class="range-filter-input"
        uiInput
        type="string"
        [tabindex]="1"
        [formControlName]="'stop'"
        [placeholder]="filter.options[1].name"
      />
    </div>
    <p *ngIf="error" class="range-filter-error">
      {{ error }}
    </p>
  </div>
</div>
