import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { KulturpassOrderSearchboxComponent } from './kulturpass-order-searchbox/kulturpass-order-searchbox.component';
import { provideComponentStore } from '@ngrx/component-store';
import { KulturpassOrderModalStore } from './kulturpass-order-modal.store';
import { UiModalRef } from '@ui/modal';
import { KulturpassOrderModalData } from './kulturpass-order-modal.data';
import { AddToShoppingCartDTO, ShoppingCartItemDTO } from '@swagger/checkout';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { BranchNamePipe } from '@shared/pipes/branch';
import { combineLatest } from 'rxjs';
import { KulturpassOrderItemComponent } from './kulturpass-order-item/kulturpass-order-item.component';
import { LoaderComponent } from '@shared/components/loader';
import { DisplayOrderDTO, KeyValueDTOOfStringAndString } from '@swagger/oms';
import { getCatalogProductNumber } from './catalog-product-number';
@Component({
  selector: 'shared-kulturpass-order-modal',
  templateUrl: 'kulturpass-order-modal.component.html',
  styleUrls: ['kulturpass-order-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'shared-kulturpass-order-modal' },
  standalone: true,
  imports: [KulturpassOrderSearchboxComponent, CommonModule, BranchNamePipe, KulturpassOrderItemComponent, LoaderComponent],
  providers: [provideComponentStore(KulturpassOrderModalStore)],
})
export class KulturpassOrderModalComponent implements OnInit {
  branch$ = this._store.branch$;

  fetchShoppingCart$ = this._store.fetchShoppingCart$;

  items$ = this._store.shoppingCart$.pipe(map((shoppingCart) => shoppingCart?.items?.map((item) => item.data) ?? []));

  trackItemById = (index: number, item: ShoppingCartItemDTO) => item.id;

  kulturpassCode$ = this._store.orderItemListItem$.pipe(map((orderItemListItem) => orderItemListItem.buyerNumber));

  credit$ = this._store.orderItemListItem$.pipe(map((orderItemListItem) => orderItemListItem?.retailPrice?.value?.value ?? 0));

  total$ = this._store.shoppingCart$.pipe(map((shoppingCart) => shoppingCart?.total?.value ?? 0));

  balance$ = combineLatest([this.credit$, this.total$]).pipe(map(([credit, total]) => credit - total));

  negativeBalance$ = this.balance$.pipe(map((balance) => balance < 0));

  emptyShoppingCart$ = this.items$.pipe(map((items) => items.length === 0));

  ordering$ = this._store.ordering$;

  hasShoppingCartItemWithoutStock$ = this.items$.pipe(
    map((items) =>
      items.some((item) => {
        const availability = this._store.getAvailability(getCatalogProductNumber(item));
        return availability?.inStock < item.quantity;
      })
    )
  );

  orderButtonDisabled$ = combineLatest([
    this.emptyShoppingCart$,
    this.negativeBalance$,
    this.ordering$,
    this.hasShoppingCartItemWithoutStock$,
  ]).pipe(
    map(
      ([emptyShoppingCart, negativeBalance, ordering, hasShoppingCartItemWithoutStock]) =>
        emptyShoppingCart || negativeBalance || ordering || hasShoppingCartItemWithoutStock
    )
  );

  constructor(
    private _modalRef: UiModalRef<[DisplayOrderDTO, string], KulturpassOrderModalData>,
    private _store: KulturpassOrderModalStore
  ) {}

  ngOnInit() {
    this._store.updateOrder(this._modalRef.data.order);
    this._store.createShoppingCart(this._modalRef.data.orderItemListItem);
  }

  addToShoppingCart(addToShoppingCart: AddToShoppingCartDTO) {
    this._store.addItem(addToShoppingCart);
  }

  quantityChange(shoppingCartItem: ShoppingCartItemDTO) {
    this._store.quantityChange(shoppingCartItem);
  }

  order() {
    this._store.onOrderSuccess = async (displayOrder: DisplayOrderDTO, action: KeyValueDTOOfStringAndString[]) => {
      const command = action.map((action) => action.command).join('|');

      this._modalRef.close([displayOrder, command]);
    };
    this._store.orderItems();
  }
}
