/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CheckoutConfiguration, CheckoutConfigurationInterface } from './checkout-configuration';

import { KulturPassService } from './services/kultur-pass.service';
import { StoreCheckoutBranchService } from './services/store-checkout-branch.service';
import { StoreCheckoutBuyerService } from './services/store-checkout-buyer.service';
import { StoreCheckoutService } from './services/store-checkout.service';
import { StoreCheckoutCountryService } from './services/store-checkout-country.service';
import { StoreCheckoutLogisticianService } from './services/store-checkout-logistician.service';
import { StoreCheckoutPayerService } from './services/store-checkout-payer.service';
import { StoreCheckoutPaymentService } from './services/store-checkout-payment.service';
import { StoreCheckoutShoppingCartService } from './services/store-checkout-shopping-cart.service';
import { StoreCheckoutSupplierService } from './services/store-checkout-supplier.service';
import { StoreCheckoutVATService } from './services/store-checkout-vat.service';

/**
 * Provider for all Checkout services, plus CheckoutConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CheckoutConfiguration,
    KulturPassService,
    StoreCheckoutBranchService,
    StoreCheckoutBuyerService,
    StoreCheckoutService,
    StoreCheckoutCountryService,
    StoreCheckoutLogisticianService,
    StoreCheckoutPayerService,
    StoreCheckoutPaymentService,
    StoreCheckoutShoppingCartService,
    StoreCheckoutSupplierService,
    StoreCheckoutVATService
  ],
})
export class CheckoutModule {
  static forRoot(customParams: CheckoutConfigurationInterface): ModuleWithProviders<CheckoutModule> {
    return {
      ngModule: CheckoutModule,
      providers: [
        {
          provide: CheckoutConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
