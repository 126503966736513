import { Injectable } from '@angular/core';
import { ActionHandler } from '@core/command';
import { DomainPrinterService, Printer } from '@domain/printer';
import { PrintModalComponent, PrintModalData } from '@modal/printer';
import { UiModalService } from '@ui/modal';
import { NativeContainerService } from 'native-container';
import { OrderItemsContext } from './order-items.context';
import { EnvironmentService } from '@core/environment';

@Injectable()
export class PrintCompartmentLabelActionHandler extends ActionHandler<OrderItemsContext> {
  constructor(
    private uiModal: UiModalService,
    private domainPrinterService: DomainPrinterService,
    private nativeContainerService: NativeContainerService,
    private _environmentSerivce: EnvironmentService
  ) {
    super('PRINT_COMPARTMENTLABEL');
  }
  printCompartmentLabelHelper(printer: string, orderItemSubsetIds: number[]) {
    return this.domainPrinterService
      .printCompartmentLabel({
        printer,
        orderItemSubsetIds,
      })
      .toPromise();
  }

  async handler(data: OrderItemsContext): Promise<OrderItemsContext> {
    const printerList = await this.domainPrinterService.getAvailableLabelPrinters().toPromise();
    let printer: Printer;

    if (Array.isArray(printerList)) {
      printer = printerList.find((printer) => printer.selected === true);
    }
    if (!printer || this._environmentSerivce.matchTablet()) {
      await this.uiModal
        .open({
          content: PrintModalComponent,
          config: { showScrollbarY: false },
          data: {
            printImmediately: !this._environmentSerivce.matchTablet(),
            printerType: 'Label',
            print: (printer) =>
              this.printCompartmentLabelHelper(
                printer,
                data.items.map((item) => item.orderItemSubsetId)
              ),
          } as PrintModalData,
        })
        .afterClosed$.toPromise();
    } else {
      await this.printCompartmentLabelHelper(
        printer.key,
        data.items.map((item) => item.orderItemSubsetId)
      );
    }
    return data;
  }
}
