import { Observable } from 'rxjs';

export interface ScanAdapter {
  /**
   * Name to identify the adapter
   */
  readonly name: string;

  /**
   * @returns true if this adapter can be used
   */
  init(): Promise<boolean>;

  /**
   * scan for a barcode
   */
  scan(): Observable<string>;
}
