import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CantAddCustomerToCartModalComponent } from './cant-add-customer-to-cart.component';
import { UiIconModule } from '@ui/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, UiIconModule, RouterModule],
  exports: [CantAddCustomerToCartModalComponent],
  declarations: [CantAddCustomerToCartModalComponent],
})
export class CantAddCustomerToCartModalModule {}
