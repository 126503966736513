import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { first, map, retry } from 'rxjs/operators';
import { ShellService } from '../shell.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { MessageBoardItemDTO, NotificationsHub } from '@hub/notifications';
import { UiModalService } from '@ui/modal';
import { ModalNotificationsComponent } from '@modal/notifications';
import { ApplicationService } from '@core/application';
import { StockService } from '@swagger/wws';
import { AuthService } from '@core/auth';
import { EnvironmentService } from '@core/environment';

@Component({
  selector: 'shell-top-bar',
  templateUrl: 'top-bar.component.html',
  styleUrls: ['top-bar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellTopBarComponent implements OnInit {
  title$ = this._app.getTitle$();

  menuIcon$ = this._shellService.sideMenuOpen$.pipe(map((open) => (open ? 'close' : 'menu')));

  sectionSubject = new BehaviorSubject<'customer' | 'branch'>('customer');

  notifications$ = this._notificationsHub.notifications$;

  notificationCount$ = this.notifications$.pipe(
    map((notifications) => Object.values(notifications).reduce((acc, val) => acc + val?.length ?? 0, 0))
  );

  branchKey$ = this._stockService.StockCurrentBranch().pipe(
    retry(3),
    map((x) => x.result.key)
  );

  canNotIncreaseFontSize$ = combineLatest([this._shellService.fontSize$, this.isTablet$]).pipe(
    map(([size, isTablet]) => (isTablet ? size === 'normal' : size === 'large'))
  );

  canNotDecreaseFontSize$ = this._shellService.fontSize$.pipe(map((size) => size === 'small'));

  get isTablet$(): Observable<boolean> {
    return this._environment.matchTablet$;
  }

  constructor(
    private _shellService: ShellService,
    private _stockService: StockService,
    private _authService: AuthService,
    private readonly _notificationsHub: NotificationsHub,
    private _modal: UiModalService,
    private _app: ApplicationService,
    private _environment: EnvironmentService
  ) {}

  ngOnInit() {
    this._app.getSection$().subscribe(this.sectionSubject);
  }

  toggleSideMenu() {
    this._shellService.toggleSideMenu();
  }
  async openNotifications() {
    const notifications = await this.notifications$.pipe(first()).toPromise();
    this._modal.open({
      content: ModalNotificationsComponent,
      data: notifications as Record<string, MessageBoardItemDTO[]>,
      config: {
        showScrollbarY: false,
      },
    });
  }

  async logout() {
    await this._authService.logout();
  }

  increaseFontSize() {
    const current = this._shellService.fontSize;

    if (current === 'small') {
      this._shellService.setFontSize('normal');
    } else if (current === 'normal') {
      this._shellService.setFontSize('large');
    }
  }

  decreaseFontSize() {
    const current = this._shellService.fontSize;

    if (current === 'large') {
      this._shellService.setFontSize('normal');
    } else if (current === 'normal') {
      this._shellService.setFontSize('small');
    }
  }
}
