import { AbstractControl } from '@angular/forms';

export class EmptyControl extends AbstractControl {
  constructor() {
    super([], []);
  }

  setValue(value: any, options?: Object): void {}
  patchValue(value: any, options?: Object): void {}
  reset(value?: any, options?: Object): void {}
}
