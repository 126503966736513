import { Subject } from 'rxjs';
import { UiFilter } from './ui-filter';
import { IUiInput, UiInput } from './ui-input';

export interface IUiInputGroup {
  readonly description?: string;
  readonly group?: string;
  readonly input?: Array<IUiInput>;
  readonly label?: string;
  readonly focused?: boolean;
}

export class UiInputGroup implements IUiInputGroup {
  //#region implements IUiFilterInputGroup
  private _description?: string;
  get description() {
    return this._description;
  }

  private _group?: string;
  get group() {
    return this._group;
  }

  private _input?: Array<UiInput>;
  get input() {
    return this._input;
  }

  private _label?: string;
  get label() {
    return this._label;
  }

  //#endregion

  readonly changes = new Subject<{ keys: (keyof IUiInputGroup)[]; target: UiInputGroup }>();

  constructor(public readonly parent?: UiFilter) {}

  toObject(): IUiInputGroup {
    return {
      description: this.description,
      group: this.group,
      input: this.input?.map((input) => input.toObject()),
      label: this.label,
    };
  }

  static create(inputGroup: IUiInputGroup, parent?: UiFilter) {
    const target = new UiInputGroup(parent);

    target._description = inputGroup?.description;
    target._group = inputGroup?.group;
    target._input = inputGroup?.input?.map((input) => UiInput.create(input, target));
    target._label = inputGroup?.label;

    return target;
  }
}
