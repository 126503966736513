import { Highlightable } from '@angular/cdk/a11y';
import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation, HostBinding, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: '[uiAutocompleteItem]',
  templateUrl: 'autocomplete-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiAutocompleteItemComponent implements Highlightable {
  @HostBinding('class.ui-autocomplete-item') clazz = true;

  @Input('uiAutocompleteItem')
  item: any;

  @HostBinding('class.active')
  isActive = false;

  @Input()
  @HostBinding('disabled')
  disabled = false;

  onClick = (item: any) => {};

  constructor(private _elementRef: ElementRef<any>) {}

  @HostListener('click')
  handleClickEvent() {
    this.onClick?.call(null, this.item);
  }

  registerOnClick(fn: (item: any) => void) {
    this.onClick = fn;
  }

  setActiveStyles(): void {
    this.isActive = true;
  }

  setInactiveStyles(): void {
    this.isActive = false;
  }

  scrollIntoView() {
    this._elementRef?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }
}
