import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeviatingAddressFormBlockComponent } from './deviating-address-form-block.component';
import { AddressFormBlockModule } from '../address';
import { NameFormBlockModule } from '../name';
import { ReactiveFormsModule } from '@angular/forms';
import { OrganisationFormBlockModule } from '../organisation';
import { EmailFormBlockModule } from '../email';
import { PhoneNumbersFormBlockModule } from '../phone-numbers';
import { CheckboxComponent } from '@shared/components/checkbox';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NameFormBlockModule,
    AddressFormBlockModule,
    OrganisationFormBlockModule,
    EmailFormBlockModule,
    PhoneNumbersFormBlockModule,
    CheckboxComponent,
  ],
  exports: [DeviatingAddressFormBlockComponent],
  declarations: [DeviatingAddressFormBlockComponent],
})
export class DeviatingAddressFormBlockComponentModule {}
