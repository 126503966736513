import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';
import { ProductImageModule } from '@cdn/product-image';
import { provideComponentStore } from '@ngrx/component-store';
import { ShoppingCartItemDTO } from '@swagger/checkout';
import { UiQuantityDropdownModule } from '@ui/quantity-dropdown';
import { KulturpassOrderItemStore } from './kulturpass-order-item.store';

@Component({
  selector: 'shared-kulturpass-order-item',
  templateUrl: 'kulturpass-order-item.component.html',
  styleUrls: ['kulturpass-order-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'shared-kulturpass-order-item' },
  standalone: true,
  imports: [ProductImageModule, CommonModule, UiQuantityDropdownModule],
  providers: [provideComponentStore(KulturpassOrderItemStore)],
})
export class KulturpassOrderItemComponent {
  @Input()
  set item(item: ShoppingCartItemDTO) {
    this._store.updateItem(item);
  }
  get item() {
    return this._store.item;
  }

  itemQuantity$ = this._store.itemQuantity$;

  get iconSrc() {
    return `/assets/images/Icon_${this.item?.product?.format}.svg`;
  }

  stockWarning$ = this._store.stockWarning$;

  availableQuantity$ = this._store.availableQuantity$;

  constructor(private _store: KulturpassOrderItemStore) {}

  onQuantityChange(quantity: number) {
    this._store.updateQuantity(quantity);
  }
}
