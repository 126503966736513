import { OrderItemListItemDTO, OrderDTO } from '@swagger/oms';

export interface KulturpassOrderModalData {
  /**
   * Guthaben des Kulturpasses
   */
  orderItemListItem: OrderItemListItemDTO;

  order: OrderDTO;
}
