import { NgModule } from '@angular/core';
import { DomainAvailabilityModule } from '@domain/availability';
import { DomainCatalogModule } from '@domain/catalog';
import { DomainIsaModule } from '@domain/isa';
import { DomainCheckoutModule } from '@domain/checkout';
import { DomainOmsModule } from '@domain/oms';
import { DomainRemissionModule } from '@domain/remission';

@NgModule({
  imports: [
    DomainIsaModule.forRoot(),
    DomainCatalogModule.forRoot(),
    DomainAvailabilityModule.forRoot(),
    DomainCheckoutModule.forRoot(),
    DomainOmsModule.forRoot(),
    DomainRemissionModule.forRoot(),
  ],
})
export class AppDomainModule {}
