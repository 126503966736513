import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFilterInputOptionsComponent } from './filter-input-options.component';
import { UiFilterInputOptionBoolModule } from './filter-input-option-bool';
import { UiIconModule } from '@ui/icon';
import { UiFilterInputOptionDateRangeModule } from './filter-input-option-date-range';
import { UiInputOptionTriStateModule } from './filter-input-option-tri-state';
import { UiInputOptionNumberRangeModule } from './filter-input-option-number-range';

@NgModule({
  imports: [
    CommonModule,
    UiIconModule,
    UiFilterInputOptionBoolModule,
    UiFilterInputOptionDateRangeModule,
    UiInputOptionTriStateModule,
    UiInputOptionNumberRangeModule,
  ],
  exports: [UiFilterInputOptionsComponent],
  declarations: [UiFilterInputOptionsComponent],
})
export class UiFilterInputOptionsModule {}
