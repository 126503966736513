import { IHttpConnectionOptions, IRetryPolicy } from '@microsoft/signalr';

export interface SignalRHubOptions {
  url: string;

  enableAutomaticReconnect?: boolean;
  automaticReconnect?: number[] | IRetryPolicy;

  httpOptions?: IHttpConnectionOptions;
}
