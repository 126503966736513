import { Component, ChangeDetectionStrategy, ElementRef, ViewChild, NgZone, AfterViewInit, OnDestroy } from '@angular/core';
import { UiMessageModalComponent, UiModalService } from '@ui/modal';
import { Barcode, BarcodePicker, ScanResult, ScanSettings } from 'scandit-sdk';

@Component({
  selector: 'app-scandit-overlay',
  templateUrl: 'scandit-overlay.component.html',
  styleUrls: ['scandit-overlay.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanditOverlayComponent implements AfterViewInit, OnDestroy {
  private _barcodePicker: BarcodePicker;

  private _onScan?: (code: string) => void;

  private _onClose?: () => void;

  @ViewChild('scanContainer', { read: ElementRef, static: true }) scanContainer: ElementRef;

  constructor(private _zone: NgZone, private _modal: UiModalService) {}

  ngAfterViewInit(): void {
    this.createBarcodePicker()
      .then(() => {
        this._barcodePicker.on('scan', (scanResult) => {
          this._zone.run(() => this.handleScanrResult(scanResult));
        });
      })
      .catch((err: Error) => {
        this._modal
          .open({
            content: UiMessageModalComponent,
            title: 'Zugriff auf Kamera verweigert',
            data: { message: 'Falls Sie den Zugriff erlauben möchten, können Sie das über die Webseiteinstellung Ihres Browsers.' },
          })
          .afterClosed$.subscribe(() => {
            this._onClose?.();
          });
      });
  }

  async createBarcodePicker() {
    this._barcodePicker = await BarcodePicker.create(this.scanContainer.nativeElement, {
      playSoundOnScan: true,
      vibrateOnScan: true,
    });

    this._barcodePicker.applyScanSettings(this.getScanSettings());
  }

  getScanSettings(): ScanSettings {
    return new ScanSettings({
      blurryRecognition: false,

      enabledSymbologies: [
        Barcode.Symbology.EAN8,
        Barcode.Symbology.EAN13,
        Barcode.Symbology.UPCA,
        Barcode.Symbology.UPCE,
        Barcode.Symbology.CODE128,
        Barcode.Symbology.CODE39,
        Barcode.Symbology.CODE93,
        Barcode.Symbology.INTERLEAVED_2_OF_5,
        Barcode.Symbology.QR,
      ],
      codeDuplicateFilter: 1000,
    });
  }

  onScan(fn: (code: string) => void) {
    this._onScan = fn;
  }

  onClose(fn: () => void) {
    this._onClose = fn;
  }

  handleScanrResult(scanRestul: ScanResult) {
    let result: string | undefined;
    if (scanRestul.barcodes.length) {
      result = scanRestul.barcodes[0].data;
    } else if (scanRestul.texts.length) {
      result = scanRestul.texts[0].value;
    }

    if (result) {
      this._onScan?.(result);
    }
  }

  close() {
    this._onClose?.();
  }

  ngOnDestroy(): void {
    this._zone.runOutsideAngular(() => {
      this._barcodePicker?.destroy(true);
    });
  }
}
