import { Inject, Injectable } from '@angular/core';

import { LogLevel } from './log-level';
import { LogProvider } from './log.provider';
import { LOG_LEVEL, LOG_PROVIDER } from './tokens';

@Injectable()
export class Logger {
  constructor(@Inject(LOG_PROVIDER) private readonly _loggerProviders: LogProvider[], @Inject(LOG_LEVEL) private _logLevel: LogLevel) {}

  log(logLevel: LogLevel, message: string, ...optionalParams: any[]): void {
    if (this._logLevel === LogLevel.OFF) {
      return;
    }

    if (this._logLevel <= logLevel) {
      console.log(this._logLevel, logLevel, this._logLevel <= logLevel);
      this._loggerProviders.forEach((provider) => {
        provider.log(logLevel, message, ...optionalParams);
      });
    }
  }

  warn(message: string, ...optionalParams: any[]): void {
    this.log(LogLevel.WARN, message, ...optionalParams);
  }

  info(message: string, ...optionalParams: any[]): void {
    this.log(LogLevel.INFO, message, ...optionalParams);
  }

  debug(message: string, ...optionalParams: any[]): void {
    this.log(LogLevel.DEBUG, message, ...optionalParams);
  }

  error(message: string, ...optionalParams: any[]): void {
    this.log(LogLevel.ERROR, message, ...optionalParams);
  }
}
