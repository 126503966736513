import { UiModalRef, UiModalService } from '@ui/modal';
import { KulturpassOrderModalData } from './kulturpass-order-modal.data';
import { KulturpassOrderModalComponent } from './kulturpass-order-modal.component';
import { Injectable } from '@angular/core';
import { DisplayOrderDTO } from '@swagger/oms';

@Injectable({ providedIn: 'root' })
export class KulturpassOrderModalService {
  constructor(private modal: UiModalService) {}

  open(data: KulturpassOrderModalData): UiModalRef<[DisplayOrderDTO, string], KulturpassOrderModalData> {
    return this.modal.open<[DisplayOrderDTO, string], KulturpassOrderModalData>({
      content: KulturpassOrderModalComponent,
      data,
      config: {
        backdropClose: false,
      },
    });
  }
}
