import { Injectable, NgZone } from '@angular/core';
import { Toast } from './toast.model';
import { BehaviorSubject, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToasterService {
  private _nextId = 1;

  private _toasts = new BehaviorSubject<Array<Toast>>([]);

  toasts$ = this._toasts.asObservable();

  constructor(private _zone: NgZone) {
    window['toaster'] = this;
  }

  help() {
    const message = `Toaster Service Help:
    Toast anzeigen:
    toaster.open({ message: 'Nachricht' });
    toaster.open({ message: 'Nachricht', title: 'Titel' });
    toaster.open({ message: 'Nachricht', type: 'success' });
    toaster.open({ message: 'Nachricht', type: 'info' });
    toaster.open({ message: 'Nachricht', type: 'warning' });
    toaster.open({ message: 'Nachricht', type: 'danger' });
    toaster.open({ message: 'Nachricht', closeAfter: 5000 });
    toaster.open({ message: 'Nachricht', closeable: false });
    toaster.open({ message: 'Nachricht', closeAfter: 5000, closeable: false });
    
    Toast schließen:
    Variante 1:
    const toast = toaster.open({ message: 'Nachricht' });
    toast.close();
    Variante 2:
    const toast = toaster.open({ message: 'Nachricht' });
    toaster.close(toast.id);`;

    console.log(message);
  }

  open(toast: Toast): { id: number; close: () => void } {
    const id = this._nextId++;

    const toasts = this._toasts.getValue();

    toasts.push({ ...toast, id });

    this._zone.run(() => {
      this._toasts.next(toasts);
    });

    const closeable = typeof toast.closeable === 'boolean' ? toast.closeable : true;

    if (closeable) {
      timer(toast.closeAfter || 10000).subscribe({
        next: () => this.close(id),
      });
    }

    return {
      id,
      close: () => {
        this.close(id);
      },
    };
  }

  close(id: number): void {
    const toasts = this._toasts.getValue();
    const index = toasts.findIndex((t) => t.id === id);
    if (index > -1) {
      toasts.splice(index, 1);
      this._zone.run(() => {
        this._toasts.next(toasts);
      });
    }
  }
}
