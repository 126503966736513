import { Directive, Input, TemplateRef } from '@angular/core';
import { UiInput } from '../../tree';

export interface UiInputContext {
  $implicit: UiInput;
}

@Directive({ selector: '[uiFilterCustomInput]' })
export class UiFilterCustomInputDirective {
  @Input('uiFilterCustomInput')
  key: string;

  constructor(public templateRef: TemplateRef<UiInputContext>) {}

  static ngTemplateContextGuard<T>(dir: UiFilterCustomInputDirective, ctx: any): ctx is UiInputContext {
    return ctx.$implicit instanceof UiInput;
  }
}
