import { Group } from './group';

export function groupBy<TG, TI>(collection: TI[], selectGroup: (item: TI) => TG): Group<TG, TI>[] {
  if (!Array.isArray(collection)) {
    return [];
  }

  return collection.reduce((acc, item) => {
    const group = selectGroup(item);
    const groupIndex = acc.findIndex((itm) => itm.group === group);
    if (groupIndex === -1) {
      acc.push({ group, items: [item] });
    } else {
      acc[groupIndex].items.push(item);
    }
    return acc;
  }, [] as Group<TG, TI>[]);
}
