import { Injectable } from '@angular/core';
import { SvgIcon } from './defs';
import { IconAlias } from './defs/icon-alias';

@Injectable()
export class IconRegistry {
  private _icons = new Map<string, SvgIcon>();
  private _aliases = new Map<string, string>();
  private _fallback: string;
  private _viewBox: string;

  register(...icons: SvgIcon[]): IconRegistry {
    icons?.forEach((icon) => {
      this._icons.set(icon.name, icon);
    });

    return this;
  }

  setViewBox(viewBox: string): void {
    this._viewBox = viewBox;
  }

  alias(...aliases: IconAlias[]): IconRegistry {
    aliases?.forEach((alias) => {
      this._aliases.set(alias.alias, alias.name);
    });

    return this;
  }

  setFallback(name: string): void {
    this._fallback = name;
  }

  get(name: string): SvgIcon | undefined {
    const alias = this._aliases.get(name);
    let iconName = name;
    if (alias) {
      iconName = alias;
    }

    let icon = this._icons.get(iconName);

    if (!icon) {
      if (alias) {
        console.warn(`Not found: Icon with name ${name} (${iconName})`);
      } else {
        console.warn(`Unable to find icon: '${name}'`);
      }
    }

    if (!icon && this._fallback) {
      icon = this._icons.get(this._fallback);
    }

    return { ...icon, viewBox: icon?.viewBox || this._viewBox };
  }
}
