import { ActionHandler } from '@core/command';
import {
  AcceptedActionHandler,
  ArrivedActionHandler,
  AssembledActionHandler,
  AvailableForDownloadActionHandler,
  BackToStockActionHandler,
  CanceledByBuyerActionHandler,
  CanceledByRetailerActionHandler,
  CanceledBySupplierActionHandler,
  CreateShippingNoteActionHandler,
  DeliveredActionHandler,
  DetermineSupplierActionHandler,
  DispatchedActionHandler,
  DownloadedActionHandler,
  FetchedActionHandler,
  InProcessActionHandler,
  NotAvailableActionHandler,
  NotFetchedActionHandler,
  OrderAtSupplierActionHandler,
  OrderingActionHandler,
  OverdueActionHandler,
  PackedActionHandler,
  ParkedActionHandler,
  PlacedActionHandler,
  PreparationForShippingActionHandler,
  PrintCompartmentLabelActionHandler,
  PrintShippingNoteActionHandler,
  ReOrderActionHandler,
  RedirectedInternaqllyActionHandler,
  RequestedActionHandler,
  ReserverdActionHandler,
  ReturnedByBuyerActionHandler,
  ShippingNoteActionHandler,
  SupplierTemporarilyOutOfStockActionHandler,
  ReOrderedActionHandler,
  CollectOnDeliveryNoteActionHandler,
  PrintPriceDiffQrCodeLabelActionHandler,
  CollectWithSmallAmountinvoiceActionHandler,
  PrintSmallamountinvoiceActionHandler,
  ShopWithKulturpassActionHandler,
  ChangeOrderItemStatusBaseActionHandler,
  CreateReturnItemActionHandler,
} from './action-handlers';
import { Type } from '@angular/core';

export const ActionHandlerServices: Type<ActionHandler>[] = [
  AcceptedActionHandler,
  ArrivedActionHandler,
  AssembledActionHandler,
  AvailableForDownloadActionHandler,
  BackToStockActionHandler,
  CanceledByBuyerActionHandler,
  CanceledByRetailerActionHandler,
  CanceledBySupplierActionHandler,
  CreateShippingNoteActionHandler,
  DeliveredActionHandler,
  DetermineSupplierActionHandler,
  DispatchedActionHandler,
  DownloadedActionHandler,
  FetchedActionHandler,
  InProcessActionHandler,
  NotAvailableActionHandler,
  NotFetchedActionHandler,
  OrderAtSupplierActionHandler,
  OrderingActionHandler,
  OverdueActionHandler,
  PackedActionHandler,
  ParkedActionHandler,
  PlacedActionHandler,
  PreparationForShippingActionHandler,
  PrintCompartmentLabelActionHandler,
  PrintShippingNoteActionHandler,
  ReOrderActionHandler,
  RedirectedInternaqllyActionHandler,
  RequestedActionHandler,
  ReserverdActionHandler,
  ReturnedByBuyerActionHandler,
  ShippingNoteActionHandler,
  SupplierTemporarilyOutOfStockActionHandler,
  ReOrderedActionHandler,
  CollectOnDeliveryNoteActionHandler,
  PrintPriceDiffQrCodeLabelActionHandler,
  CollectWithSmallAmountinvoiceActionHandler,
  PrintSmallamountinvoiceActionHandler,
  ShopWithKulturpassActionHandler,
  CreateReturnItemActionHandler,
];
