import { AssignedPayerDTO } from '@swagger/crm';

function getDefaultAssignedPayer(payers: AssignedPayerDTO[]): AssignedPayerDTO {
  if (Array.isArray(payers)) {
    const defaults = payers.filter((f) => !!f.isDefault).map((f) => new Date(f.isDefault));
    if (defaults.length > 0) {
      const latest = Math.max(...defaults.map((d) => d.getTime()));
      return payers.find((p) => new Date(p.isDefault).getTime() === latest);
    }
  }

  return undefined;
}

export const AssignedPayerHelper = {
  getDefaultAssignedPayer,
};
