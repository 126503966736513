import { ModuleWithProviders, NgModule } from '@angular/core';
import { DomainGoodsService } from './goods.service';
import { DomainOmsService } from './oms.service';
import { DomainReceiptService } from './receipt.service';

@NgModule()
export class DomainOmsModule {
  static forRoot(): ModuleWithProviders<DomainOmsModule> {
    return {
      ngModule: DomainOmsModule,
      providers: [DomainOmsService, DomainGoodsService, DomainReceiptService],
    };
  }
}
