import { Directive, ElementRef, Host, HostBinding, HostListener, Input } from '@angular/core';
import { UiDropdownComponent } from './dropdown.component';

@Directive({ selector: '[uiDropdownItem]' })
export class UiDropdownItemDirective {
  @Input()
  @HostBinding('disabled')
  disabled: boolean;
  focused: boolean;

  constructor(@Host() private dropdown: UiDropdownComponent, private elementRef: ElementRef) {}

  @HostListener('focus')
  onFocus() {
    this.focused = true;
  }

  @HostListener('blur')
  onBlur() {
    this.focused = false;
  }

  @HostListener('click')
  close() {
    this.dropdown?.close();
  }

  focus() {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    }, 1);
  }
}
