import { ApplicationProcess } from '../defs';

export interface ApplicationState {
  title: string;
  processes: ApplicationProcess[];
  section: 'customer' | 'branch';
}

export const INITIAL_APPLICATION_STATE: ApplicationState = {
  title: '',
  processes: [],
  section: 'customer',
};
