/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CatConfiguration, CatConfigurationInterface } from './cat-configuration';

import { PromotionService } from './services/promotion.service';
import { SearchService } from './services/search.service';

/**
 * Provider for all Cat services, plus CatConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CatConfiguration,
    PromotionService,
    SearchService
  ],
})
export class CatModule {
  static forRoot(customParams: CatConfigurationInterface): ModuleWithProviders<CatModule> {
    return {
      ngModule: CatModule,
      providers: [
        {
          provide: CatConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
