import { Injectable } from '@angular/core';
import { CacheOptions } from './cache-options';
import { Cached } from './cached';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor() {
    this._registerCleanupTask();
  }

  _registerCleanupTask() {
    this.cleanup();
    interval(1000 * 60).subscribe(() => {
      this.cleanup();
    });
  }

  set<T>(token: Object, data: T, options?: CacheOptions) {
    const persist = options?.persist;
    const ttl = options?.ttl;
    const cached: Cached = {
      data,
    };

    if (ttl) {
      cached.until = Date.now() + ttl;
    } else {
      cached.until = Date.now() + 1000 * 60 * 60 * 12;
    }

    if (persist) {
      localStorage.setItem(this.getKey(token), this.serialize(cached));
    } else {
      sessionStorage.setItem(this.getKey(token), this.serialize(cached));
    }

    Object.freeze(cached);
    return cached;
  }

  get<T = any>(token: Object, from?: 'session' | 'persist'): T {
    let cached: Cached;

    if (from === 'session') {
      cached = this.deserialize(sessionStorage.getItem(this.getKey(token)));
    } else if (from === 'persist') {
      cached = this.deserialize(localStorage.getItem(this.getKey(token)));
    } else {
      cached = this.deserialize(sessionStorage.getItem(this.getKey(token))) || this.deserialize(localStorage.getItem(this.getKey(token)));
    }

    if (!cached) {
      return undefined;
    }

    if (cached.until < Date.now()) {
      this.delete(token, from);
      return undefined;
    }

    return cached.data;
  }

  delete(token: Object, from: 'session' | 'persist' = 'session') {
    if (from === 'session') {
      sessionStorage.removeItem(this.getKey(token));
    } else if (from === 'persist') {
      localStorage.removeItem(this.getKey(token));
    }
  }

  private getKey(token: Object) {
    const key = `CacheService_` + this.hash(JSON.stringify(token));
    return key;
  }

  private hash(data: string): string {
    let hash = 0;
    for (let i = 0; i < data.length; i++) {
      hash = data.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash.toString(16);
  }

  private serialize(data: Cached): string {
    return JSON.stringify(data);
  }

  private deserialize(data: string): Cached {
    return JSON.parse(data);
  }

  cleanup() {
    // get all keys created by this service by looking for the service name and remove the entries
    // that ttl is expired
    let localStorageKeys = Object.keys(localStorage).filter((key) => key.startsWith('CacheService_'));
    let seesionStorageKeys = Object.keys(sessionStorage).filter((key) => key.startsWith('CacheService_'));

    localStorageKeys.forEach((key) => {
      const cached = this.deserialize(localStorage.getItem(key));
      if (cached.until < Date.now()) {
        localStorage.removeItem(key);
      }
    });

    seesionStorageKeys.forEach((key) => {
      const cached = this.deserialize(sessionStorage.getItem(key));
      if (cached.until < Date.now()) {
        sessionStorage.removeItem(key);
      }
    });
  }
}
