import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormBlockControl } from '../form-block';
import { validateEmail } from '../../../validators/email-validator';

@Component({
  selector: 'app-email-form-block',
  templateUrl: 'email-form-block.component.html',
  styleUrls: ['email-form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailFormBlockComponent extends FormBlockControl<string> {
  get tabIndexEnd() {
    return this.tabIndexStart;
  }

  constructor() {
    super();
  }

  updateValidators(): void {
    this.control.setValidators([...this.getValidatorFn(), validateEmail]);
    this.control.setAsyncValidators(this.getAsyncValidatorFn());
    this.control.updateValueAndValidity();
  }

  initializeControl(data?: string): void {
    this.control = new UntypedFormControl(data, [...this.getValidatorFn(), validateEmail], this.getAsyncValidatorFn());
  }

  _patchValue(update: { previous: string; current: string }): void {
    this.control.patchValue(update.current);
  }
}
