import { OrderItemListItemDTO, ReceiptDTO, OrderDTO } from '@swagger/oms';

export interface OrderItemsContext {
  items: OrderItemListItemDTO[];
  compartmentInfo?: string;
  compartmentCode?: string;
  /**
   * Mapping zwischen OrderItemId und der Menge
   */
  itemQuantity?: Map<number, number>;

  receipts?: ReceiptDTO[];

  shippingDelayComment?: string;

  order?: OrderDTO;
}
