import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerTypeSelectorComponent } from './customer-type-selector.component';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from '@shared/components/checkbox';
import { AuthModule } from '@core/auth';

@NgModule({
  imports: [CommonModule, FormsModule, CheckboxComponent, AuthModule],
  exports: [CustomerTypeSelectorComponent],
  declarations: [CustomerTypeSelectorComponent],
})
export class CustomerTypeSelectorModule {}
