import { NgModule } from '@angular/core';
import { CreateB2BCustomerModule } from './create-b2b-customer/create-b2b-customer.module';
import { CreateGuestCustomerModule } from './create-guest-customer';
import { CreateP4MCustomerModule } from './create-p4m-customer';
import { CreateStoreCustomerModule } from './create-store-customer/create-store-customer.module';
import { CreateWebshopCustomerModule } from './create-webshop-customer/create-webshop-customer.module';
import { UpdateP4MWebshopCustomerModule } from './update-p4m-webshop-customer';
import { CreateCustomerComponent } from './create-customer.component';

@NgModule({
  imports: [
    CreateB2BCustomerModule,
    CreateGuestCustomerModule,
    CreateStoreCustomerModule,
    CreateWebshopCustomerModule,
    CreateP4MCustomerModule,
    UpdateP4MWebshopCustomerModule,
    CreateCustomerComponent,
  ],
  exports: [
    CreateB2BCustomerModule,
    CreateGuestCustomerModule,
    CreateStoreCustomerModule,
    CreateWebshopCustomerModule,
    CreateP4MCustomerModule,
    UpdateP4MWebshopCustomerModule,
    CreateCustomerComponent,
  ],
})
export class CreateCustomerModule {}
