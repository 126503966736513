import { DialogSettings, KeyValueDTOOfStringAndString } from '@swagger/crm';

export interface DialogModel<T = any> {
  actions?: Array<KeyValueDTOOfStringAndString>;
  actionsRequired?: number;
  area?: string;
  content?: string;
  description?: string;
  settings?: DialogSettings;
  subtitle?: string;
  title?: string;
  context?: T;
  /**
   * default: true
   */
  handleCommand?: boolean;
}
