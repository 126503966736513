import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { ApplicationService } from '@core/application';
import { Config } from '@core/config';
import { take } from 'rxjs/operators';

export const ActivateProcessIdGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const application = inject(ApplicationService);

  const processIdStr = route.params.processId;

  if (!processIdStr) {
    return false;
  }

  const processId = Number(processIdStr);

  // Check if Process already exists
  const process = await application.getProcessById$(processId).pipe(take(1)).toPromise();

  if (!process) {
    application.createCustomerProcess(processId);
  }

  application.activateProcess(processId);

  return true;
};

export const ActivateProcessIdWithConfigKeyGuard: (key: string) => CanActivateFn = (key) => async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const application = inject(ApplicationService);
  const config = inject(Config);

  const processId = config.get(`process.ids.${key}`);

  if (isNaN(processId)) {
    return false;
  }

  application.activateProcess(processId);

  return true;
};
