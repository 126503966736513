import { Pipe, PipeTransform } from '@angular/core';
import { CustomerDTO, CustomerInfoDTO } from '@swagger/crm';

@Pipe({
  name: 'customerName',
  pure: true,
  standalone: true,
})
export class CustomerNamePipe implements PipeTransform {
  transform(customer: CustomerDTO | CustomerInfoDTO, ...args: any[]): string {
    if (!customer) {
      return '';
    }

    const isB2b = customer.features?.some((f) => f.key === 'b2b') && customer.organisation?.name;

    if (!isB2b) {
      return `${customer?.lastName ?? ''} ${customer?.firstName ?? ''}`;
    } else {
      return `${customer.organisation.name ?? ''}`;
    }
  }
}
