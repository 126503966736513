<div class="notification-list scroll-bar">
  <ng-container *ngFor="let notification of notifications">
    <div class="notification-headline">
      <h1>{{ notification.headline }}</h1>
    </div>
    <div class="notification-text">{{ notification.text }}</div>
    <hr />
  </ng-container>
</div>

<div class="actions">
  <button class="cta-primary" (click)="reload()">
    Aktualisieren
  </button>
</div>
