import { Injectable } from '@angular/core';
import { LogLevel } from './log-level';
import { LogProvider } from './log.provider';

@Injectable()
export class ConsoleLogProvider implements LogProvider {
  log(logLevel: LogLevel, message: string, ...optionalParams: any[]): void {
    switch (logLevel) {
      case LogLevel.DEBUG:
        console.debug(message, ...optionalParams);
        break;
      case LogLevel.INFO:
        console.info(message, ...optionalParams);
        break;
      case LogLevel.WARN:
        console.warn(message, ...optionalParams);
        break;
      case LogLevel.ERROR:
        console.error(message, ...optionalParams);
        break;
      case LogLevel.OFF:
        break;
    }
  }
}
