import { createFeatureSelector } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { CheckoutEntity } from './defs/checkout.entity';
import { DisplayOrderDTO } from '@swagger/oms';

export interface DomainCheckoutState extends EntityState<CheckoutEntity> {
  orders: DisplayOrderDTO[];
}

export const storeCheckoutAdapter = createEntityAdapter<CheckoutEntity>({
  selectId: (entity) => entity.processId,
});

export const storeFeatureName = 'domain-checkout';

export const storeFeatureSelector = createFeatureSelector<DomainCheckoutState>(storeFeatureName);

export const initialCheckoutState: DomainCheckoutState = {
  ...storeCheckoutAdapter.getInitialState(),
  orders: [],
};
