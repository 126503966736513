import { ModuleWithProviders, NgModule } from '@angular/core';
import { DomainAvailabilityService } from './availability.service';

@NgModule()
export class DomainAvailabilityModule {
  static forRoot(): ModuleWithProviders<DomainAvailabilityModule> {
    return {
      ngModule: DomainAvailabilityModule,
      providers: [DomainAvailabilityService],
    };
  }
}
