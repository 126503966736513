import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { CustomerDTO } from '@swagger/crm';
import { map } from 'rxjs/operators';
import { AddressFormBlockData } from '../../components/form-blocks';
import { NameFormBlockData } from '../../components/form-blocks/name/name-form-block-data';
import { validateEmail } from '../../validators/email-validator';
import { AbstractCreateCustomer } from '../abstract-create-customer';

@Component({
  selector: 'app-create-store-customer',
  templateUrl: 'create-store-customer.component.html',
  styleUrls: ['../create-customer.scss', 'create-store-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateStoreCustomerComponent extends AbstractCreateCustomer {
  customerType = 'store';

  validateAddress = true;

  validateShippingAddress = true;

  addressRequiredMarks: (keyof AddressFormBlockData)[] = ['street', 'streetNumber', 'zipCode', 'city', 'country'];

  addressValidators: Record<string, ValidatorFn[]> = {
    street: [Validators.required],
    streetNumber: [Validators.required],
    zipCode: [Validators.required],
    city: [Validators.required],
    country: [Validators.required],
  };

  emailFormBlockValidators = [Validators.email, validateEmail];

  nameRequiredMarks: (keyof NameFormBlockData)[] = ['gender', 'firstName', 'lastName'];

  nameValidationFns: Record<string, ValidatorFn[]> = {
    title: [],
    gender: [Validators.required],
    firstName: [Validators.required],
    lastName: [Validators.required],
  };

  saveCustomer(customer: CustomerDTO): Promise<CustomerDTO> {
    customer.isGuestAccount = false;

    return this.customerService
      .createStoreCustomer(customer)
      .pipe(map((res) => res.result))
      .toPromise();
  }
}
