import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, OnChanges, OnInit } from '@angular/core';
import { SelectFilter, SelectFilterOption } from '../../../models';
import { FilterGroup } from '../../filter-group';

@Component({
  selector: 'ui-select-filter-option',
  templateUrl: 'select-filter-option.component.html',
  styleUrls: ['select-filter-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSelectFilterOptionComponent implements OnInit, OnChanges {
  @Output() category = new EventEmitter<SelectFilter>();

  @Input()
  filter: SelectFilter;

  @Input()
  option: SelectFilterOption;

  @Input() module: 'Customer' | 'Branch' = 'Branch';

  constructor(private cdr: ChangeDetectorRef, private filterGroup: FilterGroup) {}

  ngOnInit() {
    this.filterGroup.updateView.subscribe((_) => {
      this.cdr.markForCheck();
    });
  }

  ngOnChanges(): void {}

  toggle() {
    this.filterGroup.toggleOption(this.filter, this.option);
    this.category.emit(this.filter);
    this.cdr.markForCheck();
  }

  toggleExpanded() {
    this.filterGroup.expandOption(this.filter, this.option, !this.option.expanded);
    this.cdr.markForCheck();
  }
}
